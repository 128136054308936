import React, { useState, useEffect, useContext } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { toast, ToastContainer } from "react-toastify";
import { SubdomainURL } from "../../api/api";
import CryptoJS from "crypto-js";
import unverified_img from "../../images/home/expire.svg";
import tick_img from "../../images/home/tick.svg"
import {
	Modal,
	ModalBody,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from "reactstrap";
import request, { NodeURL } from "../../api/api";
import classnames from "classnames";
import Context from "../../api/context";
import { CheckValue, validateEmail } from "../../views/common/utils";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@trendmicro/react-tooltip";
import "@trendmicro/react-tooltip/dist/react-tooltip.css";
import io from 'socket.io-client'
const socket = io(NodeURL);

let AuthetiCationModal = (props) => {
	let history = useHistory();

	let siteContext = useContext(Context);
	let [email, setEmail] = useState(""),
		[password, setPassword] = useState(""),
		[remember, setRemember] = useState(false),
		[firstname, setFirstname] = useState(""),
		[surname, setSurname] = useState(""),
		[companyname, setCompanyname] = useState(""),
		[companyemail, setCompanyemail] = useState(""),
		[phone, setPhone] = useState(""),
		[subdomain, setSubdomain] = useState(""),
		[username, setUsername] = useState(""),
		[rpassword, setRpassword] = useState(""),
		[confirmpassword, setConfirmpassword] = useState(""),
		[femail, setFemail] = useState(""),
		[fdemail, setFDEmail] = useState(""),
		[rescode, setRescode] = useState(""),
		[resetcode, setResetcode] = useState(""),
		[newpassword, setNewpassword] = useState(""),
		[confirmnewpassword, setConfirmnewpassword] = useState(""),
		[forgotpassworda, setForgotpassworda] = useState(false),
		[forgotpasswordb, setForgotpasswordb] = useState(false),
		[forgotpasswordc, setForgotpasswordc] = useState(false),
		[showregistera, setShowregistera] = useState(true),
		[showregisterb, setShowregisterb] = useState(false),
		[spinner, setSpinner] = useState(false),
		[forgotPassworsModal, setforgotPassworsModal] = useState(false),
		[activeTab, setActiveTab] = useState("1"),
		[siteLogo, setSiteLogo] = useState(""),
		[trialPeriod, setTrialPeriod] = useState(0),
		[registerTabcond, setregisterTabcond] = useState(1),
		[showLogPwd, setShowLogPwd] = useState(false),
		[showPassword, setShowPassword] = useState(false),
		[showCPassword, setShowCPassword] = useState(false),
		[unverified, setUnverified] = useState(false),
		[emailVerifiedValue, setEmailVerifiedValue] = useState(''),
		[showResendModal, setShowResendModal] = useState(false),
		[ackResendModal, setAckResendModal] = useState(false),
		[newEmail, setNewEmail] = useState(''),
		[ackNewEmail, setAckNewEmail] = useState(false),
		[subDomain, setSubDomain] = useState(''),
		[agencyId, setAgencyId] = useState(''),
		[authWindow, setAuthWindow] = useState(),
		[sbuttonDisabled, setSbuttonDisabled] = useState(false),
		toggle = (tab) => {
			if (activeTab !== tab) setActiveTab(tab);
		},
		registerTab = (e) => {
			e.preventDefault();
			setActiveTab("2");
		},
		loginTab = (e) => {
			e.preventDefault();
			setActiveTab("1");
		},
		forgotPasswordPop = (e, boolean) => {
			e.preventDefault();
			setForgotpassworda(boolean === true ? true : false);
			setforgotPassworsModal(boolean);
		};
	let onEmailChange = (e) => {
		setEmail(e.target.value);
	};
	let onPasswordChange = (e) => {
		setPassword(e.target.value);
	};

	let onHandleChange = (e, key) => {
		if (key === "firstname") {
			setFirstname(e.target.value);
		}
		if (key === "surname") {
			setSurname(e.target.value);
		}
		if (key === "companyname") {
			setCompanyname(e.target.value);
		}
		if (key === "companyemail") {
			setCompanyemail(String(e.target.value).toLowerCase());
			setUsername(String(e.target.value).toLowerCase());
		}
		if (key === "phone") {
			setPhone(e.target.value);
		}
		if (key === "subdomain") {
			setSubdomain(e.target.value);
		}
		if (key === "username") {
			setUsername(e.target.value);
		}
		if (key === "rpassword") {
			setRpassword(e.target.value);
		}
		if (key === "confirmpassword") {
			setConfirmpassword(e.target.value);
		}
		if (key === "newpassword") {
			setNewpassword(e.target.value);
		}
		if (key === "confirmnewpassword") {
			setConfirmnewpassword(e.target.value);
		}
	};

	let rememberMe = (e) => {
		setRemember(e.target.checked);
	};

	let onSubmitLogin = (e, values) => {
		e.persist();
		const loginform = {
			email: email,
			password: password,
		};
		setSpinner(true);
		request({ url: "/agency", method: "POST", data: loginform })
			.then((res) => {
				setSpinner(false);
				if (res.status === 2) {
					setUnverified(true);
					setSubDomain(res.subdomain);
					setAgencyId(res.id);
					setEmailVerifiedValue(res.email_verification_value);
					toast.error(res.response);
				}
				if (res.status === 1) {
					const domain = res.domain || "";
					if (remember === true) {
						if (email && email !== "" && password && password !== "") {
							localStorage.setItem(
								"agency_r_detail",
								CryptoJS.AES.encrypt(
									JSON.stringify({ email, password }),
									"agencyPortalWebSite"
								)
							);
							localStorage.setItem("rememberMe", true);
						}
					} else {
						if (
							localStorage.getItem("rememberMe") !== null &&
							localStorage.getItem("agency_r_detail") !== null
						) {
							localStorage.removeItem("rememberMe");
							localStorage.removeItem("agency_r_detail");
						}
					}
					window.location = `http://${domain}.${SubdomainURL}/agency/?au=${res.response.before_auth_token}`;
				} else if (res.status === 0) {
					toast.error(res.response);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
	};

	let showRegisterDiva = (e, values) => {
		e.persist();
		setShowregistera(false);
		setShowregisterb(true);
	};

	let showRegisterDivb = () => {
		setShowregistera(true);
		setShowregisterb(false);
	};

	let onSubmitRegister = (e, error, values) => {
		let testregexp = new RegExp("^[A-Za-z0-9]+$");
		e.persist();
		e.preventDefault();
		if (error && error.length > 0) {
			return toast.error("Please fill all the mandatory fields", {
				autoClose: 1500,
			});
		}
		if (!validateEmail(companyemail)) {
			return toast.error("Invalid email address", { autoClose: 1500 });
		}
		if (!testregexp.test(subdomain)) {
			return toast.error("No special characters or symbols allowed in domain", {
				autoClose: 1500,
			});
		}
		const registerform = {
			email: companyemail,
			password: rpassword,
			username: username,
			subdomain: subdomain,
			confirm_password: confirmpassword,
			name: firstname,
			surname: surname,
			phone: {
				code: "44",
				number: phone,
				dailcountry: "gb",
			},
			address: {
				line1: "",
				line2: "",
				city: "",
				state: "",
				country: "",
				zipcode: "",
				formatted_address: "",
				lat: "38.685516",
				lng: "-101.073324",
			},
			company_name: companyname,
			company_phone: {
				code: "44",
				number: "",
				dailcountry: "gb",
			},
			company_email: "",
			company_logo: "",
			status: 1,
			isverified: 1,
			agencyId: agencyId
		};
		setSpinner(true);
		request({ url: "/register/agency", method: "POST", data: registerform })
			.then((res) => {
				setSpinner(false);
				if (res.status === 1) {
					// toast.success("Registration Completed Successfully! Please Check Mail");
					setActiveTab("1");
					setFirstname("");
					setSurname("");
					setCompanyname("");
					setCompanyemail("");
					setPhone("");
					setSubdomain("");
					setUsername("");
					setRpassword("");
					setConfirmpassword("");
					history.push("/register-success");
				} else if (res.status === 0) {
					toast.error(res.response);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
	};

	let forgotHandleChange = (e) => {
		setFemail(e.target.value);
	};

	const obscureEmail = (email) => {
		const [name, domain] = email.split("@");
		return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
	};
	const resendVerificationHandler = () => {
		const data = {
			subdomain: subDomain,
			verifyId: agencyId
		};
		request({
			url: `/agency/email-verification/redo-validation`,
			method: "POST",
			data: data,
		}).then((res) => {
			if (+res.status === 1) {
				toast.success(res.response);
				setShowResendModal(true);
				setUnverified(false);
			} else if (+res.status === 0) {
				setShowResendModal(false);
				setUnverified(false);
				toast.error(res.response);
			}
		}).catch((err) => {
			setShowResendModal(false);
			setUnverified(false);
			toast.error(err);
		})
	};
	const changeEmailValidation = async () => {
		const data = {
			subdomain: subDomain,
			verifyId: emailVerifiedValue,
			newEmail: newEmail,
		};
		request({
			url: `/agency/email-verification/resubmit-email`,
			method: "POST",
			data: data,
		}).then((res) => {
			if (+res.status === 1) {
				toast.success(res.response);
				setAckNewEmail(true);
				setAckResendModal(false);
				setUnverified(false);
			} else if (+res.status === 0) {
				setShowResendModal(false);
				setAckResendModal(false);
				setUnverified(false);
				toast.error(res.response);
			}
		}).catch((err) => {
			setAckResendModal(false);
			setShowResendModal(false);
			setUnverified(false);
			toast.error(err);
		})
	};
	let onEmailSend = (e) => {
		e.persist();
		const forgotPasswordform = {
			email: femail,
		};
		setSpinner(true);
		request({
			url: "/agency/forgotpassword",
			method: "POST",
			data: forgotPasswordform,
		})
			.then((res) => {
				setSpinner(false);
				if (res.status === 1) {
					setForgotpassworda(false);
					setForgotpasswordb(true);
					let bytes = CryptoJS.AES.decrypt(
						res.response.code,
						"agencyPortalWebSite"
					);
					setRescode(bytes.toString(CryptoJS.enc.Utf8));
					const email = obscureEmail(res.response.email);
					setFDEmail(email);
				} else if (res.status === 0) {
					toast.error(res.response);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
	};
	let handleResetCode = (e) => {
		setResetcode(e.target.value);
	};

	let onSubmitResetCode = (e) => {
		e.persist();
		if (rescode === resetcode) {
			setForgotpassworda(false);
			setForgotpasswordb(false);
			setForgotpasswordc(true);
		} else {
			toast.error(`Code doesn't match`);
		}
	};

	let changePassword = (e) => {
		e.persist();
		let passwordForm = {
			password: newpassword,
			confirmpassword: confirmnewpassword,
			reset: resetcode,
			email: femail,
		};
		setSpinner(true);
		request({
			url: `/agency/reset-password`,
			method: "POST",
			data: passwordForm,
		})
			.then((res) => {
				setSpinner(false);
				if (res.status === 1) {
					toast.success("Password successfully changed");
					setActiveTab("1");
					setforgotPassworsModal(false);
					setForgotpassworda(false);
					setForgotpasswordb(false);
					setForgotpasswordc(false);
					setFemail("");
				} else if (res.status === 0) {
					toast.error(res.response);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
	}, nextbutton = (e) => {
		let testregexp = new RegExp("^[A-Za-z0-9]+$");
		e.preventDefault();
		if (registerTabcond === 1) {
			if (!validateEmail(companyemail)) {
				return toast.error("Invalid email address", { autoClose: 1500 });
			}
		}
		if (registerTabcond === 2) {
			if (!testregexp.test(subdomain)) {
				return toast.error(
					"No special characters or symbols allowed in domain",
					{ autoClose: 1500 }
				);
			}
		}
		setregisterTabcond((registerTabcond) => registerTabcond + 1);
	}, prevRegisterButton = (e) => {
		e.preventDefault();
		setregisterTabcond((registerTabcond) => registerTabcond - 1);
	};

	useEffect(() => {
		if (
			localStorage.getItem("rememberMe") !== null &&
			(localStorage.getItem("rememberMe") === true ||
				localStorage.getItem("rememberMe") === "true")
		) {
			var bytes = CryptoJS.AES.decrypt(
				localStorage.getItem("agency_r_detail"),
				"agencyPortalWebSite"
			);
			var rememberDetails = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			setEmail(rememberDetails.email);
			setPassword(rememberDetails.password);
			let remember =
				localStorage.getItem("rememberMe") === "true" ||
					localStorage.getItem("rememberMe") === true
					? true
					: false;
			setRemember(remember);
		}
		if (siteContext.siteLogo && siteContext.siteLogo !== "") {
			setSiteLogo(siteContext.siteLogo);
		}
		if (props.autheticateTab) {
			setActiveTab(props.autheticateTab);
			if (localStorage.getItem("tryforfree_email") !== null) {
				setCompanyemail(localStorage.getItem("tryforfree_email"));
				setTimeout(() => {
					localStorage.removeItem("tryforfree_email");
				}, 500);
			}
		}
		if (siteContext.trialPeriod && siteContext.trialPeriod !== "") {
			setTrialPeriod(siteContext.trialPeriod);
		}
	}, []);

	socket.on('socialAuth', resp => {
		document.getElementById('closeWindow').click();
		setSbuttonDisabled(false);
		if (resp.status && resp.status === 1) {
			if (resp.signUp === "0" || resp.signUp === 0) {
				window.location = `http://${resp.domain}.${SubdomainURL}/agency/?au=${resp.response}`;
			} else if (resp.signUp === "1" || resp.signUp === 1) {
				setFirstname((resp.response && resp.response.name && resp.response.name !== '') ? resp.response.name : "");
				setSurname((resp.response && resp.response.surname && resp.response.surname !== '') ? resp.response.surname : "");
				setCompanyemail((resp.response && resp.response.email && resp.response.email !== '') ? resp.response.email : "");
				setSubdomain((resp.response && resp.response.subdomain && resp.response.subdomain !== '') ? resp.response.subdomain : "");
				setUsername((resp.response && resp.response.username && resp.response.username !== '') ? resp.response.username : "");
				setAgencyId((resp.response && resp.response._id && resp.response._id !== '') ? resp.response._id : "");
				setActiveTab("2");
			}
		} else {
			toast.error(resp.message);
		}
	})
	
	const closeWindow = () => {
		authWindow.close();
	}
	const checkAuthWindow = () => {
		const check = setInterval(() => {
			if (!authWindow || authWindow.closed || authWindow.closed === undefined) {
				clearInterval(check)
				setSbuttonDisabled(false)
			}
		}, 1000)
	}
	const openAuthWindow = (provider) => {
		const width = 600, height = 600
		const left = (window.innerWidth / 2) - (width / 2)
		const top = (window.innerHeight / 2) - (height / 2)
		const url = `${NodeURL}/site/${provider}/auth?socketId=${socket.id}&logintype=user`;
		setAuthWindow(window.open(url, '', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`))
	}

	const GoogleAuth = (e) => {
		e.preventDefault();
		setSbuttonDisabled(true);
		openAuthWindow('google');
		checkAuthWindow();
	}

	const LinkedInAuth = (e) => {
		e.preventDefault();
		setSbuttonDisabled(true);
		openAuthWindow('linkedin');
		checkAuthWindow();
	}
	return (
		<>
			<ToastContainer position="top-right" autoClose={2500} closeOnClick />
			<Modal
				isOpen={props.loginModal}
				className={
					forgotPassworsModal
						? "agency-singups agency-forget"
						: "agency-singups"
				}
				centered={true}
				toggle={(e) => props.closeModal(e)}
			>
				<ModalBody>
					{forgotPassworsModal ? (
						<div className="forgotval-stru">
							<div className="parll-left">
								<a href="/">
									{" "}
									<img
										className="sign-logo"
										// src={
										// 	// siteLogo && String(siteLogo).includes("uploads")
										// 	// 	? `${NodeURL}/${siteLogo}`
										// 	// 	: 
										// 		"../../../img/front/agency-logo.png"
										// }
										src='https://www.getshifts.co.uk/uploads/images/others/1704789160480.png'
										alt="logo"
									/>{" "}
								</a>
								<img
									src="../../../img/front/forgot-password.png"
									alt="forgot password"
								/>
							</div>
							{/* <div className="parll-right">
								<h4> Forgot Password </h4>
								{forgotpassworda ? (
								<div className="forget-step1">
									<AvForm onValidSubmit={onEmailSend}>
									<div className="signup-groups">
										<div className="group-control">
										<AvField
											type="text"
											name="femail"
											placeholder="Email Address"
											value={femail}
											onChange={(e) => forgotHandleChange(e)}
											autoComplete="femail"
											required
											validate={{
											required: {
												value: true,
												errorMessage: "This is required field",
											},
											email: {
												value: true,
												errorMessage: "Invalid email address",
											},
											}}
										/>
										<span className="pro-updates">
											<img
											src="../../../img/front/sign-email.svg"
											alt="sign email"
											/>
										</span>
										</div>
									</div>
									<p>
										{" "}
										By clicking Send, A passcode will be sent to your
										registered email address{" "}
									</p>
									<div className="login-progress">
										<button
										type="submit"
										className="pres-login"
										disabled={spinner}
										>
										Send{" "}
										{spinner === true ? (
											<i className="fa fa-spinner"></i>
										) : null}
										</button>
										<p>
										{" "}
										Just Remembered?{" "}
										<a
											href=""
											onClick={(e) => forgotPasswordPop(e, false)}
										>
											{" "}
											Click Here{" "}
										</a>
										</p>
									</div>
									</AvForm>
								</div>
								) : null}
								{forgotpasswordb ? (
								<div className="forget-step2">
									<AvForm onValidSubmit={onSubmitResetCode}>
									<div className="signup-groups">
										<div className="group-control">
										<AvField
											type="text"
											name="resetcode"
											placeholder="Passcode"
											value={resetcode}
											onChange={(e) => handleResetCode(e)}
											autoComplete="resetcode"
											required
											validate={{
											required: {
												value: true,
												errorMessage: "This is required field",
											},
											}}
										/>
										<span className="pro-updates">
											<img
											src="../../../img/front/sign-email.svg"
											alt="sign email"
											/>
										</span>
										<p className="resend-code">
											<a href="#" onClick={(e) => onEmailSend(e)}>
											{" "}
											Resend Code{" "}
											</a>
										</p>
										</div>
									</div>
									<p>
										{" "}
										Enter the passscode that has sent to your registered
										email address {fdemail}{" "}
									</p>
									<div className="login-progress">
										<button type="submit" className="pres-login">
										Send
										</button>
										<p>
										{" "}
										Just Remembered?{" "}
										<a
											href=""
											onClick={(e) => forgotPasswordPop(e, false)}
										>
											{" "}
											Click Here{" "}
										</a>
										</p>
									</div>
									</AvForm>
								</div>
								) : null}
								{forgotpasswordc ? (
								<div className="forget-step3">
									<AvForm onValidSubmit={changePassword}>
									<div className="signup-groups">
										<div className="group-control">
										<AvField
											type="password"
											name="newpassword"
											placeholder="New Password"
											value={newpassword}
											onChange={(e) => onHandleChange(e, "newpassword")}
											autoComplete="newpassword"
											required
											validate={{
											required: {
												value: true,
												errorMessage: "This is required field",
											},
											minLength: {
												value: 6,
												errorMessage:
												"Password must have minimum 6 characters",
											},
											}}
										/>
										<span className="pro-updates">
											<img
											src="../../../img/front/sign-lock.svg"
											alt="sign profile"
											/>
										</span>
										</div>
										<div className="group-control">
										<AvField
											type="password"
											name="confirmnewpassword"
											placeholder="Confirm Password"
											value={confirmnewpassword}
											onChange={(e) =>
											onHandleChange(e, "confirmnewpassword")
											}
											autoComplete="confirmnewpassword"
											required
											validate={{
											required: {
												value: true,
												errorMessage: "This is required field",
											},
											match: {
												value: "newpassword",
												errorMessage: "Passwords didn't match",
											},
											minLength: {
												value: 6,
												errorMessage:
												"Confirm password must have minimum 6 characters",
											},
											}}
										/>
										<span className="pro-updates">
											<img
											src="../../../img/front/sign-lock.svg"
											alt="sign profile"
											/>
										</span>
										</div>
									</div>
									<div className="login-progress">
										<button
										type="submit"
										className="pres-login"
										disabled={spinner}
										>
										Submit{" "}
										{spinner === true ? (
											<i className="fa fa-spinner"></i>
										) : null}
										</button>
									</div>
									</AvForm>
								</div>
								) : null}
							</div> */}
							<div className="Parll-right">
								<h3>Please verify your Email</h3>
							</div>
						</div>
					) : null}
					{!forgotPassworsModal ? (
						<div className="modelsign-stru">
							<div className="left-sign">
								<a href="/">
									
									<img
										className="sign-logo"
										src={
											siteLogo && String(siteLogo).includes("uploads")
												? `${NodeURL}/${siteLogo}`
												: "../../../img/front/agency-logo.png"
										}
										// src="https://www.getshifts.co.uk/uploads/images/others/1704789160480.png"
										
									alt="logo"/>
								</a>
								<img src="../../../img/home/signup.png" alt="signup" />
							</div>
							<div className="right-sign">
								{
									!unverified && !showResendModal && !ackResendModal && !ackNewEmail &&
									<>
										<Nav tabs>
											<NavItem>
												<NavLink
													className={classnames({ active: activeTab === "1" })}
													onClick={() => {
														toggle("1");
													}}
												>
													{" "}
													Sign In{" "}
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames({ active: activeTab === "2" })}
													onClick={() => {
														toggle("2");
													}}
												>
													{" "}
													Sign Up{" "}
												</NavLink>
											</NavItem>
										</Nav>
										<TabContent activeTab={activeTab}>
											<TabPane tabId="1">
												<AvForm onValidSubmit={onSubmitLogin}>
													<div className="signup-groups">
														<div className="group-control">
															<AvField
																type="text"
																name="email"
																placeholder="Email Address"
																value={email}
																onChange={(e) => onEmailChange(e)}
																autoComplete="email"
																required
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This is required field",
																	},
																	email: {
																		value: true,
																		errorMessage: "Invalid email address",
																	},
																}}
															/>
															<span className="pro-updates">
																<img
																	src="../../../img/front/sign-email.svg"
																	alt="sign profile"
																/>
															</span>
														</div>
														<div className="group-control">
															<AvField
																type={showLogPwd ? "text" : "password"}
																name="name"
																placeholder="Password"
																required
																value={password}
																onChange={(e) => onPasswordChange(e)}
																autoComplete="name"
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This is required field",
																	},
																}}
															/>
															<span className="pro-updates">
																<img
																	onClick={() => setShowLogPwd(!showLogPwd)}
																	src={showLogPwd ? "../../../img/front/sign-unview.png" : "../../../img/front/sign-view.png"}
																	alt="sign lock"
																/>
															</span>
														</div>
														<div className="sign-process">
															<span>
																<label className="cus-check">
																	{" "}
																	Remember me{" "}
																	<input
																		type="checkbox"
																		onChange={(e) => rememberMe(e)}
																		checked={remember}
																	/>
																	<span className="cus-checkmark"></span>
																</label>
															</span>
															<button
																type="button"
																className="for-password"
																onClick={(e) => forgotPasswordPop(e, true)}
															>
																Forgot Password?{" "}
															</button>
														</div>
														<div className="login-progress">
															<button
																type="submit"
																className="pres-login"
																disabled={spinner}
															>
																Sign In{" "}
																{spinner === true ? (
																	<i className="fa fa-spinner"></i>
																) : null}
															</button>
															<p>
																{" "}
																New User?{" "}
																<a href="" onClick={(e) => registerTab(e)}>
																	{" "}
																	Try for free{" "}
																</a>
															</p>
															<p className="acc-quc"><b>or access quickly</b></p>
															<div className="login-but">
																<button type="button" disabled={sbuttonDisabled} className="" onClick={GoogleAuth}><img src="../../../img/google.png" /> Google</button>
																<button type="button" disabled={sbuttonDisabled} className="" onClick={LinkedInAuth}><img src="../../../img/linkedin.png" /> LinkedIn</button>
																<button type="button" onClick={closeWindow} id="closeWindow" style={{ display: 'none' }}></button>
															</div>
														</div>
													</div>
												</AvForm>
											</TabPane>
											<TabPane tabId="2">
												{/* <AvForm onValidSubmit={showRegisterDiva}> */}
												<AvForm onSubmit={onSubmitRegister}>
													{showregistera === true ? (
														<div className="signup-groups sign-up-page">
															{registerTabcond === 1 ? (
																<div className="tab-first-step">
																	<div className="sign-top">
																		<p>
																			Try Get Shifts for {trialPeriod} days Free
																		</p>
																		<div className="login-part">
																			<span className="log-number">1</span>
																			<span className="log-tit">Your Details</span>
																		</div>
																	</div>
																	<div className="group-control">
																		<AvField
																			type="text"
																			name="firstname"
																			placeholder="First Name"
																			value={firstname}
																			onChange={(e) =>
																				onHandleChange(e, "firstname")
																			}
																			autoComplete="firstname"
																			required
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This is required field",
																				},
																			}}
																		/>
																		<span className="pro-updates">
																			<img
																				src="../../../img/front/sign-profile.svg"
																				alt="sign profile"
																			/>
																		</span>
																	</div>
																	<div className="group-control">
																		<AvField
																			type="text"
																			name="surname"
																			placeholder="Last Name"
																			value={surname}
																			onChange={(e) => onHandleChange(e, "surname")}
																			autoComplete="surname"
																			required
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This is required field",
																				},
																			}}
																		/>
																		<span className="pro-updates">
																			<img
																				src="../../../img/front/sign-profile.svg"
																				alt="sign profile"
																			/>
																		</span>
																	</div>
																	<div className="group-control">
																		<AvField
																			type="email"
																			name="companyemail"
																			placeholder="Email Address"
																			value={companyemail}
																			onChange={(e) =>
																				onHandleChange(e, "companyemail")
																			}
																			autoComplete="companyemail"
																			required
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This is required field",
																				},
																				email: {
																					value: true,
																					errorMessage: "Invalid email address",
																				},
																			}}
																		/>
																		<span className="pro-updates">
																			<img
																				src="../../../img/front/sign-email.svg"
																				alt="sign profile"
																			/>
																		</span>
																	</div>
																	<div className="sign-process already-process">
																		{/* <span> Already a user? <a href="" onClick={e => loginTab(e)}> Sign In </a></span> */}
																		{CheckValue(firstname) &&
																			CheckValue(surname) &&
																			CheckValue(companyemail) ? (
																			<button
																				type="submit"
																				className="progress-next"
																				onClick={(e) => nextbutton(e)}
																			>
																				{" "}
																				Next
																			</button>
																		) : (
																			<button
																				type="submit"
																				className="progress-next"
																			>
																				{" "}
																				Next
																			</button>
																		)}
																	</div>
																	<p className="acc-quc"><b>or access quickly</b></p>
																	<div className="login-but">
																		<button type="button" disabled={sbuttonDisabled} className="" onClick={GoogleAuth}><img src="../../../img/google.png" /> Google</button>
																		<button type="button" disabled={sbuttonDisabled} className="" onClick={LinkedInAuth}><img src="../../../img/linkedin.png" /> LinkedIn</button>
																		<button type="button" onClick={closeWindow} id="closeWindow" style={{ display: 'none' }}></button>
																	</div>
																</div>
															) : registerTabcond === 2 ? (
																<div className="tab-first-step">
																	<div className="sign-top">
																		<p>
																			Try Get Shifts for {trialPeriod} days Free
																		</p>
																		<div className="login-part">
																			<span className="log-number">2</span>
																			<span className="log-tit">
																				Your Business Details
																			</span>
																		</div>
																	</div>
																	<div className="group-control">
																		<AvField
																			type="text"
																			name="companyname"
																			placeholder="Company / Organization Name"
																			value={companyname}
																			onChange={(e) =>
																				onHandleChange(e, "companyname")
																			}
																			autoComplete="companyname"
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This is required field",
																				},
																			}}
																		/>
																		<span className="pro-updates">
																			<img
																				src="../../../img/front/sign-email.svg"
																				alt="sign profile"
																			/>
																		</span>
																	</div>
																	<div className="group-control">
																		<AvField
																			type="number"
																			name="phone"
																			placeholder="Phone Number"
																			value={phone}
																			onChange={(e) => onHandleChange(e, "phone")}
																			autoComplete="phone"
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This is required field",
																				},
																				pattern: {
																					value:
																						"^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$",
																					errorMessage: "Invalid phone number",
																				},
																			}}
																		/>
																		<span className="pro-updates">
																			<img
																				src="../../../img/front/sign-phone.svg"
																				alt="sign profile"
																			/>
																		</span>
																	</div>
																	<div
																		className={
																			String(subdomain).length > 0
																				? "group-control domain-empty"
																				: "group-control domain-empty domainblink"
																		}
																	>
																		<label> Choose a Domain </label>
																		<div className="text-lab">
																			<AvField
																				type="text"
																				name="subdomain"
																				placeholder="companyname"
																				value={subdomain}
																				onChange={(e) =>
																					onHandleChange(e, "subdomain")
																				}
																				autoComplete="subdomain"
																				validate={{
																					required: {
																						value: true,
																						errorMessage:
																							"Please enter a Domain Name",
																					},
																					pattern: {
																						value: "^[A-Za-z0-9]+$",
																						errorMessage:
																							"Your  Domain Name must be composed only with letter and numbers",
																					},
																					minLength: {
																						value: 6,
																						errorMessage:
																							"Your  Domain Name must be more than 6 characters",
																					},
																					maxLength: {
																						value: 16,
																						errorMessage:
																							"Your  Domain Name must be less than 16 characters",
																					},
																				}}
																			/>
																			<span>.getshifts.co.uk</span>
																			<span className="informs-globe">
																				<Tooltip
																					placement="top"
																					content="Choose a subdomain for your company/organisation, this could be the full name or a shorter memorable version of your company/organisation name"
																				>
																					<i
																						className="fa fa-info"
																						aria-hidden="true"
																					></i>
																				</Tooltip>
																			</span>
																		</div>
																		{/* <span className={subdomain && String(subdomain).length > 0 && String(subdomain) !== String(undefined) ? "dmn-sign abs-moved-value_added" : "dmn-sign abs-moved"} >.getshifts.co.uk</span> 
                                                                    <div className={subdomain && String(subdomain).length > 0 && String(subdomain) !== String(undefined) ? "domain-uppends valueadded" : "domain-uppends"}>
                                                                        <span className="dmn-sign"> <span>{subdomain} </span><span className="domain-names">.getshifts.co.uk </span></span>
                                                                        <input type='text' value=".getshifts.co.uk"  disabled/> 
                                                                        <span>.getshifts.co.uk</span> 
                                                                    </div> */}
																		<span className="pro-updates">
																			<img
																				src="../../../img/front/sign-domain.svg"
																				alt="sign profile"
																			/>
																		</span>
																	</div>
																	<div className="sign-process already-process">
																		{/* <span> Already a user? <a href="" onClick={e => loginTab(e)}> Sign In </a></span> */}
																		<button
																			tyep="button"
																			className="signpro-prev"
																			onClick={(e) => prevRegisterButton(e)}
																		>
																			{" "}
																			Previous{" "}
																		</button>
																		{CheckValue(companyname) &&
																			CheckValue(phone) &&
																			CheckValue(subdomain) ? (
																			<button
																				type="submit"
																				className="progress-next"
																				onClick={(e) => nextbutton(e)}
																			>
																				Next{" "}
																			</button>
																		) : (
																			<button
																				type="submit"
																				className="progress-next"
																			>
																				Next{" "}
																			</button>
																		)}
																	</div>
																</div>
															) : registerTabcond === 3 ? (
																<div className="tab-first-step">
																	<div className="sign-top">
																		<p>
																			Try Get Shifts for {trialPeriod} days Free
																		</p>
																		<div className="login-part">
																			<span className="log-number">3</span>
																			<span className="log-tit">
																				Create Your Login Details
																			</span>
																		</div>
																	</div>
																	<div className="group-control">
																		<AvField
																			type="text"
																			name="username"
																			placeholder="User Name"
																			value={username}
																			onChange={(e) =>
																				onHandleChange(e, "username")
																			}
																			autoComplete="username"
																			required
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This is required field",
																				},
																			}}
																		/>
																		<span className="pro-updates">
																			<img
																				src="../../../img/front/sign-profile.svg"
																				alt="sign profile"
																			/>
																		</span>
																	</div>
																	<div className="group-control">
																		<AvField
																			type={showPassword ? "text" : "password"}
																			name="rpassword"
																			placeholder="Password"
																			value={rpassword}
																			onChange={(e) =>
																				onHandleChange(e, "rpassword")
																			}
																			autoComplete="rpassword"
																			required
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This is required field",
																				},
																				minLength: {
																					value: 6,
																					errorMessage:
																						"Password must have minimum 6 characters",
																				},
																			}}
																		/>
																		<span className="pro-updates">
																			<img
																				src={
																					showPassword
																						? "../../../img/front/sign-unview.png"
																						: "../../../img/front/sign-view.png"
																				}
																				alt="sign profile"
																				onClick={() =>
																					setShowPassword(!showPassword)
																				}
																			/>
																		</span>
																	</div>
																	<div className="group-control">
																		<AvField
																			type={showCPassword ? "text" : "password"}
																			name="confirmpassword"
																			placeholder="Confirm Password"
																			value={confirmpassword}
																			onChange={(e) =>
																				onHandleChange(e, "confirmpassword")
																			}
																			autoComplete="confirmpassword"
																			required
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This is required field",
																				},
																				match: {
																					value: "rpassword",
																					errorMessage: "Passwords didn't match",
																				},
																				minLength: {
																					value: 6,
																					errorMessage:
																						"Confirm password must have minimum 6 characters",
																				},
																			}}
																		/>
																		<span className="pro-updates">
																			<img
																				src={
																					showCPassword
																						? "../../../img/front/sign-unview.png"
																						: "../../../img/front/sign-view.png"
																				}
																				alt="sign profile"
																				onClick={() =>
																					setShowCPassword(!showCPassword)
																				}
																			/>
																		</span>
																	</div>
																	<div className="sign-process prec-process">
																		{/* <button tyep="button" className="signpro-prev" onClick={e => showRegisterDivb(e)}> Previous </button> */}
																		{registerTabcond === 2 ||
																			registerTabcond === 3 ? (
																			<button
																				tyep="button"
																				className="signpro-prev"
																				onClick={(e) => prevRegisterButton(e)}
																			>
																				{" "}
																				Previous{" "}
																			</button>
																		) : null}
																		{spinner ? (
																			<button
																				type="submit"
																				className="progress-next"
																				disabled={spinner}
																				style={{ cursor: "progress" }}
																			>
																				Sign Up
																			</button>
																		) : (
																			<button
																				type="submit"
																				className="progress-next"
																			>
																				Sign Up
																			</button>
																		)}
																	</div>
																</div>
															) : null}
															{/* <div className="group-control">
                                                    <AvField type="text" name="companyemail" placeholder="Company Email Address" value={companyemail} onChange={e => onHandleChange(e, 'companyemail')} autoComplete="companyemail" required validate={{
                                                        required: { value: true, errorMessage: "This is required field" },
                                                        email: { value: true, errorMessage: "Invalid email address" }
                                                    }} />
                                                    <span className="pro-updates">
                                                        <img src="../../../img/front/sign-email.svg" alt="sign profile" />
                                                    </span>
                                                </div>
                                                <div className="sign-process already-process">
                                                    <span> Already a user? <a href="" onClick={e => loginTab(e)}> Sign In </a></span>
                                                    <button type="submit" className="progress-next">
                                                        Next
                                                    </button>
                                                </div> */}
														</div>
													) : null}
												</AvForm>
												{/* <AvForm onValidSubmit={onSubmitRegister}>
                                            {showregisterb === true ? <div className="signup-groups">
                                                <div className="group-control">
                                                    <AvField type="password" name="confirmpassword" placeholder="Confirm Password" value={confirmpassword} onChange={e => onHandleChange(e, 'confirmpassword')} autoComplete="confirmpassword" required validate={{
                                                        required: { value: true, errorMessage: "This is required field" },
                                                        match: { value: "rpassword", errorMessage: "Passwords didn't match" },
                                                        minLength: { value: 6, errorMessage: "Confirm password must have minimum 6 characters" }
                                                    }} />
                                                    <span className="pro-updates">
                                                        <img src="../../../img/front/sign-lock.svg" alt="sign profile" />
                                                    </span>
                                                </div>
                                                <div className="sign-process prec-process">
                                                    <button tyep="button" className="signpro-prev" onClick={e => showRegisterDivb(e)}> Previous </button>
                                                    <button type="submit" className="progress-next" disabled={spinner}>
                                                        Sign Up {spinner === true ? <i className="fa fa-spinner"></i> : null}
                                                    </button>
                                                </div>
                                            </div> : null}
                                        </AvForm> */}
											</TabPane>
										</TabContent>
									</>}
								{unverified &&
									<div>
										<div className="no-data">
											<img src={unverified_img} alt='unverified' style={{ width: "37px", position: "relative", top: "44px" }} /><h3 style={{ fontSize: "27px" }}>Email verification required</h3>
											<p style={{ fontSize: "20px", marginBottom: "2rem" }}>Your Get Shifts email address is not yet verified, Please check you registered email for the verification email (check you spam too) and follow instructions to verify your Get Shifts account.</p>
											<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
												<button className="email-ver-button" style={{ fontSize: "17px" }} onClick={resendVerificationHandler}>Resend verification link</button>
												<button className="email-ver-new" style={{ fontSize: "17px" }} onClick={() => { setAckResendModal(true); setUnverified(false) }}>Change Email</button>
											</div>
										</div>
									</div>}
								{
									showResendModal &&
									<>
										<div className="no-data">
											<img src={tick_img} alt='unverified' style={{ width: "37px", position: "relative", top: "63px" }} /><h3 style={{ fontSize: "27px" }}>Verification email sent</h3>
											<p style={{ fontSize: "20px", marginBottom: "2rem" }}>A verification email has been sent. Please check your registered email (including spam) and follow the instructions to verify your Get Shifts account.</p>
											<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
												<button className="email-ver-button" onClick={(e) => props.closeModal(e)} >Close</button>
											</div>
										</div>
									</>
								}
								{ackResendModal &&
									<div>
										{console.log(newEmail)}
										<div className="no-data">
											<img src={unverified_img} alt='unverified' style={{ width: "37px", position: "relative", top: "44px" }} /><h3 style={{ fontSize: "27px" }}>Alternate email address</h3>
											<p style={{ fontSize: "20px", marginBottom: "2rem" }}>Your Get Shifts email address is not yet verified. Please enter an alternative email address to verify your account.</p>
											<input type='email' value={newEmail} onChange={(e) => setNewEmail(e.target.value)} placeholder="Enter your new E-mail address" style={{ width: "100%", margin: "1px 45px", border: "2px solid grey", borderRadius: "5px", marginBottom: "20px" }} />
											<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>

												<button className="email-ver-button" onClick={changeEmailValidation}>Submit</button>
											</div>
										</div>
									</div>}
								{ackNewEmail &&
									<div>
										<div className="no-data">
											<img src={tick_img} alt='unverified' style={{ width: "37px", position: "relative", top: "48px", right: "-30px" }} /><h3 style={{ fontSize: "27px" }}>New email Updated</h3>
											<p style={{ fontSize: "20px", marginBottom: "2rem" }}>New email address is updated. please check and verifying your new email.</p>
											<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
												<button className="email-ver-button" onClick={() => { setAckNewEmail(false); setAckResendModal(false); setShowResendModal(false); setUnverified(false) }}>Close</button>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					) : (
						false
					)}
				</ModalBody>
			</Modal>
		</>
	);
};
export default AuthetiCationModal;
