/*eslint no-unused-vars: [0, { "caughtErrors": "none" }]*/
import React from "react";
import Context from "./context";
import jwt_decode from "jwt-decode";
import request from "../api/api";

class Gettoken extends React.Component {
	state = {
		siteLogo: '',
		facebookLink: '',
		twitterLink: '',
		siteAddress: '',
		instagramLink: '',
		trialPeriod: 0
	};
	componentDidMount() {	
		this.getSiteLogo();
	}
	getSiteLogo = () => {
		request({
			url: "/site/settings",
			method: "POST"
		}).then(res => {
			if (res.status === 1 && res.response && res.response.settings) {
				this.setState({
					siteLogo: res.response.settings.logo ? res.response.settings.logo : '',
					facebookLink: res.response.settings.facebook_link ? res.response.settings.facebook_link : '',
					twitterLink: res.response.settings.twitter_link ? res.response.settings.twitter_link : '',
					instagramLink: res.response.settings.instagram_link ? res.response.settings.instagram_link : '',
					siteAddress: res.response.settings.siteaddress ? res.response.settings.siteaddress : '',
					trialPeriod: res.response.settings.trial_period ? parseInt(res.response.settings.trial_period) : 0,
				})
			}
		}).catch(err => {
			console.log("err", err)
		});
	};
	render() {
		const token = localStorage.getItem("APUSE");
		if (token) {
			var decoded = jwt_decode(token);
		} else {
			decoded = "No Token Available";
		}
		return (
			<Context.Provider value={{ 
				Authtoken: decoded, 
				siteLogo: this.state.siteLogo, 
				facebookLink: this.state.facebookLink,
				twitterLink: this.state.twitterLink,
				instagramLink: this.state.instagramLink,
				siteAddress: this.state.siteAddress,
				trialPeriod: this.state.trialPeriod
			}}>
				{this.props.children}
			</Context.Provider>
		);
	}
}
export default Gettoken;
