import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import Header from '../header'
import Footer from '../NewFooter'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'

// Images
import './features.scss'
import recruitment from '../../../images/feature-new/recruitment/recruitment_banner.png'
import communication_feature_img from '../../../images/feature-new/communication/feature.jpeg'

import sms from '../../../images/feature-new/communication/sms.svg'
import notification from '../../../images/feature-new/communication/notification.svg'
import chat from '../../../images/feature-new/communication/chat.svg'

import group_creation from '../../../images/feature-new/communication/group_creation.png'

import report from '../../../images/feature-new/communication/report.svg'
import assignment from '../../../images/feature-new/communication/assignment.svg'

import communication_flows from '../../../images/feature-new/communication/communication_flows.png'

import stake_1 from '../../../images/feature-new/communication/01.svg'
import stake_2 from '../../../images/feature-new/communication/02.svg'
import { GetStartButton, GetStartCTA, GetStartCTATrail } from '.'

const Communication = () => {
   return (
      <div>
         <MetaTags>
            <title>Integrated Communication Features - Getshifts</title>
            <meta
               name="keyword"
               content="integrated communication, communication feature, staff communication, client communication, stakeholder interaction, email communication, SMS communication, app-based chat, push notifications, system-generated messages, connectivity enhancement, collaboration tool"
            />
            <meta
               name="description"
               content="Explore Get Shifts' integrated communication feature designed to streamline interactions among staff, clients, and stakeholders. Enhance connectivity and collaboration through email, SMS, app-based chat, push notifications, and system-generated messages."
            />
         </MetaTags>
         <Header />

         <section className="cover-banners">
            <section className="home-banner">
               <div className="container">
                  <div className="banner-parts feature_parts">
                     <div className="left-banner">
                        <h4 className="sht-list1 new_sht-list1">Communication</h4>
                        <h6>Integrated Communication</h6>
                        <p>
                           Get Shifts presents integrated communication, an advanced communication feature embedded throughout the application that streamlines
                           interactions between staff, clients, and other stakeholders. This robust system enhances connectivity and collaboration by
                           facilitating various forms of communication, including email, SMS, app-based chat, push notifications, and system-generated messages.
                        </p>
                        <GetStartButton />
                     </div>

                     <div className="right_banners">
                        <img src={communication_feature_img} alt="recruitment" />
                     </div>
                  </div>
               </div>
            </section>
         </section>

         <section className="communication_section">
            <div className="container">
               <h6>Comprehensive Communication Tools</h6>
               <p>Integrated Communication provides a variety of tools to ensure smooth and effective communication across the organisation:</p>
               <div className="row mt-5">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card com__card">
                        <span className="feature__img">
                           <img src={sms} alt="Email and SMS" loading="lazy" width="80px" height="80px" />
                        </span>
                        <h6>Email and SMS</h6>
                        <p>
                           Direct communication with staff via email and SMS ensures timely and effective dissemination of important information and updates.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card com__card">
                        <span className="feature__img">
                           <img src={notification} alt="App Push Notifications" loading="lazy" width="80px" height="80px" />
                        </span>
                        <h6>App Push Notifications</h6>
                        <p>
                           Targeted push notifications keep users informed of critical updates, reminders, and alerts directly on their mobile devices,
                           enhancing responsiveness and engagement.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card com__card">
                        <span className="feature__img">
                           <img src={chat} alt="Employee Chat" loading="lazy" width="80px" height="80px" />
                        </span>
                        <h6>Employee Chat</h6>
                        <p>
                           A built-in chat function within the app allows employees to communicate seamlessly with one another, fostering collaboration and
                           immediate sharing of information.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="group_section">
            <div className="container">
               <div className="card mb-3">
                  <div className="group_img">
                     <img src={group_creation} loading="lazy" />
                  </div>
                  <div className="group_info">
                     <h6>Automated Group Creation</h6>
                     <p>
                        The system automatically creates groups by area and roles, facilitating tailored communication among teams. This feature ensures that
                        messages and tasks are directed to the appropriate personnel, improving efficiency and response times.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <section className="stake_section">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 mb-4">
                     <div className="stake_head">
                        <h6>Stakeholder Engagement</h6>
                        <p>Integrated Communication extends beyond internal operations to include clients and other external stakeholders:</p>
                     </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12 mb-4">
                     <ul className="stake_list">
                        <li>
                           <span>
                              <img src={stake_1} />
                           </span>
                           <h6>Client Communication</h6>
                           <p>
                              Direct communication channels within the system allow for seamless interactions with clients, enabling quick resolution of queries
                              and proactive engagement.
                           </p>
                        </li>
                        <li>
                           <span>
                              <img src={stake_2} />
                           </span>
                           <h6>Stakeholder Messages</h6>
                           <p>
                              Customisable communication options are available for different stakeholder groups, ensuring that all parties stay informed and
                              aligned with project goals and updates.
                           </p>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </section>
         <GetStartCTA />

         <section className="category_section">
            <div className="container">
               <div className="cat_head">
                  <h6>Categorised System Communication</h6>
                  <p>To enhance organisational efficiency, the system categorises communications based on type and urgency, including:</p>
               </div>
               <div className="row mt-4">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__cat">
                        <img src={report} alt="Incident Reporting" loading="lazy" width="24px" height="23px" />

                        <h6>Incident Reporting</h6>
                        <p>
                           Employees and clients can report incidents directly through the system, which are then categorised and escalated according to
                           predefined protocols.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__cat">
                        <img src={assignment} alt="Task Assignment and Management" loading="lazy" width="22px" height="24px" />

                        <h6>Task Assignment and Management</h6>
                        <p>
                           Tasks are communicated and managed through the system, with assignments sent directly to responsible team members. The system tracks
                           progress and updates to ensure accountability and timely completion of tasks.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="stream_section">
            <div className="container">
               <div className="row align-items-center mt-4">
                  <div className="col-lg-8 col-md-8 col-sm-6 col-xs-12 mb-4">
                     <ul className="streamlines_list">
                        <li>
                           <h6>1.Enhanced Collaboration</h6>
                           <p>Facilitates real-time interactions among teams, boosting collaboration and fostering a more connected workplace.</p>
                        </li>
                        <li>
                           <h6>2. Increased Productivity</h6>
                           <p>Minimises delays in communication, leading to quicker decision-making and enhanced productivity</p>
                        </li>
                        <li>
                           <h6>3. Improved Stakeholder Relations</h6>
                           <p>Maintains continuous engagement with clients and stakeholders, improving relationships and client satisfaction</p>
                        </li>
                        <li>
                           <h6>4. Streamlined Incident and Task Management</h6>
                           <p>Provides a structured platform for reporting issues and managing tasks, ensuring effective resolution and oversight</p>
                        </li>
                     </ul>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="streamlines_flows">
                        <div className="streamlines_flows_img">
                           <img src={communication_flows} loading="lazy" />
                        </div>
                        <h6>Streamlining Communication Flows</h6>
                        <p>
                           Integrated Communication by Get Shifts streamlines communication flows within the organisation, ensuring that everyone, from
                           management to staff, is on the same page. This unified approach reduces the risk of miscommunication and enhances the overall
                           efficiency of operations.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <GetStartCTATrail />

         <section className="new_cta_section">
            <div className="container">
               <h6>Transform Your Business Communication</h6>
               <p>
                  Discover how Integrated Communication by Get Shifts can transform your communication processes, enhance stakeholder engagement, and drive
                  better business outcomes. With its comprehensive set of tools and automated features, this system is an indispensable asset for any
                  organisation looking to improve communication efficiency and operational effectiveness.
               </p>
            </div>
         </section>

         <Footer />
      </div>
   )
}

export default Communication
