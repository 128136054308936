import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import { useLocation } from 'react-router-dom'
import SignaturePad from 'react-signature-canvas'
import { toast } from 'react-toastify'
import { Modal, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import request ,{NodeURL}from '../../api/api'
import logo from '../../images/common/agency-logo.png'
import check_color_img from '../../images/check_color.svg'
import cloud_upload_img from '../../images/cloud-upload.svg'
import delete_bin_img from '../../images/delete-bin.svg'
import './document.css'
import './doc.scss'
import 'cropperjs/dist/cropper.css'
import SuccessPage from './SuccessPage'
import FileSaver from 'file-saver'
const Document = props => {
   const domHistory = useLocation()
   let id = domHistory.hash.slice(1)
   const sigCanvas = useRef()

   const [doc, setDoc] = useState('')
   const [recipient, setRecipient] = useState({})
   const [isCompleted, setIsCompleted] = useState(false)
   const [SuccessPageDownload, setSuccessPageDownload] = useState('0')
   const [SuccessPageSign, setSuccessPageSign] = useState('0')
   const [successpage, setsuccesspage] = useState(false)
   const [signature_modal_open, setsignature_modal_open] = useState(false)
   const [activeTab, setActiveTab] = useState('1')
   const [pencilColor, setPencilColor] = useState('black')
   const [textColor, setTextColor] = useState('black')
   const [textFontFamily, setTextFontFamily] = useState('Mr De Haviland')
   const [companyName, setCompanyName] = useState('')
   const [previewImage, setpreviewImage] = useState('')
   const [cropper, setCropper] = useState('')
   const [pdf, setPdf] = useState('')
   const [docid, setDocId] = useState('')
   const [companyLogo, setCompanyLogo] = useState(logo)

   const getData = async () => {
      const data = { id }
      const res = await request({ url: '/document/sign/getcontent', method: 'POST', data })
      if (res && res.status === 1) {
         if(res.response.agencylogo && res.response.agencylogo != null && res.response.agencylogo != undefined){
            setCompanyLogo(`${NodeURL}/${res.response.agencylogo}`)
         }
         if (res.response.status) {
            setSuccessPageDownload('1')
            setsuccesspage(true)
            setSuccessPageSign('1')
            setDocId(res.response.data._id)
            setCompanyName(res.response.recipient.name)
         } else if (res.response.recipientStatus === 3) {
            setSuccessPageDownload('0')
            setsuccesspage(true)
            setSuccessPageSign('1')
         } else {
            setDoc(res.response.data)
            setIsCompleted(res.response.status)
            setRecipient(res.response.recipient)
            setCompanyName(res.response.recipient.name)
         }
      } else {
         setSuccessPageDownload('0')
         setsuccesspage(true)
         setSuccessPageSign('0')
      }
   }
   const toggle = tab => {
      if (activeTab !== tab) setActiveTab(tab)
   }
   const OnclickModel = () => {
      setsignature_modal_open(!signature_modal_open)
   }
   const setColor = (e, color) => {
      setPencilColor(color)
      setTextColor(color)
   }
   const setFontFamily = e => {
      if (activeTab === '2') {
         let fontName = e.target.value.replace(' ', '+')
         var link = document.createElement('link')
         link.rel = 'stylesheet'
         link.type = 'text/css'
         link.href = 'http://fonts.googleapis.com/css?family=' + fontName
         document.getElementsByTagName('head')[0].appendChild(link)
         let querySelector = document.querySelector('#nameColor')
         querySelector.style.fontFamily = e.target.value
         setTextFontFamily(e.target.value)
      }
   }
   const handleChange = e => {
      setCompanyName(e.target.value)
   }
   const handleDrop = e => {
      e.preventDefault()
      e.stopPropagation()
      let file = e.dataTransfer.files[0]
      if (file && file.name) {
         let ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
         if (ext === 'gif' || ext === 'png' || ext === 'jpeg' || ext === 'jpg') {
            let blob = new Blob([file], { type: file.type })
            setpreviewImage(URL.createObjectURL(blob))
         } else {
            toast.error('Invalid image format')
         }
      }
   }
   const handleDragOver = e => {
      e.preventDefault()
   }
   const handleDragEnter = e => {
      e.preventDefault()
   }
   const handleDragLeave = e => {
      e.preventDefault()
   }
   const fileHandleChange = e => {
      const file = e.target.files[0]
      if (file) {
         const reader = new FileReader()
         reader.onloadend = () => {
            setpreviewImage(reader.result)
         }
         reader.readAsDataURL(file)
      }
   }
   const clearDraw = () => {
      sigCanvas.current.clear()
   }
   const clearTextSign = () => {
      setCompanyName('')
   }
   const clearPreview = () => {
      setpreviewImage('')
   }
   const getCropData = () => {
      if (typeof cropper !== 'undefined') {
         if (cropper.cropBoxData.width <= 300 && cropper.cropBoxData.height <= 300) {
            uploadLogo(cropper.getCroppedCanvas().toDataURL(), activeTab)
            OnclickModel()
         } else {
            toast.error('Image is bigger than 300px * 300px please reupload')
            // props.show_bottom_modal("Image is bigger than 300px * 300px please reupload");
         }
      }
   }
   const saveSignature = () => {
      if (activeTab === '1') {
         if (sigCanvas.current.isEmpty() === false) {
            let image = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
            fetch(image)
               .then(res => res.blob())
               .then(blob => {
                  const file = new File([blob], 'png', { type: 'image/png' })
                  uploadLogo(file, activeTab)
                  OnclickModel()
               })
         } else {
            toast.error('Signature is required field.')
            // return props.show_mod_pop_btm('Signature is required field.');
         }
      } else if (activeTab === '2') {
         if (companyName) {
            setTimeout(() => {
               let text = companyName
               var canvas = document.createElement('canvas')
               var ctx = canvas.getContext('2d')
               canvas.height = 40 * 2
               canvas.width = text.length * 18
               ctx.font = `normal 25pt ${textFontFamily ? textFontFamily : 'Arial'}`
               ctx.fillStyle = textColor ? textColor : 'black'
               // ctx.fillText(text, 0, canvas.height/2.1);
               ctx.fillText(text, 10, 45)
               let image = canvas.toDataURL('image/png')
               fetch(image)
                  .then(res => res.blob())
                  .then(blob => {
                     const file = new File([blob], 'png', { type: 'image/png' })
                     uploadLogo(file, activeTab)
                     OnclickModel()
                  })
            }, 100)
         } else {
            toast.error('Kindly enter the name')
            // return props.show_mod_pop_btm('Kindly enter the name');
         }
      } else if (activeTab === '3') {
         if (previewImage) {
            fetch(previewImage)
               .then(res => res.blob())
               .then(blob => {
                  const file = new File([blob], 'png', { type: 'image/png' })
                  uploadLogo(file, activeTab)
                  OnclickModel()
               })
         } else {
            toast.error('Kindly upload or drop the image')
            // return props.show_mod_pop_btm('Kindly upload or drop the image');
         }
      }
   }
   const uploadLogo = async (file, activetab) => {
      let formData = new FormData()
      formData.append('documentsign', file)
      formData.append('activetab', activetab)
      const res = await request({ url: `/document/sign/upload`, method: 'POST', data: formData })
      if (res.status === 1) {
         setPdf(res.message)
      } else {
         setPdf('')
      }
   }
   const onsubmit = async () => {
      const res = await request({ url: '/document/sign/submit', method: 'POST', data: { id: id, pdf: pdf } })
      if (res && res.status === 1) {
         toast.success(res.response)
         setPdf('')
         getData()
      } else {
         toast.error(res.response)
      }
   }
   useEffect(() => {
      getData()
   }, [])
   useEffect(() => {
      let signatureModel = document.getElementById(id)
      if (!signatureModel) return
      if (pdf) {
         signatureModel.innerHTML = `<img class="user_signature_image" src=${pdf} alt="sign" width="100%" height="100%" style="object-fit:contain" />`
      }

      signatureModel.addEventListener('click', OnclickModel)

      return () => {
         signatureModel.removeEventListener('click', OnclickModel)
      }
   }, [doc, pdf])

   const onDownload = async (docId) => {
      let res = await request({ url: '/agency/documents/downloadDocument', method: 'POST', data: {Id :docId}, responseType: 'blob' })
      let filedownload = new Blob([res], { type: 'application/pdf' })
      let name = `${companyName && companyName ? companyName : 'Document'}.pdf`
      FileSaver(filedownload, name)
   }
   return (
      <>
         <div>
            <div className="doc-header">
               <div className='doc-logo'>
               <img src={companyLogo} alt="logo" />
               </div>
            </div>
            {!successpage ? (
               <div className="doc-body-container">
                  <div className="doc-container">
                     <div className="doc-headSection">
                        <div className="doc-title">{doc && doc.fileName}</div>
                        {isCompleted && (
                           <button className="doc-download">
                              <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{ marginRight: '5px', fontSize: '18px' }} />
                              Download
                           </button>
                        )}
                     </div>
                     <div className="doc-body">
                        <div dangerouslySetInnerHTML={{ __html: doc.content }} />
                     </div>
                     {pdf && (
                        <div class="doc-submit">
                           <button onClick={onsubmit}>
                              <i className="fa fa-check" aria-hidden="true" style={{ marginRight: '10px' }} />
                              Accept & Sign
                           </button>
                        </div>
                     )}
                  </div>
               </div>
            ) : (
               <SuccessPage download={SuccessPageDownload} Sign={SuccessPageSign} onDownload={onDownload} docId={docid} />
            )}
            {signature_modal_open ? (
               <div className="popup adding_folder">
                  <div className="modal-dialog signat">
                     <div className="modal-content">
                        <div className="modal-header doc-modal-header">
                           <h4 className="modal-title" id="myModalLabel">
                              Signature
                           </h4>
                           <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={OnclickModel}>
                              &times;
                           </button>
                        </div>
                        <div className="modal-body">
                           <Nav tabs>
                              <NavItem>
                                 <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => {
                                       toggle('1')
                                    }}
                                 >
                                    {' '}
                                    Draw{' '}
                                 </NavLink>
                              </NavItem>
                              <NavItem>
                                 <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => {
                                       toggle('2')
                                    }}
                                 >
                                    {' '}
                                    Type
                                 </NavLink>
                              </NavItem>
                              <NavItem>
                                 <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => {
                                       toggle('3')
                                    }}
                                 >
                                    {' '}
                                    Upload
                                 </NavLink>
                              </NavItem>
                           </Nav>
                           <TabContent activeTab={activeTab}>
                              <TabPane tabId="1">
                                 <SignaturePad penColor={pencilColor} canvasProps={{ className: 'sigPad', width: 500, height: 200 }} ref={sigCanvas} />
                                 {/* <div className="sig-clear" onClick={(e) => clearDraw()}>Clear</div> */}
                                 <div className="sig-color">
                                    <button className={`s_black ${pencilColor === 'black' ? 's_size' : ''}`} onClick={e => setColor(e, 'black')}>
                                       <img src={check_color_img} alt="check" />
                                    </button>
                                    <button className={`s_blue ${pencilColor === 'blue' ? 's_size' : ''}`} onClick={e => setColor(e, 'blue')}>
                                       <img src={check_color_img} alt="check" />
                                    </button>
                                    <button className={`s_red ${pencilColor === 'red' ? 's_size' : ''}`} onClick={e => setColor(e, 'red')}>
                                       <img src={check_color_img} alt="check" />
                                    </button>
                                    <button className={`s_green ${textColor === 'green' ? 's_size' : ''}`} onClick={e => setColor(e, 'green')}>
                                       <img src={check_color_img} alt="check" />
                                    </button>
                                 </div>
                              </TabPane>
                              <TabPane tabId="2">
                                 <div className="choose-font">
                                    <select onChange={e => setFontFamily(e)} value={textFontFamily}>
                                       <option value="">Choose font</option>
                                       <option value="Pinyon Script" style={{ fontFamily: 'Pinyon Script' }}>
                                          Pinyon Script
                                       </option>
                                       <option value="Sofia" style={{ fontFamily: 'Sofia' }}>
                                          Sofia
                                       </option>
                                       <option value="Herr Von Muellerhoff" style={{ fontFamily: 'Herr Von Muellerhoff' }}>
                                          Herr Von Muellerhoff
                                       </option>
                                       <option value="Mr De Haviland" style={{ fontFamily: 'Mr De Haviland' }}>
                                          Mr De Haviland
                                       </option>
                                       <option value="Dawning of a New Day" style={{ fontFamily: 'Dawning of a New Day' }}>
                                          Dawning of a New Day
                                       </option>
                                       <option value="League Script" style={{ fontFamily: 'League Script' }}>
                                          League Script
                                       </option>
                                       <option value="Stalemate" style={{ fontFamily: 'Stalemate' }}>
                                          Stalemate
                                       </option>
                                       <option value="Sacramento" style={{ fontFamily: 'Sacramento' }}>
                                          Sacramento
                                       </option>
                                       <option value="Rancho" style={{ fontFamily: 'Rancho' }}>
                                          Rancho
                                       </option>
                                       <option value="Marck Script" style={{ fontFamily: 'Marck Script' }}>
                                          Marck Script
                                       </option>
                                       <option value="Allura" style={{ fontFamily: 'Allura' }}>
                                          Allura
                                       </option>
                                       <option value="cursive" style={{ fontFamily: 'cursive' }}>
                                          cursive
                                       </option>
                                       <option value="Tangerine" style={{ fontFamily: 'Tangerine' }}>
                                          Tangerine
                                       </option>
                                       <option value="Fantasy" style={{ fontFamily: 'Fantasy' }}>
                                          Fantasy
                                       </option>
                                       <option value="Arial" style={{ fontFamily: 'Arial' }}>
                                          Arial
                                       </option>
                                    </select>
                                 </div>
                                 <div className="sig-box">
                                    <input type="text" id="nameColor" className="" value={companyName} onChange={e => handleChange(e)} />
                                    <canvas id="myCanvas" value={companyName} type="hidden" style={{ display: 'none' }}></canvas>
                                 </div>
                                 <div className="sig-font"></div>
                                 <div className="sig-color">
                                    <button className={`s_black ${textColor === 'black' ? 's_size' : ''}`} onClick={e => setColor(e, 'black')}>
                                       <img src={check_color_img} alt="check" />
                                    </button>
                                    <button className={`s_blue ${textColor === 'blue' ? 's_size' : ''}`} onClick={e => setColor(e, 'blue')}>
                                       <img src={check_color_img} alt="check" />
                                    </button>
                                    <button className={`s_red ${textColor === 'red' ? 's_size' : ''}`} onClick={e => setColor(e, 'red')}>
                                       <img src={check_color_img} alt="check" />
                                    </button>
                                    <button className={`s_green ${textColor === 'green' ? 's_size' : ''}`} onClick={e => setColor(e, 'green')}>
                                       <img src={check_color_img} alt="check" />
                                    </button>
                                 </div>
                              </TabPane>
                              <TabPane tabId="3">
                                 <div
                                    className="file_drag drag-drop-zone"
                                    onDrop={e => handleDrop(e)}
                                    onDragOver={e => handleDragOver(e)}
                                    onDragEnter={e => handleDragEnter(e)}
                                    onDragLeave={e => handleDragLeave(e)}
                                 >
                                    {/* {previewImage && previewImage !== null && <div className="sig-clear" onClick={(e) => clearPreview()}>Clear</div>} */}
                                    <div className="sig-drag_upload">
                                       {(!previewImage || previewImage === '') && (
                                          <div className="drop_word">
                                             <img src={cloud_upload_img} alt="Cloud" />
                                             <h3>Drag & drop a signature image or scan</h3>
                                             <p>(or)</p>
                                          </div>
                                       )}
                                       {(!previewImage || previewImage === '') && (
                                          <div className="sig-upload">
                                             <input type="file" id="upload" hidden onChange={e => fileHandleChange(e)} accept="image/*" />
                                             <label htmlFor="upload">Upload Image</label>
                                          </div>
                                       )}
                                       {previewImage && previewImage !== null && (
                                          <div className="sig-upload-preview">
                                             <Cropper
                                                style={{ height: 200, width: '100%' }}
                                                zoomTo={0.5}
                                                initialAspectRatio={1}
                                                preview=".img-preview"
                                                src={previewImage}
                                                viewMode={1}
                                                minCropBoxHeight={5}
                                                minCropBoxWidth={5}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={1}
                                                checkOrientation={false}
                                                onInitialized={instance => {
                                                   setCropper(instance)
                                                }}
                                                guides={true}
                                             />
                                          </div>
                                       )}
                                    </div>
                                 </div>
                              </TabPane>
                           </TabContent>
                        </div>
                        <div className="footer_cnt">
                           <p>
                              By signing this document with an electronic signature, I agree that such signature will be valid as handwritten signatures to the
                              extent allowed by local law.
                           </p>
                        </div>
                        <div className="modal-footer">
                           {activeTab === '1' ? (
                              <div className="sig-clear new_clear" onClick={clearDraw}>
                                 <img src={delete_bin_img} alt="Clear" /> Clear
                              </div>
                           ) : activeTab === '2' ? (
                              <div className="sig-clear new_clear" onClick={clearTextSign}>
                                 <img src={delete_bin_img} alt="Clear" /> Clear
                              </div>
                           ) : (
                              previewImage &&
                              previewImage !== null && (
                                 <div className="sig-clear new_clear" onClick={clearPreview}>
                                    <img src={delete_bin_img} alt="Clear" /> Clear
                                 </div>
                              )
                           )}
                           <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => OnclickModel()}>
                              Cancel
                           </button>
                           {activeTab === '1' ? (
                              <button type="button" className="btn btn-primary" onClick={saveSignature}>
                                 Accept & sign
                              </button>
                           ) : activeTab === '2' ? (
                              <button type="button" className="btn btn-primary" onClick={saveSignature}>
                                 Accept & sign
                              </button>
                           ) : (
                              <button type="button" className="btn btn-primary" onClick={getCropData}>
                                 Accept & sign
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            ) : null}
         </div>
      </>
   )
}

export default Document
