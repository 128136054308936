import React, { useState } from "react";
import AuthetiCationModal from "./AuthetiCationModal";

let RegisterSuccess = props => {
    let [showLoginModal, setShowLoginModal] = useState(false);

    let openSigninModal = (e, tab) => {
        setShowLoginModal(true);
        // setAutheticateTab(tab);
    };

    let closeSigninModal = (e) => {
        setShowLoginModal(false);
    }

    return (
        <div className="register-success-page">
            <p>Thank you for  registering for the free trial, check your email for instructions.</p>
            <button onClick={e => openSigninModal(e, '1')}>Login</button>

            {showLoginModal === true ? 
                <AuthetiCationModal
                    loginModal={showLoginModal}
                    closeModal={e => closeSigninModal(e)}
                    // autheticateTab={autheticateTab}
                /> 
            : null}
        </div>
    )
}

export default RegisterSuccess;