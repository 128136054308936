import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import classnames from "classnames";
import Header from "./header";
import Slider from "react-slick";
import Footer from "./NewFooter";
import WOW from '../../animation/Wow';
import AuthetiCationModal from "./AuthetiCationModal";

export default function applicationfeature() {
    const [activeTab, setActiveTab] = useState('1');
    let [showLoginModal, setshowLoginModal] = useState(false),
        [autheticateTab, setautheticateTab] = useState(1);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    const settings1 = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        // speed: 1000,
        smartSpeed: 1500,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let openSigninModal = (e, tab) => {
        setshowLoginModal(true);
        setautheticateTab(tab);
    }, closeSigninModal = (e) => {
        setshowLoginModal(false);
    };
    return (
        <>
            <Header></Header>
            <section className="application-features">
                <div className="container">
                    <div className="features-heading">
                        <div className="col-md-12">
                            <h3>
                                Mobile App Features
                            </h3>
                        </div>
                        <div className="features-lists">
                            <div className="col-md-5">
                                <div className="feats-cont">
                                    <h5> About </h5>
                                    <p>Our employee app is robust, full of useful engaging features,
                                    giving your staff more control. Some of the features include, shift management,
                                    where staff can pick up open shifts, request shift cancellations as well swap shifts.
                                    They can record their exact work hours and attendance by clock in and out on the app.
                                        The app also includes Availability management, shift and compliance reminders.</p>

                                    <h6>Get Our App</h6>
                                    <ul>
                                        <li>
                                            <a href="https://apps.apple.com/us/app/agency-portal/id1484083870" rel="noopener noreferrer" target="_blank">
                                                {" "}
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/img/home/app-store.svg`}
                                                    alt="img"
                                                />{" "}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://play.google.com/store/apps/details?id=com.agencyportal.com" rel="noopener noreferrer" target="_blank">
                                                {" "}
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/img/home/play-store.svg`}
                                                    alt="img"
                                                />{" "}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="feats-gals">
                                    <img src={`${process.env.PUBLIC_URL}/img/front/device-system.png`} alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="shiftstabing">
                <div className="container">
                    <div className="shift-cases">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    Open Shift
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    Employee Schedule
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    Accept Shifts
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '4' })}
                                    onClick={() => { toggle('4'); }}
                                >
                                    Ongoing Shifts
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '5' })}
                                    onClick={() => { toggle('5'); }}
                                >
                                    Clock In And Clock Out
                            </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className="opns-ranges">
                                    <div className="col-md-6">
                                        <div className="left-moved">
                                        <img src={`${process.env.PUBLIC_URL}/img/front/open.png`} alt="img" />
                                            {/* <Slider {...settings1}>
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                
                                            </Slider> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="right-moved">
                                            <h5> Open Shifts </h5>
                                            <p> Employees can view and select and pick up unassigned shifts </p>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="opns-ranges">
                                    <div className="col-md-6">
                                        <div className="left-moved">
                                        <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                            {/* <Slider {...settings1}>
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                            </Slider> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="right-moved">
                                            <h5> Employee Schedule </h5>
                                            <p> Employees can view and select and pick up unassigned shifts </p>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div className="opns-ranges">
                                    <div className="col-md-6">
                                        <div className="left-moved">
                                        <img src={`${process.env.PUBLIC_URL}/img/front/accept.png`} alt="img" />
                                            {/* <Slider {...settings1}>
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                            </Slider> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="right-moved">
                                            <h5> Accept Shifts </h5>
                                            <p> Employees can view and select and pick up unassigned shifts </p>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="4">
                                <div className="opns-ranges">
                                    <div className="col-md-6">
                                        <div className="left-moved">
                                        <img src={`${process.env.PUBLIC_URL}/img/front/ongoing.png`} alt="img" />
                                            {/* <Slider {...settings1}>
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                            </Slider> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="right-moved">
                                            <h5> Ongoing Shifts </h5>
                                            <p> Employees can view and select and pick up unassigned shifts </p>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="5">
                                <div className="opns-ranges">
                                    <div className="col-md-6">
                                        <div className="left-moved">
                                        <img src={`${process.env.PUBLIC_URL}/img/front/onclock.png`} alt="img" />
                                            {/* <Slider {...settings1}>
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                                <img src={`${process.env.PUBLIC_URL}/img/front/open-shift.png`} alt="img" />
                                            </Slider> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="right-moved">
                                            <h5> Clock In And Clock Out </h5>
                                            <p> Employees can view and select and pick up unassigned shifts </p>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <div className="click-dot">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggle('1'); }}
                                    >
                                    <p></p>
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggle('2'); }}
                                    >
                                        <p></p>
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggle('3'); }}
                                    >
                                      <p></p>
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '4' })}
                                        onClick={() => { toggle('4'); }}
                                    >
                                       <p></p>
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '5' })}
                                        onClick={() => { toggle('5'); }}
                                    >
                                        <p></p>
                                        
                                </NavLink>
                                </NavItem>
                        </Nav>
                            </div>
                        </TabContent>
                    </div>
                </div>
            </section>
            <div className="col-md-12">
                <div className="free-offers new-get third-con">
                    <div className="sigfr-width">
                        <div className="left-subs">
                            <img src={`${process.env.PUBLIC_URL}/img/home/subscribe.png`} />
                        </div>
                        <div className="right-subs">
                            <p>Let Get Shifts handle the staffing details, so you can concentrate on the heart of your business.</p>
                            <div className="trails-sec">
                                {/* <a href="#" onClick={e => openSigninModal(e, '2')}> Get Started - <span> It's free </span> </a> */}
                                <Link to="/signup">Get started for free</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <section className="bells-notification">
                <div className="container">
                    <div className="info-vlas">
                        <div className="col-md-6">
                            <div className="shift-lists">
                                <div className="main-steps cur-act">
                                    <h4> Notifications </h4>
                                    <p> Employees get push notification for example on open, booked, cancelled shifts as well as compliance update and more </p>
                                </div>

                                <div className="main-steps">
                                    <h4> Timesheets </h4>
                                    <p> Employee timesheets can be viewed and managed on the app by the employee. </p>
                                </div>

                                <div className="main-steps">
                                    <h4> Upload </h4>
                                    <p> Employees can upload files on the app, for example timesheets and documentation. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="customs-mbs">
                                <img src={`${process.env.PUBLIC_URL}/img/front/app-stru.png`} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="others-features">
                <div className="container">
                    <div className="col-md-12">
                        <div className="cont-feats">
                            <h3> Other Features </h3>

                            <div className="othfea-lists">
                                <div className="counter-names">
                                    <img src={`${process.env.PUBLIC_URL}/img/front/other1.svg`} alt="img" />
                                    <h4>Earnings</h4>
                                    <p>Employee are able to view their earnings on the app</p>
                                </div>
                            </div>

                            <div className="othfea-lists">
                                <div className="counter-names">
                                    <img src={`${process.env.PUBLIC_URL}/img/front/other2.svg`} alt="img" />
                                    <h4>Shift Calendar</h4>
                                    <p>The calendar helps employees to view both their upcoming and historical shifts</p>
                                </div>
                            </div>

                            <div className="othfea-lists">
                                <div className="counter-names">
                                    <img src={`${process.env.PUBLIC_URL}/img/front/other3.svg`} alt="img" />
                                    <h4>Digital Timesheet Signature</h4>
                                    <p>No need for paper, client’s can sign timesheets on the mobile app</p>
                                </div>
                            </div>

                            <div className="othfea-lists">
                                <div className="counter-names">
                                    <img src={`${process.env.PUBLIC_URL}/img/front/other4.svg`} alt="img" />
                                    <h4>Profile Management</h4>
                                    <p>Employees can manage their profile and submit relevant documentation on the app</p>
                                </div>
                            </div>

                            <div className="othfea-lists">
                                <div className="counter-names">
                                    <img src={`${process.env.PUBLIC_URL}/img/front/other5.svg`} alt="img" />
                                    <h4>Availability</h4>
                                    <p>Availability schedule for the employee can be easily managed on the app.</p>
                                </div>
                            </div>

                            <div className="othfea-lists">
                                <div className="counter-names">
                                    <img src={`${process.env.PUBLIC_URL}/img/front/other6.svg`} alt="img" />
                                    <h4>Create Shifts</h4>
                                    <p>Employees can create shift and timesheets if booked directly</p>
                                </div>
                            </div>

                            <div className="othfea-lists">
                                <div className="counter-names">
                                    <img src={`${process.env.PUBLIC_URL}/img/front/other7.svg`} alt="img" />
                                    <h4>Geofencing</h4>
                                    <p>Employees can only clock in and clock out of shift only with the work location.</p>
                                </div>
                            </div>

                            <div className="othfea-lists">
                                <div className="counter-names">
                                    <img src={`${process.env.PUBLIC_URL}/img/front/other8.svg`} alt="img" />
                                    <h4>Work Location Address</h4>
                                    <p>Employees are able to view the address and location of of the work location.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className="col-md-12">
                <div className="free-offers new-get third-con">
                    <div className="sigfr-width">
                        <div className="left-subs">
                            <img src={`${process.env.PUBLIC_URL}/img/home/subscribe.png`} />
                        </div>
                        <div className="right-subs">
                            <p style={{"width":"90%"}}>Sign up for Get Shifts today Get started with a free trial for 30 days.</p>
                            <div className="trails-sec">
                                {/* <a href="#" onClick={e => openSigninModal(e, '2')}> Get Started - <span> It's free </span> </a> */}
                                <Link to="/signup">Get Started - <span style={{ fontSize: "12px" }}>It's free</span></Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {showLoginModal === true ? <AuthetiCationModal loginModal={showLoginModal} closeModal={e => closeSigninModal(e)} autheticateTab={autheticateTab} /> : null}
            <Footer></Footer>
        </>
    )
}
