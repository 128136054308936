import axios, { AxiosRequestConfig } from 'axios'

// const AUTH_TOKEN = localStorage.getItem("APUSE");
// export const NodeURL = "http://localhost:3066";
// export const SubdomainURL = "localhost:3002";

// export const NodeURL = "http://192.168.1.33:3066";
/* LIVE URL CONFIG */
// export const NodeURL = 'https://www.getshifts.co.uk'
// export const SubdomainURL = 'getshifts.co.uk'

/* STAGING URL CONFIG */

// export const SubdomainURL = "apstaging.co.uk";
export const NodeURL = "https://www.apstaging.co.uk";
export const SubdomainURL = "apstaging.co.uk";

export const client = axios.create({
   baseURL: NodeURL
})
// client.defaults.headers.common["Authorization"] = AUTH_TOKEN;
client.defaults.responseType = 'json'
/**
 *
 * @param {AxiosRequestConfig} options
 * @returns
 */
const request = options => {
   const onSuccess = response => {
      // if (response.data && response.data.status === "00") {
      //   localStorage.removeItem("APUSE");
      //   window.location = "/login";
      //   // window.alert("Session Expired");
      // }
      return response.data
   }
   const onError = error => {
      if (error.response) {
         // Request was made but server responded with something other than 2xx
      } else {
         // Something else happened while setting up the request triggered the error
      }
      return Promise.reject(error.response || error.message)
   }
   return client(options)
      .then(onSuccess)
      .catch(onError)
}

let planModuleId = {
   shiftmoduleId: '5e4c01821229852a6b3a66b1',
   timesheetmoduleId: '5e4c01821229852a6b3a66b3',
   invoicemoduleId: '5e4c01821229852a6b3a66b2',
   recruitmentmoduleId: '5e4c01821229852a6b3a66b6',
   compliancemoduleId: '5e4c01821229852a6b3a66b4',
   communicationmoduleId: '5e4c01821229852a6b3a66b5'
}
if (NodeURL.includes('apstaging')) {
   planModuleId = {
      shiftmoduleId: '5e3ae0303cd2295ee3830521',
      invoicemoduleId: '5e3ae0303cd2295ee3830522',
      timesheetmoduleId: '5e3ae0303cd2295ee3830523',
      recruitmentmoduleId: '5e3ae0303cd2295ee3830526',
      compliancemoduleId: '5e3ae0303cd2295ee3830524',
      communicationmoduleId: '5e3ae0303cd2295ee3830525'
   }
}

let tieredPlansIDs

if (NodeURL.includes('getshifts')) {
   tieredPlansIDs = {
      basic: '670cca562d6f876cfec6bb44',
      starter: '670cd4dcf5a9557032dfbe4b',
      pro: '670ce8c713d62573c528bd20',
      premium: '670cec5f282632791d89713d'
   }
} else {
   tieredPlansIDs = {
      basic: '6703b34482d51345f0d45332',
      starter: '6703dda082d51345f0d45abf',
      pro: '66fe3cfc61592f472827868f',
      premium: '66ff8285f37d8301a8479625'
   }
}

export const planID = planModuleId
export const tieredPlansID = tieredPlansIDs

export default request
