import React, { useState } from 'react';
import "../newWebsite/NewCSS/Forgot.scss";
import { useForm } from 'react-hook-form';
import Header from './header';
import { useHistory , Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import request from '../../api/api';

import CryptoJS from "crypto-js";
import { ToastContainer, toast } from 'react-toastify';



// GET Shift New Register 

let forget_password_toastId = "forget_password"

let validTabs = (tabs='')=>['verifyemail' ,'verifypasscode','newpassword'].includes(tabs)
const GetShiftForgotPassword = () => {
   const [viewTab , setViewTab ] = useState("verifyemail");
   const [mail, setMail] = useState("");
   const [maskEmail, setmaskEmail] = useState("");
   const [bytes, setBytes] = useState("");
   const [passcode, setPasscode] = useState("");
   const [agencyId , setAgencyId ] = useState("");

   const handleNextTab = (id)=>{
    if(validTabs(id)){
        setViewTab(id);
    }
   }
   const handleEmail=(maskmail,bytes,agencyId='')=>{
        const [username, domain] = maskmail.split('@');
        const maskedEmail = username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1)+ '@' + domain;
        setmaskEmail(maskedEmail);
        setMail(maskmail);
        setBytes(bytes.toString(CryptoJS.enc.Utf8));
        setAgencyId(agencyId);
   }

   const handlePasscode=(passcode)=>{
    setPasscode(passcode)
   }
  return (
    <div>
        <Header id="hedspace"></Header>
        <div className='forgot-container'>
            <div className='container'>
                <div className='forgot-modal'>
                    <h5>Forgot Password</h5>
                    <VisibleTab Id={viewTab} handleNextTab={handleNextTab} handleEmail={handleEmail} maskEmail={maskEmail} bytes={bytes} handlePasscode={handlePasscode} passcode={passcode} mail={mail} agencyId={agencyId}/>
                </div>
            </div>
        </div>
		<ToastContainer position="top-right" autoClose={2500} containerId={forget_password_toastId} closeOnClick />

    </div>
  )
}
const VisibleTab = ({Id ='',...rest}) =>{
    if(Id === 'verifyemail'){
        return <VerifyEmail {...rest}></VerifyEmail>
    }
    if(Id === 'verifypasscode'){
        return <VerifyPasscode {...rest}></VerifyPasscode>
    }
    if(Id === 'newpassword'){
        return <NewPassword {...rest}></NewPassword>
    }
    return null
}

const schemaEmail = yup.object().shape({
    email: yup.string().email('Please enter a valid email').required('This is required field').matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, 'Invalid email format'),
})
const VerifyEmail = ({ handleNextTab = ()=>{},handleEmail=()=>{}}) => {
    const { register, handleSubmit, formState: { errors}} = useForm({resolver: yupResolver(schemaEmail),});
    let [spinner, setSpinner] = useState(false);
    let [invalidEmail, setInvalidEmail] = useState(false);


    const onSubmit = async (data={}) => {
        setSpinner(true);
        data.template = "agency_forgot_password_reset";
		await request({url: "/agency/forgotpassword",method: "POST",data: data})
			.then((res) => {
                let bytes = CryptoJS.AES.decrypt(
                    res.response.code,
                    "agencyPortalWebSite"
                );
                if(res.status===1){
                    setSpinner(false);
                    let agencyId = res.response.agencyId;
                    handleEmail(data.email,bytes ,agencyId)
                    handleNextTab('verifypasscode')
                }else if(res.status===0){
                    setSpinner(false);
                }
            }).catch(()=>{
                setSpinner(false);
                setInvalidEmail(true);
            });
      }
    
    return (
        <>
            <form className='new-form' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='on'>
                <div className="form-group">
                    <label className="form-label">Email Address</label>
                    <input {...register('email')} type='email' className='form-control' />
                    {errors.email &&<span className='form-error'>{errors.email.message}</span>}
                </div>
                <p className='form-info'>By clicking Send Code. A passcode will be sent to your registered email address</p>
                {invalidEmail && <p className='invalid-code alert alert-danger' role='alert'>Invalid Email Address<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>setInvalidEmail(false)}>&times;</button></p>}

                <button type="submit" className="form-submit">Send Code</button>

                {spinner && <div className="spinner-box"><span className="spinner"></span></div>}
            </form>
            <p className='just-here'>Just Remembered?<Link to="/signin">Signin Here</Link></p>

        </>
    )
}



const schemaPasscode = yup.object().shape({
    passcode: yup.string().required('This is required field').min(6, 'Passcode must have minimum 6 characters'),
})

const VerifyPasscode = ({ handleNextTab = ()=>{},maskEmail,bytes,handlePasscode=()=>{} ,mail,handleEmail=()=>{}}) => {
    const { register, handleSubmit, formState: { errors}} = useForm({resolver: yupResolver(schemaPasscode)});
    let [spinner, setSpinner] = useState(false);
    let [verify, setVerify] = useState(false);
    let [verifyMessage, setVerifyMessage] = useState("");
    
    let[newByte,setNewByte]=useState(bytes);

    const resendCode = () => {
        let mailData={
            email:mail,
            template : "agency_forgot_password_reset"
        }
        setSpinner(true);
        

		request({url: "/agency/forgotpassword",method: "POST",data: mailData})
			.then((res) => {
                let bytesNew = CryptoJS.AES.decrypt(res.response.code,"agencyPortalWebSite");
                if(res.status===1){
                    setSpinner(false);
                    let agencyId = res.response.agencyId;
                    setNewByte(bytesNew.toString(CryptoJS.enc.Utf8));

                    handleEmail(mail,bytesNew,agencyId)
                        toast.success("Passcode resend successfully",{autoClose:true,progress:true ,containerId:forget_password_toastId});
                   
                }else if(res.status===0){
                    setSpinner(false);
                }
            }).catch(()=>{
                setSpinner(false);
            });
      }
    

      const onSubmit = (data) => {
        setSpinner(true)
        if(newByte===data.passcode){
            toast.dismiss()
            setTimeout(()=>{
                setSpinner(false)
                handlePasscode(data.passcode);
                handleNextTab('newpassword');
            },1000)
        }else{
            setTimeout(()=>{
                setSpinner(false)
                setVerify(true);
                setVerifyMessage("Passcode doesn't match")
            },1000)
        }
      }
    
    return (
        <>
            <form className='new-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="form-group">
                    <label className="form-label">Passcode</label>
                    <input {...register('passcode')} type='text' className='form-control' />
                    <button type='button' className='resend' onClick={resendCode}>Resend Code</button>
                    {errors.passcode &&<span className='form-error'>{errors.passcode.message}</span>}
                </div>
                    <p className='form-info'>Enter 6 digit passcode that has been sent to your registered email address <span>{maskEmail}</span></p>
                <button type="submit" className="form-submit">Verify</button>
                {spinner && <div className="spinner-box"><span className="spinner"></span></div>}
                {verify && <p className='invalid-code alert alert-danger' role='alert'>{verifyMessage} <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>setVerify(false)}>&times;</button></p>}

            </form>
            <p className='just-here'>Just Remembered?<Link to="/signin">Signin Here</Link></p>
        </>
    )
}



const schemaPassword = yup.object().shape({
    newPassword: yup.string().required('This is required field').min(6, 'Password must have minimum 6 characters'),
    confirmPassword: yup.string().required('This is required field').oneOf([yup.ref('newPassword'), null], `Passwords didn't match`),
})

const NewPassword = ({ passcode,mail,agencyId }) => {
    const { register, handleSubmit, formState: { errors}} = useForm({resolver: yupResolver(schemaPassword)});
    
    let [spinner, setSpinner] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [verifyPassword, setVerifyPassword] = useState(false);

    const handlePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleConfirmPassword = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };
    const domHistory = useHistory();
    const onSubmit = (data) => {
        let passwordForm = {
			password: data.newPassword,
			confirmpassword: data.confirmPassword,
			reset: passcode,
			email: mail,
		};
		setSpinner(true);

        request({url: `/agency/resetpassword/${agencyId}/${passcode}`,method: "POST",data: passwordForm})
			.then((res) => {
                if (res.status === 1) {
                    setSpinner(false);
                    setVerifyPassword(true)
                    setTimeout(()=>{
                        domHistory.push("/signin")
                        setVerifyPassword(false)
                    },1000)
                }else if (res.status === 0) {
                    setSpinner(false);
				}
            });

    }
    
    return (
        <>
            <form className='new-form' onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label className="form-label">New Password</label>
                    <input {...register('newPassword')} type={showPassword ? 'text' : 'password'} className='form-control' />
                    <button type='button' className='toggle-pass' onClick={handlePassword}>{showPassword ? 'Hide' : 'Show'}</button>
                    {errors.newPassword &&<span className='form-error'>{errors.newPassword.message}</span>}
                </div>
                <div className="form-group">
                    <label className="form-label">Confirm Password</label>
                    <input {...register('confirmPassword')} type={showConfirmPassword ? 'text' : 'password'} className='form-control'/>
                    <button type='button' className='toggle-pass' onClick={handleConfirmPassword}>{showConfirmPassword ? 'Hide' : 'Show'}</button>
                    {errors.confirmPassword &&<span className='form-error'>{errors.confirmPassword.message}</span>}
                </div>
                {verifyPassword && <p className='invalid-code alert alert-success' role='alert'>Password Reset Successfully <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>setVerifyPassword(false)}>&times;</button></p>}

                <button type="submit" className="form-submit">Change Password</button>
                {spinner && <div className="spinner-box"><span className="spinner"></span></div>}

            </form>

        </>
    )
}






export default GetShiftForgotPassword

