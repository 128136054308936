/*eslint no-sequences: 0*/
import React, { Component } from "react";
import { Container, Row, Col, Button, Alert, UncontrolledTooltip } from "reactstrap";
import {
	AvForm,
	AvGroup,
	AvInput,
	AvFeedback
} from "availity-reactstrap-validation";
import { toast, ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import request, { client, NodeURL } from "../../api/api";
import Gettoken from "../../api/gettoken";
import Context from "../../api/context";
import { Link } from "react-router-dom";

// import SocialLogin from "./sociallogin";
import { SubdomainURL } from "../../api/api";
import queryString from "query-string";

class Agencylogin extends Component {
	state = {
		email: "",
		password: "",
		emaillists: [],
		invalidemail: false,
		showPassword: false,
		login_loder: false,
		logo_loder: false,
		error: false
	};
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	componentDidMount() {
		/*  const subdomain = window.location.host.split(".")[1] ? window.location.host.split(".")[0] : false;
		if (subdomain) {
		  request({
			url: "/site/checkdomain",
			method: "POST",
			data: { subdomain }
		  }).then(res => {
			if (res.status === 1) {
			} else if (res.status === 0) {
			  window.location = "/";
			}
		  });
		} else {
		  window.location = "/";
		}*/
		const parsed = queryString.parse(this.props.location.search);
		if (parsed && parsed.au) {
			this.setState({ login_loder: true });
			request({
				url: "/administrators/agency/actasagency",
				method: "POST",
				data: { auth: parsed.au }
			})
				.then(res => {
					if (res.status === 1) {
						localStorage.setItem("APUSA", res.response.auth_token);
						client.defaults.headers.common["Authorization"] =
							res.response.auth_token;
						window.location.reload();
					} else if (res.status === 0) {
						// toast.error(res.response);
						// window.location = "/";
						this.setState({ login_loder: false });
					}
				})
				.catch(err => {
					toast.error(err);
					this.setState({ login_loder: false });
				});
		}

		const subdomain = window.location.host.split(".")[1]
			? window.location.host.split(".")[0]
			: false;
		request({
			url: "/site/agencies",
			method: "POST"
		}).then(res => {
			this.setState({ logo_loder: true });
			const filteredlist =
				res.response.result &&
				res.response.result.filter(
					list => list.domain.toString() === subdomain.toString()
				);
			// console.log("filteredlist",filteredlist)
			this.setState(
				{
					agency_company_logo: filteredlist[0] && filteredlist[0].company_logo
				},
				() => {
					this.setState({ logo_loder: false });
				}
			);
			this.setState({ agencylist: res.response.result });
		});

		request({
			url: "/site/settings",
			method: "POST"
		})
			.then(res => {
				if (res.status === 1) {
					this.setState({ logo_loder: true });

					this.setState(
						{
							sitesettings_logo: res.response.settings.logo || {}
						},
						() => {
							this.setState({ logo_loder: false });
						}
					);
				} else if (res.status === 0) {
					toast.error(res.response);
				}
			})
			.catch(err => {
				toast.error(err);
			});

		if (this.props.location.state === true) {
			return (
				toast.success(
					"Registration Completed Successfully!  Please Check Your Mail"
				),
				this.props.history.push({ state: false })
			);
		}
		// const tokenAgency = localStorage.getItem("APUSA");
		// if (tokenAgency) {
		//   const token = jwt_decode(tokenAgency);
		//   if (token && token.role) {
		//     switch (token.role) {
		//       case "agency":
		//         if (token.subscription === 1) {
		//           setTimeout(() => {
		//             this.props.history.replace("/portal/agency/dashboard");
		//             window.location.reload();
		//           }, 200);
		//         } else if (token.subscription === 0) {
		//           setTimeout(() => {
		//             this.props.history.replace("/portal/agency/subscribe");
		//             window.location.reload();
		//           }, 200);
		//         }
		//         break;
		//       default:
		//     }
		//   }
		// }
		if (this.props.location.state === "notify") {
			toast.info("Logged Out!");
			this.props.history.push({ state: "" });
		}
	}

	agencynodifytoastId = "AgencyLogin";

	agencynodify() {
		if (!toast.isActive(this.agencynodifytoastId)) {
			this.agencynodifytoastId = toast.success(
				"Registration Completed Successfully!  Please Check Your Mail"
			);
		}
	}
	openInNewTab = href => {
		Object.assign(document.createElement("a"), {
			target: "_self",
			href
		}).click();
	};
	OnFormSubmit = e => {
		const loginform = {
			email: this.state.email,
			password: this.state.password
		};
		request({
			url: "/agency",
			method: "POST",
			data: loginform
		})
			.then(res => {
				if (res.status === 1) {
					const domain = res.domain || "";
					// this.openInNewTab(`https://${domain}.${SubdomainURL}/agency/login/?au=${res.response.before_auth_token}`);
					//window.location = `https://${domain}.${SubdomainURL}/agency/login/?au=${res.response.before_auth_token}`;

					window.location = `http://${domain}.${SubdomainURL}/agency/?au=${res.response.before_auth_token}`;
				} else if (res.status === 0) {
					toast.error(res.response);
				}
			})
			.catch(err => {
				toast.error(err);
			});
	};
	deactivatenodifytoastId = "AgencyLogin";

	deactivatenodify() {
		if (!toast.isActive(this.deactivatenodifytoastId)) {
			this.deactivatenodifytoastId = toast.error("Deactivated!");
		}
	}

	passwordnodifytoastId = "AgencyLogin";

	passwordnodify() {
		if (!toast.isActive(this.passwordnodifytoastId)) {
			this.passwordnodifytoastId = toast.info("Password Changed!");
		}
	}

	logoutmsgtoastId = "AgencyLogin";
	logoutmsg = () => {
		if (!toast.isActive(this.logoutmsgtoastId)) {
			this.logoutmsgtoastId = toast.info("Logged Out!");
		}
	};

	onShowPassword = () => {
		var x = document.getElementById("password");
		if (x.type === "password") {
			x.type = "text";
			this.setState({
				showPassword: true
			})
		} else {
			x.type = "password";
			this.setState({
				showPassword: false
			})
		}
	}

	render() {
		// const { login_loder, logo_loder } = this.state || {};
		const subdomain = window.location.host.split(".")[1]
			? window.location.host.split(".")[0]
			: false;
		const logourl = this.state.agency_company_logo
			? this.state.agency_company_logo
			: this.state.sitesettings_logo;
		return (
			<>
				{/*<ToastContainer position="top-right" autoClose={2500} closeOnClick/> */}
				{/* {login_loder ? (
          <div className="loader-page">
            {" "}
            <i className="fa fa-spinner fa-spin fa-5x" />{" "}
          </div>
        ) : null}
        {subdomain === "www" ? <HomeHeader /> : null}
        {subdomain === "www" ? <HomeHeaderMobile /> : null}
      
        {logo_loder && (
          <div className="home-loader">
            <i className="fa fa-spinner fa-spin fa-5x" />{" "}
          </div>
        )} */}


				<ToastContainer position="top-right" autoClose={2500} closeOnClick />
				<section className=" section login-spaces ">
					<Container>
						<div className="row form">
							<div className="agency-boxes login-cont">
								<div className="box-width">
									<div className="employee-forms">
										<div className="new-agency-body">
											<div className="new-agency-login">
												<Link to="/">
													{" "}
													<img src={NodeURL + "/" + logourl} alt="logo" />  {" "}
												</Link>
											</div>
											<h4>Login</h4>
											<AvForm onValidSubmit={this.OnFormSubmit}>

												<AvGroup>
													<AvInput
														name="email"
														label="Email"
														className="user-agency"
														placeholder="Enter email or username"
														onChange={this.onChange}
														value={this.state.email}
														required
													/>
													<AvFeedback>This is required!</AvFeedback>
												</AvGroup>
												<AvGroup className="input-group  mb-0">
													<AvInput
														type="password"
														name="password"
														className="pass-agency"
														placeholder="Enter password"
														onChange={this.onChange}
														value={this.state.password}
														required
													/>
													<i id="view-password" className={`fa ${this.state.showPassword ? "fa-eye-slash" : "fa-eye"} view-password`} aria-hidden="true" onClick={this.onShowPassword}></i>
													<AvFeedback>This is required!</AvFeedback>
												</AvGroup>
												<UncontrolledTooltip
													placement="top"
													target="view-password"
												>
													{this.state.showPassword ? "hide" : "view"}
												</UncontrolledTooltip>
												<Row>
													{this.state.error ? (
														<Alert color="danger">
															Password Incorrect.Enter Correct Password!
														</Alert>
													) : null}
													<Col xs="12" className="text-right">
														<Button color="link" className="forget-ranges">
															<Link
																className="for-pass"
																to="/agency/forgotpassword"
															>
																Forgot password?
															</Link>
														</Button>
													</Col>

													<Col xs="12">
														<div className="mid-btns">
															<Button type={"submit"} className="login-btns">
																Login
															</Button>
														</div>
													</Col>
												</Row>
												<Row>
													<div className="emp-login">
														{subdomain === "www" ? null : (
															<p className="reg-again text-center">
																<span className="page-login"> Employee Login</span>
																<Link to="/login">Click Here</Link>
															</p>
														)}
													</div>
												</Row>

											</AvForm>
										</div>

									</div>
								</div>
							</div>
						</div>

						<Row className="justify-content-center">
							{/* <div className="left-login-agency">
								<div className="banner-login">
									<img
										src="../img/login-pages/ag-login.png"
										alt="agency-section"
									/>
								</div>
							</div> */}


						</Row>
					</Container>
				</section>
				{/* </section> */}
				{/* <!--Form--> */}
			</>
		);
	}
}

export default props => (
	<Gettoken>
		<Context.Consumer>
			{theme => <Agencylogin {...props} theme={theme} />}
		</Context.Consumer>
	</Gettoken>
);
