import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import Header from "./header";
import Footer from "./NewFooter";

export default class CookiesPolicy extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	menufun = () => {
		document.getElementById("varmenu").classList.add("shower-menus");
		document.getElementById("shbody").classList.add("shower-body");
	};
	hidingmenu = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	};
	clomenus = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	};
	render() {
		return (
			<>
				{/* <section className="home-header front-header">
					<div className="container">
						<div className="col-md-12">
							<div className="header-sections">
								<Link to="/">
									{" "}
									<img className="ags-logo"
										src="../../../img/front/agency-logo.png"
										alt="logo"
									/>{" "}
								</Link>
								<ul className="inner-menu">
									<li>
										{" "}
										<Link to="/agency-register"> Sign Up </Link>{" "}
									</li>
									<li>
										{" "}
										<Link to="/agency-login"> Sign In </Link>{" "}
									</li>
									<li className="men-clicks">
										<span id="clickmenu" onClick={this.menufun}>
											{" "}
											<img
												src="../../../img/home/white-menu.svg"
												alt="logo"
											/>{" "}
										</span>{" "}
									</li>
								</ul>
								<div
									className="fixed-body act-bdy"
									id="shbody"
									onClick={this.hidingmenu}
								></div>

								<div className="showing-menus" id="varmenu">
									<div className="menu-lists">
										<div className="close-menu">
											<img
												id="closeviews"
												onClick={this.clomenus}
												className="menu-aft"
												src="../../../img/front/menu-close.svg"
												alt="images"
											/>
										</div>
										<ul>
											<li>
												{" "}
												<Link to="/">
													{" "}
													Home
													<img
														className="menu-bef"
														src="../../../img/front/menu-home.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-home-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/features">
													{" "}
													Features
													<img
														className="menu-bef"
														src="../../../img/front/menu-settings.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-settings-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/pricing">
													{" "}
													Pricing
													<img
														className="menu-bef"
														src="../../../img/front/menu-price.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-price-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/resources">
													{" "}
													Resources
													<img
														className="menu-bef"
														src="../../../img/front/menu-resource.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-resource-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/contact">
													{" "}
													Contact
													<img
														className="menu-bef"
														src="../../../img/front/menu-contact.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-contact-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				<Header></Header>
				<section className="policy-section">
					<div className="container">
						<div className="vals-policy">
							<Col md="12">
								<h4> Cookies Policy </h4>
							</Col>
							<Col md="7">
								<h6> About us </h6>
								<p>This Policy applies to your use of the website Get Shifts The website is owned by NXT Digital Ltd, trading as Get Shifts, and is registered in England and Wales under company registration number 08835058. Our registered office address is Unit 5, Christie Fields Office Park, Christie Way, Manchester M21 7QY</p>
								<p>Where we manage personal data, we identify as a Data Controller and recognise and act on our obligations under data protection law applicable in the UK. For details of how we process personal data you should refer to our Privacy Policy on our website. We can be contacted by email to: <a href="mailto:support@getshifts.co.uk">support@getshifts.co.uk </a> The person responsible for data protection is Macs Mahlangu.</p>


							</Col>

							<Col md="5">
								<img src="../../../img/front/cookie-policy.svg" alt="img" />
							</Col>

							<Col md="12">
								<h6>What are cookies?</h6>
								<p> Cookies are small files saved to the user’s computer hard drive that track, save and store information about the user’s interactions and usage of the website. This allows the website, through its server, to provide the user with a tailored experience when navigating the website. Session cookies may be used to validate a user’s access to different parts of the website.</p>

								<h6>Pixel tags</h6>
								<p>We also use pixel tags (also known as web beacons) which are small strings of html or JavaScript code that provide a method for delivering a graphic image on a web page. Pixel tags may be used to obtain information about the computer or device being used to view a particular web page such as the IP address of the computer or device to which the pixel tag is sent, the time it was sent, the computer or device’s operating system and browser type, and similar information.</p>

								<h6>Push notifications</h6>
								<p> We may send push notifications or alerts to you including to any mobile device we have registered as an active account. You can deactivate these messages at any time by changing the notification settings on your device. </p>

								<h6>Disabling cookies</h6>
								<p> If you are uncomfortable with the use of cookies, you can disable cookies on your device by changing the settings in the preferences or options menu in your browser. You can set your browser to reject or block cookies or to tell you when a website tries to put a cookie on your device. You can also delete any cookies that are already stored on your device. However, please be aware that if you do delete and block all cookies from our website, parts of the site may not fully function.</p>

								<h6>How we use cookies</h6>
								<p> The website uses cookies to help us identify and track visitors, their usage of the website, and their website access preferences. Cookies are used to identify and distinguish you from other users on the website.</p>
								<p>Where applicable this website uses a cookie control system allowing the user on their first visit to the website to allow or disallow the use of cookies on their computer or device. This complies with UK and EU legislation which requires that explicit consent is given by users before reading files are left, or applied, on a user’s computer or device.</p>
								<h6>Consent</h6>
								<p>If you accept the use of Cookies on this website, you consent to the processing of data about you by us and any third parties as identified above in accordance with this policy and our Privacy Policy. You have the right to withdraw your consent at any time by sending an email to <a href="mailto:privacy@getshifts.co.uk"> privacy@getshifts.co.uk </a></p>
								<h6> Types of cookies </h6>
								<p><b>Strictly necessary cookies:</b>These help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.</p>
								<p><b>Analytical / performance cookies:</b> These help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.</p>
								<p><b>Functionality cookies:</b> These are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.</p>
								<p><b>Targeting cookies</b> This information is used to make our site and the advertising displayed more relevant to your interests. We may also share this information with third parties for this purpose. Cookies can be placed for a user’s session and will disappear as soon as the browser software is closed or the computer is turned off, or cookies may be persistent and will remain on the device.</p>

								<h6>Cookies we use</h6>
								<div className="cookies-table-section">
									<div className="scrl-funs">
										<table className="table">
											<thead>
												<tr>
													<th> Cookie </th>
													<th> Provider </th>
													<th> Type </th>
													<th> use </th>
													<th className="rent-periods"> Retention Period</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="cookies-renters" > NECESSARY </td>
												</tr>
												<tr>
													<td> connect.sid </td>
													<td> getshifts.co.uk	 </td>
													<td> HTTP </td>
													<td> The cookie is necessary for secure log-in and the detection of any spam or abuse of the website.	</td>
													<td> Session </td>
												</tr>
												<tr>
													<td className="cookies-renters" >MARKETING</td>
												</tr>
												<tr>
													<td> rc::c	 </td>
													<td> google.com	</td>
													<td> HTML </td>
													<td> This cookie is used to distinguish between humans and bots.	</td>
													<td> Session </td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>

								<h6>Analytics</h6>
								<p> We use tracking software provided by Google Analytics to monitor visitors, and to better understand how they use our site. This software uses cookies to track visitors’ usage. The software will save a cookie to the user’s hard drive in order to track and monitor engagement and usage of the website. The cookie will not store, save or collect personal information.</p>
								<p>Google’s Privacy Policy is available at <a href="http://www.google.co.uk/privacy.html" target="__blank"> http://www.google.co.uk/privacy.html.</a> Users may opt-out from having their data collected by disabling the tracking.</p>

								<h6>Changes to this policy</h6>
								<p>We reserve the right to change this Cookies Policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. You will be deemed to have accepted any changes to the terms of this Cookies Policy on your next visit of the website following the amendment.</p>

								<h6>Complaints</h6>
								<p>If you have any concerns about how we handle your data, you can contact the person responsible for data protection by email to <a href="mailto:privacy@getshifts.co.uk" > privacy@getshifts.co.uk </a></p>

								<p>If you wish to raise formally raise a concern or complaint to the Information Commissioner’s Office (ICO) – the UK Regulator on data protection matters, you can call the help desk on 0303 123 1113, or see the options for reporting issues on <a href="https://ico.org.uk/concerns/" target = "__blank"> https://ico.org.uk/concerns/ </a></p>

								<h6>Further Information</h6>
								<p> Guidelines for the processing and handling of data is available from the Information Commissioner’s Office, the UK supervisory authority on data protection, see <a href="https://ico.org.uk" target="__blank"> www.ico.org.uk </a> Information is also available at <a href="https://ec.europa.eu/ipg/basics/legal/cookies/index_en.html" target="__blank"> www.ec.europa.eu/ipg/basics/legal/cookies/index_en.html </a></p>
							</Col>
						</div>
					</div>
				</section>
				<Footer></Footer>
			</>
		);
	}
}
