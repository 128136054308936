import CryptoJS from 'crypto-js';
import { SubdomainURL } from "../../api/api";

const Remember = ({ email, password, remember, status, domain, response }) => {
    if (status === 1) {

      if (remember === true && email && email !== "" && password && password !== "") {
        localStorage.setItem(
          "agency_r_detail",
          CryptoJS.AES.encrypt(JSON.stringify({ email, password }), "agencyPortalWebSite")
        );
        localStorage.setItem("rememberMe", true);
      }
  
     
      const redirectUrl = `http://${domain}.${SubdomainURL}/agency/?au=${response.before_auth_token}`;
      window.location = redirectUrl;
    } else if (status === 0) {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("agency_r_detail");
    }
  };

export default Remember


