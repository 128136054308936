import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, Col, Container, InputGroupAddon, InputGroupText, Row, Alert } from "reactstrap";
import { AvFeedback, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { toast, ToastContainer } from "react-toastify";
import request from "../../api/api";

class agencyforgotpassword extends Component {
	state = {
		email: "",
		mailalert: false,
		mailerror: false,
		nomail: false
	};
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	OnFormSubmit = e => {
		request({
			url: "/agency/forgotpassword",
			method: "POST",
			data: { email: this.state.email }
		})
			.then(res => {
				if (res.status === "1") {
					this.setState({
						mailalert: true
					});
				} else if (res.status === "0") {
					this.setState({
						mailerror: true
					});
				}
				setTimeout(() => {
					this.setState({
						mailalert: false
					});
				}, 3500);
				setTimeout(() => {
					this.setState({
						mailerror: false
					});
				}, 3500);
			})
			.catch(err => {
				this.setState({
					nomail: true
				});
				setTimeout(() => {
					this.setState({
						nomail: false
					});
				}, 3500);
			});
	};

	componentDidMount() {
		if (localStorage.getItem("notify")) {
			this.logoutmsg();
			localStorage.clear();
		}
	}
	logerrorstoastId = "Forgotpasswords";
	logerror = () => {
		if (!toast.isActive(this.logerrorstoastId)) {
			this.logerrorstoastId = toast.error("No User Found!");
		}
	};

	render() {
		return (
			<section className="section login-spaces ">
				<ToastContainer position="top-right" autoClose={2500} closeOnClick />
				<Container>
					<div className="row form">
						<div className="agency-boxes login-cont">
							<div className="box-width">
								<div className="employee-forms">
									<div className="new-agency-body">
										<h4>Forgot Password</h4>
										<AvForm onValidSubmit={this.OnFormSubmit}>

											<AvGroup className="input-group">
												{/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon> */}
												<AvInput type="text" name="email" placeholder="Enter email" onChange={this.onChange} value={this.state.email} required />

												<AvFeedback>This is invalid!</AvFeedback>
											</AvGroup>
											<div className="mid-btns">
												<Button className="login-btns">
													Send
												</Button>
												<button
													type="button"
													className="back-login"
													active
													onClick={() => {
														this.props.history.push("/agency-login");
													}}
												>
													Back to Login
												</button>
											</div>

											<Row>
												{this.state.mailalert ? <Alert color="info">Check your email , Follow the instructions and Reset Password!</Alert> : null}
												{this.state.mailerror ? <Alert color="danger">Please Enter Valid Email Address!</Alert> : null}
												{this.state.nomail ? <Alert color="danger">Please Enter Valid Email Address!</Alert> : null}
											</Row>

										</AvForm>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <Row className="justify-content-center">
            <Col md="8">
              <CardGroup className="ag-lo">
                <Card className="p-4">
                  
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + "%" }}>
                  <CardBody className="text-center">
                    <div className="for-text">
                     
                     
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row> */}
				</Container>
			</section>
		);
	}
}

export default agencyforgotpassword;
