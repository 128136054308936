import React from 'react'
import { Link } from 'react-router-dom'
import { MetaTags } from 'react-meta-tags'
import Header from '../header'
import Footer from '../NewFooter'
import './features.scss'
// Images
import featuremincard1 from '../../../images/feature-new/feature-mini-card1.png'
// import timesheets from "../../../images/feature-new/timesheets.png"
import timesheets from '../../../images/feature-new/time_sheet/timesheet_banner.png'

import featuresTimesheet from '../../../images/feature-new/features_timesheet.png'

import enhanced_time from '../../../images/feature-new/time_sheet/enhanced_time.svg'
import interactive_timesheet from '../../../images/feature-new/time_sheet/interactive_timesheet.svg'
import digital_signature from '../../../images/feature-new/time_sheet/digital_signature.svg'

import timesheet_download from '../../../images/feature-new/time_sheet/timesheet_download.svg'
import timesheet_share from '../../../images/feature-new/time_sheet/timesheet_share.svg'
import timesheet_export from '../../../images/feature-new/time_sheet/timesheet_export.svg'

import accessibility from '../../../images/feature-new/time_sheet/accessibility.svg'
import precision from '../../../images/feature-new/time_sheet/precision.svg'
import seamless from '../../../images/feature-new/time_sheet/seamless.svg'

import robust from '../../../images/feature-new/time_sheet/robust.svg'
import discover from '../../../images/feature-new/time_sheet/discover.svg'
import { GetStartButton, GetStartCTA, GetStartCTATrail } from '.'

const Timesheets = () => {
   return (
      <>
         <MetaTags>
            <title>Smart Timesheet Tracking Software in UK - Getshift</title>
            <meta name="keyword" content="time tracking software, employee timesheet software, best Time Tracking software" />
            <meta
               name="description"
               content="Track working hours easy with our Timesheet Software. Streamline employee time management, monitor project progress, and generate accurate reports for enhanced productivity and payroll management"
            />
         </MetaTags>
         <Header />
         <section className="cover-banners">
            <section className="home-banner">
               <div className="container">
                  <div className="banner-parts feature_parts">
                     <div className="left-banner">
                        <h4 className="sht-list1 new_sht-list1">Timesheets</h4>
                        <h6>Overview of Automated Online Timesheets with Advanced Features.</h6>
                        <p>
                           Get Shifts introduces an innovative solution to streamline time tracking and approval processes: Automated Online Timesheets with
                           Geofencing. This advanced system not only simplifies traditional timesheet management but also includes sophisticated features such
                           as geofencing, incident reporting, digital signatures, and comprehensive data handling capabilities.
                        </p>
                        <GetStartButton />
                     </div>

                     <div className="right_banners">
                        <img src={timesheets} alt="timesheets" />
                     </div>
                  </div>
               </div>
            </section>
         </section>
         <section className="feature_content">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={enhanced_time} alt="enhanced_time" loading="lazy" width="40px" height="40px" />
                        </span>
                        <h6>Enhanced Time Tracking with Geofencing</h6>
                        <p>
                           Automated Online Timesheets incorporate geofencing technology, allowing employees to clock in or out only within a predefined
                           geographical area. This feature ensures precise time records and helps prevent time theft.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={interactive_timesheet} alt="interactive_timesheet" loading="lazy" width="40px" height="40px" />
                        </span>
                        <h6>Interactive Timesheet Features</h6>
                        <p>
                           Employees can add notes and report shift incidents directly within the app, providing context and important details for each
                           timesheet entry. This functionality enhances communication and records essential information relating to the workday.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={digital_signature} alt="digital_signature" loading="lazy" width="40px" height="40px" />
                        </span>
                        <h6>Digital Signatures and File Uploads</h6>
                        <p>
                           The system allows timesheets to be digitally signed by designated personnel at the work location, ensuring accountability and formal
                           approval of time records. Employees can also upload files, such as physical timesheets or other relevant documents, directly into the
                           system for a comprehensive record-keeping.
                        </p>
                     </div>
                  </div>
               </div>
               <GetStartCTA />
               <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-4">
                     <div className="three__feature__img">
                        <img src={timesheet_download} alt="timesheet_download" loading="lazy" />
                        <img src={timesheet_share} alt="timesheet_share" loading="lazy" />
                        <img src={timesheet_export} alt="timesheet_export" loading="lazy" />
                     </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 mb-4">
                     <div className="card card__feature three__feature">
                        <h6>
                           Timesheet Download, Sharing, and
                           <br />
                           Export Options
                        </h6>
                        <p>
                           The system allows timesheets to be digitally signed by designated personnel at the work location, ensuring accountability and formal
                           approval of time records. Employees can also upload files, such as physical timesheets or other relevant documents, directly into the
                           system for a comprehensive record-keeping.
                        </p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature card__feature--new">
                        <span className="feature__img">
                           <img src={accessibility} alt="accessibility" loading="lazy" />
                        </span>
                        <h6>Accessibility and Flexibility</h6>
                        <p>
                           Online access enables employees and managers to handle timesheets from any location with internet connectivity, promoting a flexible
                           and paperless work environment that aligns with modern eco-friendly business practices.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature card__feature--new">
                        <span className="feature__img">
                           <img src={precision} alt="precision" loading="lazy" />
                        </span>
                        <h6>Precision and Real-Time Access</h6>
                        <p>
                           Automated calculations combined with geofencing technology ensure accuracy and consistency in timesheet management. Managers enjoy
                           real-time data access, allowing for prompt timesheet approval and a comprehensive overview of workforce activities.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature card__feature--new">
                        <span className="feature__img">
                           <img src={seamless} alt="seamless" loading="lazy" />
                        </span>
                        <h6>Seamless System Integration</h6>
                        <p>
                           The timesheet system integrates seamlessly with other functionalities of the Get Shifts platform, including shift scheduling and
                           payroll processing. This harmonization streamlines workflows, reduces administrative burdens, and enhances operational efficiency.
                        </p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={robust} alt="robust" loading="lazy" />
                        </span>
                        <h6>Robust Reporting and Compliance</h6>
                        <p>
                           Robust reporting capabilities allow for in-depth analysis of employee hours, overtime, and attendance patterns. Compliance with
                           labour regulations is supported by accurate record-keeping and overtime tracking, helping businesses adhere to legal standards and
                           avoid potential liabilities.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={discover} alt="discover" loading="lazy" />
                        </span>
                        <h6>Discover Enhanced Operational Efficiency</h6>
                        <p>
                           Explore how the Automated Online Timesheets from Get Shifts, with its advanced features like geofencing, incident reporting, digital
                           signatures, and comprehensive data handling, can revolutionize your time tracking processes. This tool is not just a technological
                           advancement; it's a strategic asset designed to improve decision-making, reduce overhead costs, and ensure compliance with labour
                           regulations, ultimately enhancing overall business performance.
                        </p>
                     </div>
                  </div>
               </div>
               <GetStartCTATrail />
            </div>
         </section>
         {/* <section>
                <div className="container">
                <div className="features_cta">
                    
                        <div className="right-features_cta">
                            <h5>"Effortlessly Manage Your Time: Introducing Automated Online Timesheets"</h5>
                            <p>Let Get Shifts handle the staffing details, so you can concentrate on the heart of your business</p>
                            <div className="features_cta_btn">
                            <Link to="/signup">Get Started - <span style={{ fontSize: "12px" }}>It's free</span></Link>
                            </div>
                        </div>

                </div>
                </div>
            </section> */}
         <Footer />
      </>
   )
}

export default Timesheets
