import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import Header from '../header'
import Footer from '../NewFooter'
import './features.scss'
import classNames from 'classnames'

// Images
// import invoice from "../../../images/feature-new/invoice.png"
import featuremincard1 from '../../../images/feature-new/feature-mini-card1.png'

import featureInvoice from '../../../images/feature-new/feature_invoice.png'

import invoice from '../../../images/feature-new/invoice/invoice_banner.png'

import billing from '../../../images/feature-new/invoice/billing.svg'
import transparency from '../../../images/feature-new/invoice/transparency.svg'
import timely from '../../../images/feature-new/invoice/timely.svg'
import customize from '../../../images/feature-new/invoice/customize.svg'
import financial from '../../../images/feature-new/invoice/financial.svg'
import sharing from '../../../images/feature-new/invoice/sharing.svg'

import insights from '../../../images/feature-new/invoice/insights.svg'
import transform from '../../../images/feature-new/invoice/transform.svg'
import accuracy from '../../../images/feature-new/invoice/accuracy.svg'
import improving from '../../../images/feature-new/invoice/improving.svg'
import Slider from 'react-slick'

import { TabContent } from 'reactstrap'
import { GetStartButton, GetStartCTA } from '.'

const Invoices = () => {
   const [activeTab, setActiveTab] = useState('1')
   const [sliderIndex, setSliderIndex] = useState(0)
   const sliderRef = useRef(null)

   const handleButtonClick = (tabIndex, index) => {
      setActiveTab(tabIndex)
      setSliderIndex(index)
   }

   var settings = {
      dots: true,
      autoplay: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               infinite: true,
               dots: true
            }
         }
      ]
   }

   useEffect(() => {
      if (sliderRef.current) {
         sliderRef.current.slickGoTo(sliderIndex)
      }
   }, [sliderIndex])

   return (
      <>
         <MetaTags>
            <title> Best Invoicing And Billing Software in UK - Getshift</title>
            <meta name="keyword" content="invoicing software for small business, free invoicing software, invoice software" />
            <meta
               name="description"
               content="Discover the best invoicing software in the UK with Get shifts. Simple to create, send and manage invoices with our Leading invoice software for UK businesses."
            />
         </MetaTags>
         <Header />
         <section className="cover-banners">
            <section className="home-banner">
               <div className="container">
                  <div className="banner-parts feature_parts">
                     <div className="left-banner">
                        <h4 className="sht-list1 new_sht-list1">Invoices</h4>
                        <h6>
                           Overview of Invoicing by
                           <br />
                           Get Shifts
                        </h6>
                        <p>
                           Get Shifts introduces Automatic Invoicing, a revolutionary feature designed to streamline the invoicing process, ensure timely
                           payments, and maintain healthy cash flow—crucial for building positive relationships with vendors and contractors. This automated
                           solution generates invoices directly from approved timesheets, integrating seamlessly with the timesheet approval workflow to
                           eliminate manual efforts and ensure prompt payment within a unified platform. Additionally, it allows for manual invoice creation,
                           catering to unique or exceptional billing needs.
                        </p>
                        <GetStartButton />
                     </div>

                     <div className="right_banners">
                        <img src={invoice} alt="invoice" />
                     </div>
                  </div>
               </div>
            </section>
         </section>
         <section className="feature_content">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={billing} alt="billing" loading="lazy" />
                        </span>
                        <h6>Simplifying the Billing Process</h6>
                        <p>
                           Automatic Invoicing enhances billing accuracy by automatically generating invoices based on approved timesheet data, minimising
                           errors and administrative tasks. This allows teams to focus more on strategic activities rather than tedious invoicing processes,
                           boosting operational efficiency. For specific cases, users can also create invoices manually, offering flexibility when needed.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={transparency} alt="transparency" loading="lazy" />
                        </span>
                        <h6>Transparency and Accountability</h6>
                        <p>
                           The system instils transparency by reflecting detailed timesheet data in the invoices, providing clients and contractors clear
                           visibility into billed hours and associated costs. This transparency fosters trust and accountability in financial dealings.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={timely} alt="timely" loading="lazy" />
                        </span>
                        <h6>Enhancing Cash Flow with Timely Payments</h6>
                        <p>
                           By accelerating the billing cycle, Automatic Invoicing reduces delays in payment processing, enhancing cash flow, which is crucial
                           for the smooth operation and financial health of businesses. The system also enables automated payment reminders, ensuring timely
                           collection without the need for manual follow-ups.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={customize} alt="customize" loading="lazy" />
                        </span>
                        <h6>Customisation and Personalisation</h6>
                        <p>
                           Businesses can customise invoice templates, incorporate branding elements, and specify billing details, aligning the invoicing
                           process with organisational branding and providing a personalised touch to the billing process.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={financial} alt="financial" loading="lazy" />
                        </span>
                        <h6>Efficient Integration with Financial Systems</h6>
                        <p>
                           Automatic Invoicing integrates smoothly with accounting software and payment gateways such as QuickBooks or PayPal, facilitating
                           efficient invoice processing and timely receipt of payments, thus streamlining financial management and reducing potential errors.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                     <div className="card card__feature">
                        <span className="feature__img">
                           <img src={sharing} alt="sharing" loading="lazy" />
                        </span>
                        <h6>Easy Sending and Sharing</h6>
                        <p>
                           Invoices can be easily sent and shared directly with customers from the system, enhancing communication and ensuring that billing
                           information reaches clients promptly and securely.
                        </p>
                     </div>
                  </div>

                  {/* <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                            <div className="card card__feature">
                                <span className="feature__img">
                                    <img src={transform} alt="transform" loading="lazy" />
                                </span>
                                <h6>Transform Your Financial Operations</h6>
                                <p>Automatic Invoicing by Get Shifts is more than just an invoicing tool; it's a comprehensive solution that improves cash flow management, reduces administrative burdens, offers valuable financial insights, ensures accuracy in financial reporting, and elevates the client experience. By adopting Automatic Invoicing, businesses can streamline their financial operations, foster stronger relationships with stakeholders, and secure a competitive edge for sustained growth and stability.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                            <div className="card card__feature">
                                <span className="feature__img">
                                    <img src={accuracy} alt="accuracy" loading="lazy" />
                                </span>
                                <h6>Accuracy in Financial Reporting</h6>
                                <p>Automated invoice generation, combined with the capability for manual entry and integration with accounting systems, ensures that financial records are consistently accurate and up-to-date, fortifying the integrity of financial reporting and reducing the risk of discrepancies.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
                            <div className="card card__feature">
                                <span className="feature__img">
                                    <img src={improving} alt="improving" loading="lazy" />
                                </span>
                                <h6>Improving Client Experience</h6>
                                <p>Clients benefit from receiving timely, accurate invoices that simplify their payment processes and enhance their overall satisfaction and loyalty. This positive client experience is pivotal for building long-term business relationships and establishing a reputation for reliability and professionalism.</p>
                            </div>
                        </div> */}
               </div>
            </div>
         </section>
         <GetStartCTA />
         <section className="invoice__carousel">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                     <div className="invoice__carousel_btns">
                        <button className={classNames('carousel_btn carousel_btn_1', { active: activeTab === '1' })} onClick={() => handleButtonClick('1', 0)}>
                           <img src={insights} alt="insights" loading="lazy" />
                        </button>
                        <button className={classNames('carousel_btn carousel_btn_2', { active: activeTab === '2' })} onClick={() => handleButtonClick('2', 1)}>
                           <img src={transform} alt="transform" loading="lazy" />
                        </button>
                        <button className={classNames('carousel_btn carousel_btn_3', { active: activeTab === '3' })} onClick={() => handleButtonClick('3', 2)}>
                           <img src={accuracy} alt="accuracy" loading="lazy" />
                        </button>
                        <button className={classNames('carousel_btn carousel_btn_4', { active: activeTab === '4' })} onClick={() => handleButtonClick('4', 3)}>
                           <img src={improving} alt="improving" loading="lazy" />
                        </button>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                     <div className="invoice__carousel_slides">
                        <Slider {...settings} ref={sliderRef} beforeChange={(current, next) => setSliderIndex(next)} activeTab={activeTab}>
                           <div className="invoice__card">
                              <h6>Insights into Financial Patterns</h6>
                              <p>
                                 The feature provides insights into billing trends and financial patterns, helping businesses refine pricing strategies and
                                 identify cost-saving opportunities. These insights assist in understanding revenue streams and making informed decisions to
                                 enhance profitability.
                              </p>
                           </div>
                           <div className="invoice__card">
                              <h6>Transform Your Financial Operations</h6>
                              <p>
                                 Automatic Invoicing by Get Shifts is more than just an invoicing tool; it's a comprehensive solution that improves cash flow
                                 management, reduces administrative burdens, offers valuable financial insights, ensures accuracy in financial reporting, and
                                 elevates the client experience. By adopting Automatic Invoicing, businesses can streamline their financial operations, foster
                                 stronger relationships with stakeholders, and secure a competitive edge for sustained growth and stability.
                              </p>
                           </div>
                           <div className="invoice__card">
                              <h6>Accuracy in Financial Reporting</h6>
                              <p>
                                 Automated invoice generation, combined with the capability for manual entry and integration with accounting systems, ensures
                                 that financial records are consistently accurate and up-to-date, fortifying the integrity of financial reporting and reducing
                                 the risk of discrepancies.
                              </p>
                           </div>
                           <div className="invoice__card">
                              <h6>Improving Client Experience</h6>
                              <p>
                                 Clients benefit from receiving timely, accurate invoices that simplify their payment processes and enhance their overall
                                 satisfaction and loyalty. This positive client experience is pivotal for building long-term business relationships and
                                 establishing a reputation for reliability and professionalism.
                              </p>
                           </div>
                        </Slider>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         {/* <section>
                <div className="container">
                  <div className="features_cta">

                        <div className="right-features_cta">
                            <h5>"Effortless Invoicing: Automate Your Billing Process Today!"</h5>
                            <p>Let Get Shifts handle the staffing details, so you can concentrate on the heart of your business</p>
                            <div className="features_cta_btn">
                              <Link to="/signup">Get Started - <span style={{ fontSize: "12px" }}>It's free</span></Link>
                            </div>
                        </div>
                      
                  </div>
                </div>
            </section> */}
         <Footer />
      </>
   )
}

export default Invoices
