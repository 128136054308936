import React, { Component } from "react";
import { Link } from "react-router-dom";
import savenodify from "../agency/agencylogin";
import { toast, ToastContainer } from "react-toastify";
import request, { client } from "../../api/api";
const regex = /^[A-Za-z0-9 ]+$/

export default class NewSignUp extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        this.state = {
            name:
                this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state
                    ? this.props.history.location.state.name
                    : "",
            email:
                this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state
                    ? this.props.history.location.state.email
                    : "",
            password: "",
            confirm_password: "",
            number: "",
            username: "",
            company_name: "",
            subdomain: "",
            code: "",
            address: "",
            line1: "",
            line2: "",
            city: "",
            state: "",
            dailcountry: "gb",
            country: "",
            zipcode: "",
            formatted_address: "",
            phoneerror: false,
            status: 1,
            isverified: 1,
            avatarfile: "",
            recapcha: "",
            setloader: false,
            activeTab: '1'
        };
    }

    flash1 = new savenodify();
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (String(e.target.name) === String('email')) {
            this.setState({ username: e.target.value });
        }
    };
    menufun = () => {
        document.getElementById("varmenu").classList.add("shower-menus");
        document.getElementById("shbody").classList.add("shower-body");
    };
    hidingmenu = () => {
        document.getElementById("varmenu").classList.remove("shower-menus");
        document.getElementById("shbody").classList.remove("shower-body");
    };
    clomenus = () => {
        document.getElementById("varmenu").classList.remove("shower-menus");
        document.getElementById("shbody").classList.remove("shower-body");
    };

    ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    }

    isValid(str) {
        return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
    }

    myTrim(x) {
        return x.replace(/^\s+|\s+$/gm, "");
    }

    OnFormSubmit = (e, values) => {
        client.defaults.responseType = "json";
        if (this.state.name === "") {
            this.submittogle_change('1');
            toast.error("Please enter Name");
        } else if (this.state.surname === "") {
            this.submittogle_change('1');
            toast.error("Please enter Surname");
        } else if (this.state.email === "") {
            this.submittogle_change('1');
            toast.error("Please enter Email");
        } else if (this.ValidateEmail(this.state.email) === false) {
            this.submittogle_change('1');
            toast.error("You have entered an invalid email address!");
        } else if (this.state.company_name === "") {
            this.submittogle_change('2');
            toast.error("Please enter Company Name");
        } else if (this.state.number === "") {
            this.submittogle_change('2');
            toast.error("Please Enter Contact Phone Number");
        } else if (this.state.subdomain === "") {
            this.submittogle_change('2');
            toast.error("Please enter Domain");
        } else if (this.state.subdomain.length < 6) {
            this.submittogle_change('2');
            toast.error("Your Domain Name must be more than 6 characters");
        } else if (this.state.subdomain.length > 16) {
            this.submittogle_change('2');
            toast.error("Your Domain Name must be less than 16 characters");
        } else if (/[^a-zA-Z0-9\-\/]/.test(this.state.subdomain)) {
            this.submittogle_change('2');
            toast.error("No special characters or symbols allowed");
        } else if (this.state.username === "") {
            this.submittogle_change('3');
            toast.error("Please enter Username");
        } else if (this.state.password === "") {
            this.submittogle_change('3');
            toast.error("Please enter Password");
        } else if (this.state.confirm_password === "") {
            this.submittogle_change('3');
            toast.error("Please enter Password");
        } else if (this.state.confirm_password != this.state.password) {
            this.submittogle_change('3');
            toast.error("Password did not match");
        } else {
            this.setState({ setloader: true });
            const data = new FormData();
            data.append("username", this.state.username);
            data.append("subdomain", this.myTrim(this.state.subdomain));
            data.append("password", this.state.password);
            data.append("confirm_password", this.state.confirm_password);
            data.append("name", this.state.name);
            data.append("surname", this.state.surname);
            data.append("email", this.state.email);
            data.append("phone[code]", "44");
            data.append("phone[number]", this.state.number);
            data.append("phone[dailcountry]", this.state.dailcountry);
            data.append("address[line1]", this.state.line1 || "");
            data.append("address[line2]", this.state.line2 || "");
            data.append("address[city]", this.state.city || "");
            data.append("address[state]", this.state.state || "");
            data.append("address[country]", this.state.country || "");
            data.append("address[zipcode]", this.state.zipcode || "");
            data.append(
                "address[formatted_address]",
                this.state.formatted_address || ""
            );
            data.append("address[lat]", "38.685516");
            data.append("address[lng]", "-101.073324");
            data.append("company_name", this.state.company_name || "");
            data.append("company_phone[code]", "44");
            data.append("company_phone[number]", "07400123456");
            data.append("company_phone[dailcountry]", "gb");
            data.append("company_email", this.state.company_email || "");
            data.append("company_logo", this.state.company_logo || "");
            data.append("status", this.state.status);
            data.append("isverified", this.state.isverified);
            request({
                url: "/register/agency",
                method: "POST",
                data: data
            }).then(res => {
                this.setState({ setloader: false });
                if (res.status === 1) {
                    // this.props.history.push("/");
                    toast.success("Registration Completed Successfully! Please Check Mail");
                    this.setState({ successmodal: true });
                    setTimeout(() => {
                        this.props.history.push("/agency-login");
                    }, 1000);
                } else if (res.status === 0) {
                    toast.error(res.response);
                }
            }).catch(err => {
                this.setState({ setloader: false });
                toast.error(err);
            });
        }
    };

    RedirectHome = () => {
        this.props.history.push("/");
        this.setState({ successmodal: false });
    };

    toggle = (e, tab = '1') => {
        window.scrollTo(0, 0);
        e.preventDefault();
        this.setState({ activeTab: tab });
    }

    submittogle_change = (tab = '1') => this.setState({ activeTab: tab });

    render() {
        let { activeTab } = this.state;
        return (
            <>
            <ToastContainer position="top-right" autoClose={2500} closeOnClick />
            {/* <section className="home-header front-header">
                    <div className="container">
                        <div className="col-md-12">
                            <div className="header-sections">
                                <Link to="/">
                                    {" "}
                                    <img className="ags-logo"
                                        src="../../../img/front/agency-logo.png"
                                        alt="logo"
                                    />{" "}
                                </Link>
                                <ul className="inner-menu menu-gains">
                                    <li>
                                        {" "}
                                        <Link to="/agency-login"> Sign In </Link>{" "}
                                    </li>
                                    <li className="men-clicks">
                                        <span id="clickmenu" onClick={this.menufun}>
                                            {" "}
                                            <img
                                                src="../../../img/home/white-menu.svg"
                                                alt="logo"
                                            />{" "}
                                        </span>{" "}
                                    </li>
                                </ul>
                                <div
                                    className="fixed-body act-bdy"
                                    id="shbody"
                                    onClick={this.hidingmenu}
                                ></div>
                                <div className="showing-menus" id="varmenu">
                                    <div className="menu-lists">
                                        <div className="close-menu">
                                            <img
                                                id="closeviews"
                                                onClick={this.clomenus}
                                                className="menu-aft"
                                                src="../../../img/front/menu-close.svg"
                                                alt="images"
                                            />
                                        </div>
                                        <ul>
                                            <li className="sign-desk">
                                                {" "}
                                                <Link to="/agency-register">
                                                    {" "}
                                                    Sign Up
                                                    <img
                                                        className="menu-bef"
                                                        src="../../../img/front/menu-user.svg"
                                                        alt="images"
                                                    />
                                                    <img
                                                        className="menu-aft"
                                                        src="../../../img/front/menu-user-clr.svg"
                                                        alt="images"
                                                    />
                                                </Link>{" "}
                                            </li>
                                            <li className="sign-desk">
                                                {" "}
                                                <Link to="/agency-login">
                                                    {" "}
                                                    Sign In
                                                    <img
                                                        className="menu-bef"
                                                        src="../../../img/front/menu-user.svg"
                                                        alt="images"
                                                    />
                                                    <img
                                                        className="menu-aft"
                                                        src="../../../img/front/menu-user-clr.svg"
                                                        alt="images"
                                                    />
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                {" "}
                                                <Link to="/">
                                                    {" "}
                                                    Home
                                                    <img
                                                        className="menu-bef"
                                                        src="../../../img/front/menu-home.svg"
                                                        alt="images"
                                                    />
                                                    <img
                                                        className="menu-aft"
                                                        src="../../../img/front/menu-home-clr.svg"
                                                        alt="images"
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                {" "}
                                                <Link to="/features">
                                                    {" "}
                                                    Features
                                                    <img
                                                        className="menu-bef"
                                                        src="../../../img/front/menu-settings.svg"
                                                        alt="images"
                                                    />
                                                    <img
                                                        className="menu-aft"
                                                        src="../../../img/front/menu-settings-clr.svg"
                                                        alt="images"
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                {" "}
                                                <Link to="/pricing">
                                                    {" "}
                                                    Pricing
                                                    <img
                                                        className="menu-bef"
                                                        src="../../../img/front/menu-price.svg"
                                                        alt="images"
                                                    />
                                                    <img
                                                        className="menu-aft"
                                                        src="../../../img/front/menu-price-clr.svg"
                                                        alt="images"
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                {" "}
                                                <Link to="/resources">
                                                    {" "}
                                                    Resources
                                                    <img
                                                        className="menu-bef"
                                                        src="../../../img/front/menu-resource.svg"
                                                        alt="images"
                                                    />
                                                    <img
                                                        className="menu-aft"
                                                        src="../../../img/front/menu-resource-clr.svg"
                                                        alt="images"
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                {" "}
                                                <Link to="/contact">
                                                    {" "}
                                                    Contact
                                                    <img
                                                        className="menu-bef"
                                                        src="../../../img/front/menu-contact.svg"
                                                        alt="images"
                                                    />
                                                    <img
                                                        className="menu-aft"
                                                        src="../../../img/front/menu-contact-clr.svg"
                                                        alt="images"
                                                    />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            {/* <section className="breadcum-headers">
                    <div className="container">
                        <Col md="12">
                            <div className="breadcum-sec">
                                <ul>
                                    <li>
                                        <Link to="/"> Home </Link>
                                    </li>
                                    <li className="active">
                                        <Link to="/agency-register"> Signup </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </div>
                </section> */}
            <section className=" section login-spaces regi-spaces">
                <div className="container">
                    <div className="row form">
                        <div className="agency-boxes login-cont">
                            <div className="heading-boxes">
                                <div className="new-agency-login">
                                    <Link to="/">
                                        {" "}
                                        <img className="ags-logo"
                                            src="../../../img/front/agency-logo.png"
                                            alt="logo"
                                        />{" "}
                                    </Link>
                                </div>
                                <h4> Try Get Shifts for 30 days Free </h4>
                            </div>
                            <div className="box-width">
                                <div className="employee-forms adjus-boxes">
                                    <div className="new-agency-body">
                                        {/* <div className="login-steps">
                                                <ul>
                                                    <li className={String(activeTab) === '1' || String(activeTab) === '2' || String(activeTab) === '3' ? 'active' : ''} >
                                                        <span className="login-seccount"> 1 </span>
                                                        <span className="loginsec-parts"> Your Details </span>
                                                    </li>
                                                    <li className={String(activeTab) === '2' || String(activeTab) === '3' ? 'active' : ''}>
                                                        <span className="login-seccount"> 2 </span>
                                                        <span className="loginsec-parts"> Your Business Details </span>
                                                    </li>
                                                    <li className={String(activeTab) === '3' ? 'active' : ''} >
                                                        <span className="login-seccount"> 3 </span>
                                                        <span className="loginsec-parts"> Create Your Login Details </span>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        {String(activeTab) === '1' ?
                                            <div className="forms-hiding">
                                                {/* <h6> Your Details </h6> */}
                                                <div className="login-steps">
                                                    <ul>
                                                        <li className={String(activeTab) === '1' || String(activeTab) === '2' || String(activeTab) === '3' ? 'active' : ''} >
                                                            <span className="login-seccount"> 1 </span>
                                                            <span className="loginsec-parts"> Your Details </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        autoComplete="off"
                                                        placeholder="First Name"
                                                        onChange={this.onChange}
                                                        value={this.state.name}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input
                                                        type="text"
                                                        name="surname"
                                                        autoComplete="off"
                                                        placeholder="Last Name"
                                                        onChange={this.onChange}
                                                        value={this.state.surname}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label> Email Address </label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Company Email"
                                                        autoComplete="off"
                                                        onChange={this.onChange}
                                                        value={this.state.email}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="loginprev">
                                                    <button type="button" className="btn login-nexts" onClick={e => this.toggle(e, '2')} >
                                                        Next
                                                        </button>
                                                </div>
                                            </div>
                                            : String(activeTab) === '2' ?
                                                <div className="forms-hiding">
                                                    {/* <h6> Your Business Details </h6> */}
                                                    <div className="login-steps">
                                                        <ul>
                                                            <li className={String(activeTab) === '2' || String(activeTab) === '3' ? 'active' : ''}>
                                                                <span className="login-seccount"> 2 </span>
                                                                <span className="loginsec-parts"> Your Business Details </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="form-group">
                                                        <label> Company / Organisation Name </label>
                                                        <input
                                                            type="text"
                                                            name="company_name"
                                                            placeholder="Your Company Name"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            value={this.state.company_name}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label> Phone Number </label>
                                                        <input
                                                            type="number"
                                                            name="number"
                                                            autoComplete="off"
                                                            placeholder="Your Contact Number"
                                                            onChange={this.onChange}
                                                            value={this.state.number}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            {" "}
                                                            Choose a Domain{" "}
                                                            <span className="tooltip-stus">
                                                                {" "}
                                                                <img
                                                                    src="../../../img/front/choose-domain.svg"
                                                                    alt="img"
                                                                />
                                                                <span class="tooltiptext-stus">
                                                                    {" "}
                                                                    Eg Company name (lower case no spaces, shorten where possible){" "}
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className="domain-inputs">
                                                            <input
                                                                type="text"
                                                                name="subdomain"
                                                                autoComplete="off"
                                                                //placeholder="Choose a Domain"
                                                                placeholder="companyname"
                                                                // className="form-control"
                                                                className= {this.state.subdomain && String(this.state.subdomain).length > 0 && String(this.state.subdomain) !== String(undefined) && String(this.state.subdomaind) !== String(null) ? "form-control value_added" : "form-control"}
                                                                onChange={this.onChange}
                                                            //value={this.state.subdomain}
                                                            />
                                                            <span className={this.state.subdomain && String(this.state.subdomain).length > 0 && String(this.state.subdomain) !== String(undefined) && String(this.state.subdomaind) !== String(null) ? "dmn-sign abs-moved-value_added" : "dmn-sign abs-moved"} >.getshifts.co.uk</span>
                                                            <div className={this.state.subdomain && String(this.state.subdomain).length > 0 && String(this.state.subdomain) !== String(undefined) && String(this.state.subdomaind) !== String(null) ? "domain-uppends valueadded" : "domain-uppends"}>
                                                                <span className="dmn-sign"> 
                                                                    <span>{this.state.subdomain} .</span>
                                                                    <span className="domain-names">.getshifts.co.uk </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="loginprev">
                                                        <button type="button" className="btn login-prevs" onClick={e => this.toggle(e, '1')} >
                                                            Previous
                                                            </button>
                                                        <button type="button" className="btn login-nexts" onClick={e => this.toggle(e, '3')}>
                                                            Next
                                                            </button>
                                                    </div>
                                                </div>
                                                : String(activeTab) === '3' ?
                                                    <div className="forms-hiding">
                                                        {/* <h6> Create Your Login Details </h6> */}
                                                        <div className="login-steps">
                                                            <ul>
                                                                <li className={String(activeTab) === '3' ? 'active' : ''} >
                                                                    <span className="login-seccount"> 3 </span>
                                                                    <span className="loginsec-parts"> Create Your Login Details </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>  {" "} Username   {" "}
                                                                <span className="tooltip-stus spce-tls">
                                                                    {" "}
                                                                    <img
                                                                        src="../../../img/front/choose-domain.svg"
                                                                        alt="img"
                                                                    />
                                                                    <span class="tooltiptext-stus" style={{ textTransform: 'capitalize' }} >
                                                                        {" "}
                                                                        create username or use email{" "}
                                                                    </span>
                                                                </span>

                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="username"
                                                                autoComplete="off"
                                                                // placeholder="Choose a Username"
                                                                placeholder="Memorable Username"
                                                                onChange={this.onChange}
                                                                value={this.state.username}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Password </label>
                                                            <input
                                                                type="password"
                                                                name="password"
                                                                // placeholder="Choose a Password"
                                                                placeholder="Choose Password"
                                                                onChange={this.onChange}
                                                                value={this.state.password}
                                                                autoComplete="off"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Confirm Password </label>
                                                            <input
                                                                type="password"
                                                                name="confirm_password"
                                                                placeholder="Confirm Password"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                onChange={this.onChange}
                                                                value={this.state.confirm_password}
                                                            />
                                                        </div>
                                                        <div className="loginprev">
                                                            {this.state.setloader ?
                                                                <button type="button" className="btn login-prevs" style={{ cursor: 'progress' }} >
                                                                    Previous
                                                                    </button> :
                                                                <button type="button" className="btn login-prevs" onClick={e => this.toggle(e, '2')} >
                                                                    Previous
                                                                    </button>
                                                            }
                                                            {this.state.setloader ?
                                                                <button type="button" className="btn login-nexts" style={{ cursor: 'progress' }} >
                                                                    Sign Up
                                                                    </button> :
                                                                <button type="button" className="btn login-nexts" onClick={this.OnFormSubmit}>
                                                                    Sign Up
                                                                    </button>
                                                            }
                                                        </div>
                                                        {/*                                                             
                                                            {
                                                                this.state.setloader ?
                                                                    <div className="mid-btns">
                                                                        <button
                                                                            type="button"
                                                                            className="login-btns"
                                                                            style={{ cursor: 'progress' }}
                                                                        >
                                                                            {" "}
                                                                            Sign Up{" "}
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    <div className="mid-btns">
                                                                        <button
                                                                            type="button"
                                                                            className="login-btns"
                                                                            onClick={this.OnFormSubmit}
                                                                        >
                                                                            {" "}
                                                                            Sign Up{" "}
                                                                        </button>
                                                                    </div>
                                                            } */}
                                                    </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* 
                <section className="contact-sections">
                    <div className="container">
                        <div className="common-flex">
                            <Col md="6">
                                <div className="contact-var">
                                   
                                    <div className="contact-lists">
                                        

                                        <div className="form-group">
                                            <div className="left-forms">
                                                <img src="../../../img/front/company.svg" alt="img" />
                                            </div>
                                            <div className="right-forms">
                                                <div className="cms-contact">
                                                    <label> Company / Organistation name </label>
                                                    <input
                                                        type="text"
                                                        name="company_name"
                                                        placeholder="Enter Company Name"
                                                        autoComplete="off"
                                                        onChange={this.onChange}
                                                        value={this.state.company_name}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="left-forms">
                                                <img src="../../../img/front/envelope.svg" alt="img" />
                                            </div>
                                            <div className="right-forms">
                                                <div className="cms-contact">
                                                    <label> Email Address </label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Enter Email ID"
                                                        autoComplete="off"
                                                        onChange={this.onChange}
                                                        value={this.state.email}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="left-forms">
                                                <img
                                                    src="../../../img/front/smartphone.svg"
                                                    alt="img"
                                                />
                                            </div>
                                            <div className="right-forms">
                                                <div className="cms-contact">
                                                    <label> Phone Number </label>
                                                    <input
                                                        type="number"
                                                        name="number"
                                                        autoComplete="off"
                                                        placeholder="Enter phone Number"
                                                        onChange={this.onChange}
                                                        value={this.state.number}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h6> Domain Details </h6>

                                    <div className="contact-lists">
                                        <div className="form-group">
                                            <div className="left-forms">
                                                <img src="../../../img/front/domain.svg" alt="img" />
                                            </div>
                                            <div className="right-forms">
                                                <div className="cms-contact">
                                                    <label>
                                                        {" "}
                                                        Choose a Domain{" "}
                                                        <span className="tooltip-stus">
                                                            {" "}
                                                            <img
                                                                src="../../../img/front/choose-domain.svg"
                                                                alt="img"
                                                            />
                                                            <span class="tooltiptext-stus">
                                                                {" "}
                                                                Eg. Company name (lowercase, no spaces){" "}
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <div className="domain-inputs">
                                                        <input
                                                            type="text"
                                                            name="subdomain"
                                                            autoComplete="off"
                                                            placeholder="Choose a Domain"
                                                            className="form-control"
                                                            onChange={this.onChange}
                                                            value={this.state.subdomain}
                                                        />
                                                        <span>.getshifts.co.uk</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h6> Login Details </h6>
                                    <div className="contact-lists">
                                        <div className="form-group">
                                            <div className="left-forms">
                                                <img src="../../../img/front/user.svg" alt="img" />
                                            </div>
                                            <div className="right-forms">
                                                <div className="cms-contact">
                                                    <label> Username </label>
                                                    <div className="sts-two as-ls">
                                                        <input
                                                            type="text"
                                                            name="username"
                                                            autoComplete="off"
                                                            placeholder="Choose a username"
                                                            onChange={this.onChange}
                                                            value={this.state.username}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="form-group">
                                            <div className="left-forms">
                                                <img src="../../../img/front/password.svg" alt="img" />
                                            </div>
                                            <div className="right-forms">
                                                <div className="cms-contact">
                                                    <div className="sts-two as-ls">
                                                        <label>Password</label>
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            placeholder="Enter password"
                                                            onChange={this.onChange}
                                                            value={this.state.password}
                                                            autoComplete="off"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    <div className="sts-two as-rs">
                                                        <label>Confirm password</label>
                                                        <input
                                                            type="password"
                                                            name="confirm_password"
                                                            placeholder="Confirm password"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            onChange={this.onChange}
                                                            value={this.state.confirm_password}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <button
                                        type="button"
                                        className="btn contact-subs"
                                        onClick={this.OnFormSubmit}
                                    >
                                        {" "}
                                        Sign Up{" "}
                                    </button>
                                </div>
                            </Col>

                            <Col md="6">
                                <div className="signup-covers">
                                    <img src="../../../img/front/signup.svg" alt="img" />
                                </div>
                            </Col>
                        </div>
                    </div>
                    <Modal isOpen={this.state.successmodal} className="modal-md">
                        <ModalHeader>Information</ModalHeader>
                        <ModalBody>
                            Registration Completed Successfully. Please Check Your Mail For
                            Details.
            </ModalBody>
                        <ModalFooter className="d-flex justify-content-center">
                            <Button className="info-sub" onClick={this.RedirectHome}>
                                OK
              </Button>
                        </ModalFooter>
                    </Modal>
                </section>
                <Footer></Footer> */}
            </>
        );
    }
}
