import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { MetaTags } from 'react-meta-tags'
import Header from '../header'
import Footer from '../NewFooter'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import Slider from 'react-slick'
// Images
import './features.scss'
import recruitment from '../../../images/feature-new/recruitment/recruitment_banner.png'
import recruitment_card from '../../../images/feature-new/recruitment/recurit_card_img.png'

import automation from '../../../images/feature-new/recruitment/automation.svg'
import centralised from '../../../images/feature-new/recruitment/centralised.svg'
import enhanced from '../../../images/feature-new/recruitment/enhanced.svg'

import risk_management from '../../../images/feature-new/recruitment/risk_management.png'
import analytics_insights from '../../../images/feature-new/recruitment/analytics_insights.png'
import integration from '../../../images/feature-new/recruitment/integration.png'
import { GetStartButton, GetStartCTATrail } from '.'

const Recuritments = () => {
   const [activeTab, setActiveTab] = useState('1')

   const toggle = tab => {
      if (activeTab !== tab) setActiveTab(tab)
   }
   var settings = {
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               infinite: true,
               dots: true
            }
         }
      ]
   }
   return (
      <div>
         <MetaTags>
            <title>Recruitment Software for Shift and staff Management solution - Getshifts</title>
            <meta name="keyword" content="recruitment management software, hr recruitment software, recruitment software uk" />
            <meta
               name="description"
               content="Getshifts is the  best Shift and Staff Management Software for your organisation. Compare top Recruitment Software tools with customer review and pricing."
            />
         </MetaTags>
         <Header />

         <section className="cover-banners">
            <section className="home-banner">
               <div className="container">
                  <div className="banner-parts feature_parts">
                     <div className="left-banner">
                        <h4 className="sht-list1 new_sht-list1">Recruitment</h4>
                        <h6>
                           Overview of Integrated Recruitment <br />
                           Tracking
                        </h6>
                        <p>
                           The Integrated Recruitment Tracking System from Get Shifts is a revolutionary solution designed to optimise the recruitment process,
                           allowing businesses to attract and retain top talent efficiently. This comprehensive system enhances the entire recruitment
                           journey—from initial application screening to final staff onboarding—providing a streamlined experience for both candidates and
                           hiring teams.
                            <GetStartButton/>
                        </p>
                     </div>

                     <div className="right_banners">
                        <img src={recruitment} alt="recruitment" />
                     </div>
                  </div>
               </div>
            </section>
         </section>

         <section className="recuritCar_section">
            <div className="container">
               <Slider {...settings}>
                  <div className="recuritCar_slide">
                     <img src={automation} alt="Automation and Efficiency" loading="lazy" />
                     <h6>Automation and Efficiency</h6>
                     <p>
                        At the heart of the system's benefits is its automation capability, which minimises manual effort by automating repetitive tasks such as
                        application screening, compliance checks, and reference verification. This not only saves time but also improves the accuracy and speed
                        of recruitment processes.
                     </p>
                  </div>
                  <div className="recuritCar_slide">
                     <img src={centralised} alt="Centralised Recruitment Platform" loading="lazy" />
                     <h6>Centralised Recruitment Platform</h6>
                     <p>
                        The system offers a centralised platform that integrates every stage of the recruitment cycle, ensuring end-to-end visibility and
                        control. This unified approach makes all candidate information and recruitment activities easily accessible, enhancing coordination and
                        decision-making among hiring teams.
                     </p>
                  </div>
                  <div className="recuritCar_slide">
                     <img src={enhanced} alt="Enhanced Candidate Interaction" loading="lazy" />
                     <h6>Enhanced Candidate Interaction</h6>
                     <p>
                        Robust communication and scheduling features within the system facilitate seamless interactions with candidates. Hiring teams can
                        efficiently schedule interviews and maintain engagement with applicants throughout the recruitment process, enhancing the candidate
                        experience and boosting the organisation's reputation as an employer of choice.
                     </p>
                  </div>
               </Slider>
            </div>
         </section>

         <section className="recuritTab_section">
            <div className="container">
               <Nav justified pills>
                  <NavItem>
                     <NavLink
                        className={activeTab === '1' ? 'active' : ''}
                        onClick={() => {
                           toggle('1')
                        }}
                     >
                        Compliance and Risk Management
                     </NavLink>
                  </NavItem>
                  <NavItem>
                     <NavLink
                        className={activeTab === '2' ? 'active' : ''}
                        onClick={() => {
                           toggle('2')
                        }}
                     >
                        Advanced Analytics and Insights
                     </NavLink>
                  </NavItem>
                  <NavItem>
                     <NavLink
                        className={activeTab === '3' ? 'active' : ''}
                        onClick={() => {
                           toggle('3')
                        }}
                     >
                        Integration with HR Systems
                     </NavLink>
                  </NavItem>
               </Nav>
               <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                     <Row>
                        <Col sm="12">
                           <p>
                              A key feature of the system is compliance management, which ensures that recruitment practices adhere to relevant regulations and
                              internal policies. The system's audit trails and built-in compliance checks help maintain regulatory standards, reducing the risks
                              associated with non-compliance.
                           </p>
                        </Col>
                        <Col sm="12">
                           <div className="tab-content-img">
                              <img src={risk_management} loading="lazy" />
                           </div>
                        </Col>
                     </Row>
                  </TabPane>
                  <TabPane tabId="2">
                     <Row>
                        <Col sm="12">
                           <p>
                              The system's reporting and analytics tools provide valuable insights into key recruitment metrics like time-to-hire and
                              cost-per-hire. Access to real-time data enables hiring managers to assess the effectiveness of recruitment efforts and identify
                              areas for improvement.
                           </p>
                        </Col>
                        <Col sm="12">
                           <div className="tab-content-img">
                              <img src={analytics_insights} loading="lazy" />
                           </div>
                        </Col>
                     </Row>
                  </TabPane>
                  <TabPane tabId="3">
                     <Row>
                        <Col sm="12">
                           <p>
                              Integration with other HR and talent management systems is a significant advantage, ensuring seamless data sharing across various
                              organisational tools. This connectivity enhances data accuracy and eliminates information silos.
                           </p>
                        </Col>
                        <Col sm="12">
                           <div className="tab-content-img">
                              <img src={integration} loading="lazy" />
                           </div>
                        </Col>
                     </Row>
                  </TabPane>
               </TabContent>
            </div>
         </section>
         <section className="recurit_section">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-4">
                     <div>
                        <img src={recruitment_card} />
                     </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 mb-4">
                     <div className="recurit_cards">
                        <div className="recurit_card recurit_card1">
                           <h6>Collaborative Recruitment Approach</h6>
                           <p>
                              The centralised platform supports a cohesive approach to recruitment, improving collaboration among hiring teams. This
                              environment, coupled with comprehensive data and tools, leads to more effective decision-making and faster role fulfilment.
                           </p>
                        </div>
                        <div className="recurit_card recurit_card2">
                           <h6>Strategic Recruitment Insights</h6>
                           <p>
                              Beyond standard recruitment metrics, the system offers insights into broader trends and patterns that can inform strategic
                              planning. Businesses can analyse data on candidate sourcing, interview outcomes, and hiring success to refine their recruitment
                              strategies and better allocate resources.
                           </p>
                        </div>
                        <div className="recurit_card recurit_card3">
                           <h6>Optimising Candidate Experience</h6>
                           <p>
                              Enhancing applicant experiences is crucial. Timely communication and feedback help maintain candidate engagement and satisfaction,
                              benefiting the organisation's employer brand and appeal to potential talent.
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <GetStartCTATrail />
         <section className="new_cta_section">
            <div className="container">
               <h6>Discover How Our Recruitment System Can Transform Your Hiring Process</h6>
               <p>
                  Learn more about how the Integrated Recruitment Tracking System from Get Shifts can streamline your recruitment processes and secure top
                  talent, solidifying your competitive edge and driving success.
               </p>
            </div>
         </section>

         <Footer />
      </div>
   )
}

export default Recuritments
