import React, { Component } from "react";

export default class AgencyLoader extends Component {
  componentDidMount() {
    window.location.reload();
  }
  render() {
    return <div></div>;
  }
}
