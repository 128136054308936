import React from 'react'
import { Link } from 'react-router-dom'

import featuremincard1 from '../../../images/feature-new/feature-mini-card1.png'
import featuremincard2 from '../../../images/feature-new/feature-mini-card2.png'

/**
 * GetStartButton Component
 *
 * This component renders a "Get started for free" button that is styled based on the provided styles.
 * The button is wrapped inside a paragraph tag, with customizable alignment and button styles.
 *
 * @component
 * @example
 * // Example usage:
 * <GetStartButton
 *    wraperStyle={{ marginTop: '20px' }}
 *    buttonStyle={{ backgroundColor: 'blue', color: 'white' }}
 * />
 *
 * @param {Object} props - The properties object.
 * @param {React.CSSProperties} props.wraperStyle - Custom styles for the wrapper paragraph element. Defaults to an empty object.
 * @param {React.CSSProperties} props.buttonStyle - Custom styles for the button element. Defaults to an empty object.
 * @param {boolean} props.isNobookDemo - Custom styles for the button element. Defaults to an empty object.
 *
 * @returns {JSX.Element} A button wrapped in a paragraph with the applied styles.
 */
export const GetStartButton = ({ wraperStyle = {}, buttonStyle = {} , isNobookDemo=false }) => {
   return (
      <p style={{ gap:"1rem", ...wraperStyle }} className='row'>
         <button type="button" className="btn tryforfree get_started_free_btn" style={{ width: '30%', ...buttonStyle }}>
            <Link to="/signup" className="try-free-view">
               Get started for free
            </Link>
         </button>
         {
            !isNobookDemo && 
            <button type="button" className="btn tryforfree get_started_free_btn">
            <Link to="#" onClick={()=>window.onBookDemo()} className="try-free-view book_demo_cta">
               Book a Demo
            </Link>
         </button>
         }

      </p>
   )
}
/**
 * GetStartCTA Component
 *
 * This component displays a call-to-action section encouraging users to discover the benefits of the "Get Shifts" platform.
 * It includes an image, a description, and a "Get started for free" button that links to the signup page.
 *
 * @component
 * @example
 * // Example usage:
 * <GetStartCTA />
 *
 * @returns {JSX.Element} The call-to-action section with a promotional message and a signup link.
 */
export const GetStartCTA = () => {
   return (
      <div className="col-md-12">
         <div className="free-offers new-get get_started-call-to-action">
            <div className="sigfr-width">
               <div className="left-subs">
                  <img src={featuremincard1} alt="featuremincard1" />
               </div>
               <div className="right-subs">
                  <p>
                     Discover how Get Shifts can revolutionise your workforce management. Schedule a demo with our team to see how our platform can streamline
                     your scheduling and improve efficiency.
                  </p>

                  <div className="trails-sec ">
                     <Link to="/#" className='book_demo_cta' onClick={()=>window.onBookDemo()}>Book a Demo</Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

/**
 * GetStartCTATrail Component
 *
 * This component displays an alternative call-to-action section similar to GetStartCTA,
 * but with a different layout and promotional message. It encourages users to sign up for a free trial.
 *
 * @component
 * @example
 * // Example usage:
 * <GetStartCTATrail />
 *
 * @returns {JSX.Element} The call-to-action section with a promotional message and a signup link for a free trial.
 */
export const GetStartCTATrail = () => {
   return (
      <div className="col-md-12">
         <div className="free-offers new-get new-get2 get_started-call-to-action">
            <div className="sigfr-width sigfr-width2">
               <div className="right-subs">
                  <div className="trails-sec">
                     <p>
                        Experience the benefits of Get Shifts with no commitment. Sign up for a free trial and see how our platform can help optimise your
                        scheduling and workforce management.
                     </p>
                     <Link to="/signup">Get started for free</Link>
                  </div>
               </div>
               <div className="left-subs">
                  <img src={featuremincard2} alt="featuremincard2" />
               </div>
            </div>
         </div>
      </div>
   )
}

export const BookDemoButton = () => {
   return (
      <button type="button" className="orange_outline">
         <Link to="#" onClick={() => window.onBookDemo()}>
            Book a Demo
         </Link>
      </button>
   )
}
