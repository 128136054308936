import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import request, { NodeURL } from '../../../api/api';

// import { getcategoryvideolist } from '../../actions/KnowledgebaseAction'
import { useHistory, useLocation } from 'react-router-dom';
// import { show_mod_pop_btm } from '../../actions/documentAction';
import Header from '../header';
import Footer from '../NewFooter';
import moment from 'moment';
const scrollTo_top = () => window.scrollTo({ behavior: "smooth", top: 0 })

function VideoPage() {
    const history = useHistory();
    const domLocation = useLocation();
  

    const [activeTab, setActiveTab] = useState(0);
    const [categoryvideodata, setcategoryvideodata] = useState({});
    const [videoDetails, setVideoDetails] = useState({});

    const handleTabClick = (tab, item) => {
        setActiveTab(tab);
        setVideoDetails(item)
    };
    const openPdfmodel = (data) => {
        if (data && data.video_support_pdf && data.video_support_pdf !=null && data.video_support_pdf !=undefined) {
            let url = data.video_support_pdf;
            const newTab = window.open(`${NodeURL}/${url}`, '_blank');
            newTab.focus();
        } else {
            // dispatch(show_mod_pop_btm('Invalid PDF File.'));
        }

    }

    const getCategoryVideo = async (id) =>{
        let get_data = {};
        try {
        let respData = await request({url:'/company/knowledge/getcategory',method:'POST',data:{Id:id,action_for:'video'}});
        // let respData = await request({ url: `/company/knowledge/getcategoryvideolist?id=${id}`, method: 'GET' });
        if(respData.status === 1){
            get_data = respData.response ? respData.response[0] :{};
            get_data.updatedAtFormat = moment(categoryvideodata.updatedAt).format('MMMM DD, YYYY')
        }
        setcategoryvideodata(get_data)
            return get_data
        } catch (error) {
            return get_data
        }
    }

    useEffect(() => {
        getCategoryVideo(domLocation.state)
        scrollTo_top()
    }, [domLocation.state])

    useEffect(() => {
        if (categoryvideodata && categoryvideodata.videos && categoryvideodata.videos.length > 0) {
            setVideoDetails(categoryvideodata.videos[0]);
        }
    }, [categoryvideodata]);

    return (
        <>
        <Header></Header>
        <div>
            <div className="container">
                <div className="inner-tab">
                    <div className="inner-head">
                        <button onClick={() => history.push('/helpcentre')}><img src='/img/back.svg' alt="Active" /> Back</button>
                        <h6>{categoryvideodata  && categoryvideodata.category ?categoryvideodata.category:'' }</h6>
                        <p>{categoryvideodata && categoryvideodata.content ? categoryvideodata.content : '' }</p>
                    </div>
                    <div className="inner-btns">
                        {categoryvideodata && categoryvideodata.videos && categoryvideodata.videos.length > 0
                            ? categoryvideodata.videos.map((item, i) => (
                                <button key={i} onClick={() => handleTabClick(i, item)} className={activeTab === i ? 'active' : ''}>
                                    <img src='/img/play-active.svg' alt="Active" />
                                    <span>{i + 1}. {item.video_name}</span>
                                    <p><span>Updated on {categoryvideodata.updatedAtFormat}</span></p>
                                </button>
                            )) : null}
                    </div>
                    <div className="inner-content">
                        <div className='inner-contents'>
                            <div className='inner-cont'>
                                <div className='view_pdf_updateon'>
                                    <div>
                                        <h6>{videoDetails && videoDetails.video_name ? videoDetails.video_name : ''}</h6>
                                        <p><span>Updated on {categoryvideodata.updatedAtFormat}</span></p>
                                    </div>
                                    {videoDetails.video_support_pdf ?<button onClick={() => openPdfmodel(videoDetails)}>PDF</button>:null}
                                </div>
                                <div className='inner-video'>
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={videoDetails && videoDetails.video_link ? videoDetails.video_link : ''}
                                        frameBorder="0"
                                        allowFullScreen
                                        title='Video details'
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        </>

    )
}

export default VideoPage

