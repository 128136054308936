import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import Header from "./header";
import Footer from "./NewFooter";

export default class NewPrivacyPolicy extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	menufun = () => {
		document.getElementById("varmenu").classList.add("shower-menus");
		document.getElementById("shbody").classList.add("shower-body");
	};
	hidingmenu = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	};
	clomenus = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	};
	render() {
		return (
			<>
				{/* <section className="home-header front-header">
					<div className="container">
						<div className="col-md-12">
							<div className="header-sections">
								<Link to="/">
									{" "}
									<img className="ags-logo"
										src="../../../img/front/agency-logo.png"
										alt="logo"
									/>{" "}
								</Link>
								<ul className="inner-menu menu-gains">
									<li>
										{" "}
										<Link to="/agency-register"> Sign Up </Link>{" "}
									</li>
									<li>
										{" "}
										<Link to="/agency-login"> Sign In </Link>{" "}
									</li>
									<li className="men-clicks">
										<span id="clickmenu" onClick={this.menufun}>
											{" "}
											<img
												src="../../../img/home/white-menu.svg"
												alt="logo"
											/>{" "}
										</span>{" "}
									</li>
								</ul>
								<div
									className="fixed-body act-bdy"
									id="shbody"
									onClick={this.hidingmenu}
								></div>

								<div className="showing-menus" id="varmenu">
									<div className="menu-lists">
										<div className="close-menu">
											<img
												id="closeviews"
												onClick={this.clomenus}
												className="menu-aft"
												src="../../../img/front/menu-close.svg"
												alt="images"
											/>
										</div>
										<ul>
											<li className="sign-desk">
												{" "}
												<Link to="/agency-register">
													{" "}
													Sign Up
													<img
														className="menu-bef"
														src="../../../img/front/menu-user.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-user-clr.svg"
														alt="images"
													/>
												</Link>{" "}
											</li>
											<li className="sign-desk">
												{" "}
												<Link to="/agency-login">
													{" "}
													Sign In
													<img
														className="menu-bef"
														src="../../../img/front/menu-user.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-user-clr.svg"
														alt="images"
													/>
												</Link>{" "}
											</li>
											<li>
												{" "}
												<Link to="/">
													{" "}
													Home
													<img
														className="menu-bef"
														src="../../../img/front/menu-home.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-home-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li className="active">
												{" "}
												<Link to="/features">
													{" "}
													Features
													<img
														className="menu-bef"
														src="../../../img/front/menu-settings.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-settings-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/pricing">
													{" "}
													Pricing
													<img
														className="menu-bef"
														src="../../../img/front/menu-price.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-price-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/resources">
													{" "}
													Resources
													<img
														className="menu-bef"
														src="../../../img/front/menu-resource.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-resource-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/contact">
													{" "}
													Contact
													<img
														className="menu-bef"
														src="../../../img/front/menu-contact.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-contact-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				<Header></Header>
				<section className="policy-section">
					<div className="container">
						<div className="vals-policy">
							<Col md="12">
								<h4>Privacy Policy</h4>
							</Col>
							<Col md="7">
								<h6>Policy Acceptance</h6>
								<p>
								This privacy policy applies to the website Get Shifts operated by  NXT Digital Limited trading as “Get Shifts” and shall include all personal data processed by us through direct mail, telephone or social media channels. Any reference to “you” or “your” means you, the user
								</p>
								<p>
								Your acceptance of the policy is deemed to occur upon your first use of the website. You are required to read and accept this Privacy Policy when you engage with us.
								</p>
								<p>
								Get Shifts may change this policy from time to time by updating this page. On each visit to the website you should refer to this page to ensure that you are aware of and accept any changes.
								</p>
							</Col>

							<Col md="5">
								<img src="../../../img/front/privacy-policy.svg" alt="img" />
							</Col>

							<Col md="12">
								<h6> Policy Statemnet</h6>
								<p>
									{" "}
									Get Shifts recognises the trust you place in us when you
									share personal data with us. We are committed to being open,
									honest and transparent with our use of personal data.
								</p>

								<p>
									This privacy policy provides you with details of the personal
									data we collect when we engage with you, how we will use and
									look after your personal data, your privacy rights and how the
									law protects you. We will take all reasonable steps to ensure
									that personal data is safeguarded and kept in accordance with
									data protection law. By providing us with your data, you
									warrant that you are over 18 years of age.{" "}
								</p>

								<h6> About us </h6>
								<p>This Policy applies to your use of the website Get Shifts. The website is owned by NXT Digital Ltd, trading as Get Shifts, and is registered in England and Wales under company registration number 08835058. Our registered office address is Unit 5, Christie Fields Office Park, Christie Way, Manchester M21 7QY.</p>
								<p>Where we manage personal data, we identify as a Data Controller and recognise and act on our obligations under applicable data protection law</p>
								<p>For any issues relating to data protection the person responsible is Macs Mahlangu. If you have a concern in relation to data protection matters, you can contact us by email to <a href="mailto:privacy@getshifts.co.uk"> privacy@getshifts.co.uk </a></p>

								<h6>What personal data de we collect?</h6>
								<p>Personal data is any information relating to an identified or identifiable individual. It does not include data where the identity has been removed (i.e. anonymous data). We may collect, use, store and transfer different kinds of personal data about you when we engage with you. This may include any of the following:</p>
								<ul className="list-markers">
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Identity Data - title, first name, last name, date of birth or similar identifiers. If you interact with us through social media, this may include your social media user name</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Contact Data - billing address, delivery address, email address and telephone numbers</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Financial Data – bank account and payment card details</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Transaction Data - details about services you have purchased from us</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i>  Technical Data - includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Profile Data – your username and password, purchases made by you, your interests, preferences, images and videos uploaded, qualifications and references, feedback and survey responses </li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Geographical Data - information setting out your primary address to control the use of location services in most mobile devices and desktop settings</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Usage Data - information about how you use our website(s) and service(s)</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Marketing and Communications Data - includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
								</ul>
								<h6>How do we collect personal data?</h6>
								<p>We use different methods to collect data from and about you, through the following:</p>
								<p><b>Registration:</b> Personal details provided during the sign up process on our website are collected so that we can register you and respond to your communications with details of our services. Data is held on the grounds of necessary to fulfil a contractual obligation and as legitimate to our business interests. </p>
								<p>Once registration is completed, data is used on an ongoing basis to enable you to continue to access the website and our services during your trial or as a subscriber to our services. This may include details of service updates and promotions.</p>
								<p><b>Emails:</b> We retain copies of emails sent to us and any personal data will be held in accordance with this Privacy Policy on the basis of being legitimate to our business interests.</p>
								<p><b>Telephone calls:</b> Calls to us may be recorded and any data relating to the call may be retained by us. The data will be held on the basis of being for our legitimate business needs or in order to fulfil our contractual obligations if you are a client of ours.</p>
								<p><b>Other direct interactions:</b> You may give us your personal data by filling in forms or by corresponding with us face-to-face, by post, through social media or online live chat. This includes personal data you provide when you: sign up to receive our services; make enquiries or request information be sent to you; use our services; ask for information to be sent to you; engage with us on social media; contact us directly; or leave comments or reviews on our services.</p>
								<p><b>Social media: We use social media to engage with users and link to our Facebook, Google, LinkedIn and Twitter pages. We do not keep any specific data that identifies you as an individual user but hold details of our followers on these platforms. You should refer to the Privacy Policies of these channels to understand how they treat your data in relation to linking to our site:</b></p>
								{/* <ul className="social-pannels">
									<li><Link to="#"> <i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
									<li><Link to="#"> <i className="fa fa-google-plus" aria-hidden="true"></i></Link></li>
									<li><Link to="#"> <i className="fa fa-linkedin" aria-hidden="true"></i> </Link></li>
									<li><Link to="#"> <i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
								</ul> */}
								<p>If you send us a direct message via social media, the details may be retained by us only as relevant to any ongoing contract or to further our legitimate business interests or as required for legal purposes. The third-party provider may also retain details in accordance with their Privacy Policy.</p>
								<p><b>Blog:</b> You may comment on blogs posted on our website and your comments may be seen by other users. You are advised not to provide any personal details in your comments. Your user name may be published alongside any comment you make.</p>
								<p><b>Reviews:</b> We may ask for a review of our services and these may be published on our website or social media. We may publish your name, position in the company and company name if provided with these details.</p>
								<p><b>Visits to our website:</b> When you visit our website, we do not attempt to identify you as an individual user, and we will not collect personal data about you unless you specifically provide this to us. As you interact with our website, technical data may be automatically processed through the use of Cookies, details of which are explained in our Cookie Policy.</p>
								<p><b>Special categories of data:</b> We do not generally collect any special categories of personal data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
								<p><b>Children:</b> We do not market this website at those under 18 years old. Consistent with the GDPR we will never knowingly request personally identifiable information from anyone under the age of 16 years old. We will take appropriate steps to delete any personal data of individuals less than 16 years of age that has been collected on our website upon learning of the existence of such data.</p>

								<h6>information we get from other sources</h6>
								<p>From time to time, we may need to obtain information from third parties about you. This will only apply where it is necessary to provide our services and as permitted by law.</p>
								<p>We may receive personal data relating to your identity and contact data from data partners; and data from any third parties who are permitted by law or have your permission to share your personal data with us, such as via social media or review sites.</p>

								<h6>How we use your data</h6>
								<p>UK data protection law requires us to have a “legal basis” for processing personal data. The legal bases we rely on are:</p>

								<ul className="list-markers">
									<li> <i className="fa fa-caret-right" aria-hidden="true"></i> Performance of a contract we are about to enter into or have entered into with you</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Compliance with a legal or regulatory obligation</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i>  Carrying out activities that are legitimate to our business interests</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Consent. However, generally, we shall not rely on consent as a legal basis for processing your personal data other than where the law requires it. Where our legal basis is consent, you have the right to withdraw consent any time.</li>
								</ul>
								<h6>We may use the personal data we collect from you as outlined in this table:</h6>

								<div className="cookies-table-section">
									<div className="scrl-funs">
										<table className="table">
											<thead>
												<tr>
													<th> Use of personal data	</th>
													<th> Type of data </th>
													<th> Legal basis </th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td> To register you on our website.	</td>
													<td> <p>(a) Identity</p>
														<p> (b) Contact </p>
													</td>
													<td>
														Performance of a contract or to take steps to enter into a contract To enable us to provide services during the trial period (initial 1 month).
													</td>
												</tr>

												<tr>
													<td>  To provide, manage and personalise our services to you, respond to communications.	</td>
													<td>
														<p> (a) Identity </p>
														<p> (b) Contact </p>
													</td>
													<td>
														Where necessary for the performance of our agreement or to take steps to enter into an agreement It is in our legitimate interest to make sure that our customer accounts are well-managed, and to provide a high standard of service.
													</td>
												</tr>

												<tr>
													<td> To process payments for our services </td>
													<td>
														<p> (a) Identity </p>
														<p> (b) Contact </p>
														<p> (c) Financial </p>
														<p> (d) Transaction </p>
													</td>
													<td>Performance of a contract Necessary to comply with a legal obligation.</td>
												</tr>

												<tr>
													<td>To administer and improve the website	</td>
													<td>
														<p> (e) Technical </p>
														<p> (h) Usage </p>
													</td>
													<th>It is in our legitimate interests to develop and improve our products and services, so that we can continue to provide products and services that our customers want to use, and to make sure we continue to be competitive.</th>

												</tr>


												<tr>
													<td> To send email notifications which have been specifically requested.</td>
													<td>
														<p> (a) Identity </p>
														<p> (b) Contact </p>
													</td>
													<td>
														It is in our legitimate interests to give you information about our products and services that you may be interested in.
													</td>

												</tr>

												<tr>
													<td> To send marketing communications, where expressly agreed e.g. newsletters.	</td>
													<td>
														<p> (a) Identity </p>
														<p> (b) Contact </p>
														<p> (i) Marketing and Communications </p>
													</td>
													<td> In the case of electronic marketing we have your consent to do so </td>
												</tr>

												<tr>
													<td> To provide third parties with statistical information about our users </td>
													<td>
														<p> (e) Technical</p>
														<p> (h) Usage </p>
													</td>
													<td> It is in our legitimate interests to better understand how our customers use our products and what changes we could make to improve them </td>
												</tr>

												<tr>
													<td>To ask for feedback or a review	</td>
													<td>
														<p>(a) Identity</p>
														<p>(b) Contact</p>
													</td>
													<td>It is in our legitimate interests to better understand how our customers use our products and what changes we could make to improve them</td>

												</tr>

												<tr>
													<td> To deal with enquiries and complaints made by or about you relating to the website	</td>
													<td>
														<p> (a) Identity </p>
														<p> (b) Contact </p>
														<p> (e) Technical </p>
														<p> (h) Usage</p>
													</td>
													<td>
														It is in our legitimate interests to make sure that our customer accounts are well-managed, so that our customers are provided with a high standard of service
													</td>
												</tr>

												<tr>
													<td>To recover debt and exercise other rights we have under any agreement with you, as well as to protect ourselves against harm to our rights and interests in property	</td>
													<td>
														<p> (a) Identity </p>
														<p> (b) Contact </p>
														<p> (c) Financial </p>
														<p> (d) Transaction</p>
													</td>
													<td>Where necessary to perform a contract or to take steps to enter into an agreement with you Where the law requires this It is in our legitimate interests to ensure that we can recover debts owed to us, as well as making sure our assets are protected</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<p>Users contacting this website and/or its owners do so at their own discretion and provide any such personal data requested at their own risk. Your personal data is kept private and stored securely until a time it is no longer required or has no use.</p>

								<p><b>Our legitimate interests:</b> When we use our legitimate interests as the legal basis for processing your personal data, we will consider and balance any potential impact on you and your rights before we process your personal data. We will only then proceed where we believe our interests are not overridden by the impact on you. Our legitimate interests include the management of our business operations.</p>

								<h6>Sharing Information</h6>
								<p><b>Disclosure:</b> We don’t share, sell, or distribute your data to third parties, except as contractually agreed with you or as provided in this Privacy Policy. We may disclose your personal data if we are required to do so by law, in connection with any legal proceedings, and in order to establish, exercise or defend our legal rights, or if otherwise legally permitted.</p>
								<p><b>Data Processors:</b> We may use Data Processors who act on our instruction in relation to the management of your data and they must adhere to all data protection laws and regulations. We will ensure that any Data Processors used only operate on our written instructions and comply with their obligations under the GDPR.</p>
								<p>You will be informed of any other Data Controllers who have access to your data and who may determine processing activities separately to us, or as a Joint Data Controller.</p>
								<p><b>Marketing:</b>  We may carry out direct marketing by email, phone, text or post.</p>
								<p>We will ask for your consent to receiving marketing communications (including newsletters) when you register on the website and you have the option not to give consent and to withdraw consent given at any time. You may withdraw your consent for us to contact you by email to <a href="mailto:privacy@getshifts.co.uk"> privacy@getshifts.co.uk. </a> We may continue to contact you for non-marketing purposes if there is another lawful basis to do so.</p>
								<p>Non-personally identifiable information may be provided to other third parties for marketing, advertising or other uses.</p>
								<p><b>External links:</b> Users of the website are advised to adopt a policy of caution before clicking on any external web links. Clicking an external link will take the user away from our website. Once you leave our website or are redirected to a third-party website, plug-in or application, you are no longer governed by this Privacy Policy or our website’s terms and conditions. We cannot guarantee or verify the contents of any externally linked website and users click on external links at their own risk. Get Shifts and its owners cannot be held liable for any damages, or the consequences of visiting any external links.</p>
								<p><b>Social media platforms:</b> Communication, engagement and actions taken through external social media platforms that this website and its owners participate on are subject to our terms and conditions as well as the privacy policies held with each social media platform respectively.</p>
								<p>Users are advised to use social media platforms wisely and communicate and/or engage with them with due care and caution in regard to their own privacy and personal details. This website nor its owners will not ask for personal or sensitive information through social media platforms and encourage users wishing to discuss sensitive details to contact them through primary communication channels such as by telephone or email.</p>
								<p>Get Shifts uses social sharing buttons which help share web content directly from web pages to the social media platform in question. Users are advised that before using such social sharing buttons, that they do so at their own discretion, and should consider that the social media platform may track and save requests to share a web page, through the users’ social media platform account.</p>
								<p><b>Payment processing:</b> Payment for our services is processed online through the application of our payment provider, Stripe, and all data is safeguarded and handled in accordance with recognised industry standards. You can refer to their Privacy Policy at <a href="https://stripe.com/gb/privacy" target="__blank"> stripe.</a> </p>
								<h6>Data Retention</h6>
								<p>We keep your personal data in accordance with our Data Retention Policy which reflects our needs to provide services to you as contracted and also as required to meet legal, statutory and regulatory obligations. The need to hold information is regularly reviewed and data will be disposed of when no longer required.</p>
								<h6>Data Security</h6>
								<p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, personal data is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems and are required to keep the information confidential.</p>
								<p>We take appropriate steps to ensure a safe processing of personal data, however, we cannot guarantee the security of data transmitted through our website or by email. Any transmission is at your own risk.</p>

								<h6>Data Storage</h6>
								<p>Any information that you supply to us may be stored and processed by our website host, Amazon Web Services (Europe). Your data may be transferred in accordance with the policies of Amazon Europe and under relevant data protection law. You can refer to their Privacy Policy at: <a
									href="https://www.amazon.co.uk/gp/help/customer/display.html?ie=UTF8&amp;nodeId=201909010&amp;ref_=footer_privacy" target="__blank"> Amazon </a></p>

								<h6>Right of Data Subjects</h6>
								<p>Get Shifts recognises a data subjects rights and will uphold these in accordance with data protection law. In relation to certain rights, we may ask you for information to confirm your identity and, where applicable, to help us to search for your personal information. Except in rare cases, we will respond to you within one month from either (i) the date that we have confirmed your identity, or (ii) where we do not need to do this because we already have this information, from the date we received your request.</p>
								<p><b>Subject access requests:</b>
									You have the right to ask for a copy of the information that we hold about you by email to <a href="mailto:privacy@getshifts.co.uk"> privacy@getshifts.co.uk </a> We may not provide you with a copy of your personal information if this concerns other individuals or we have another lawful reason to withhold that information. In limited circumstances a fee may apply.</p>
								<p><b>Right to rectification:</b> Data subjects have the right to request that personal data is amended or changed if it is inaccurate or incorrect. We act on any such request without delay.</p>
								<p><b>Right to erasure:</b> Data subjects have the right to ask us to delete personal data from our systems without giving any reason and at any time. We act on any such request without delay.</p>
								<p><b>Right to restrict processing:</b> Data subjects have the right to rectification or erasure of personal data in the following circumstances:</p>
								<ul className="list-markers">
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Personal data is not accurate</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> The processing of data is unlawful</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Data is required to exercise legal rights or defend legal claims</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i> Data is unlawful, although there may be lawful grounds for processing, which override this right.</li>
								</ul>
								<p><b>Right to data portability:</b>Data subjects have the right to obtain and request the transfer of their data to different service providers.</p>
								<p><b>Right to object:</b> Data subjects have the right to object to the processing of personal data at any time based on their particular situation. This includes objecting to profiling unless it is in the ‘public interest’ or exercised lawfully by an official authority. We will only process data under lawful grounds.</p>
								<p><b>Right not to be subject to decisions based on automated processing:</b> We do not use any automated processing that results in any automated decision based on a data subject’s personal data</p>
								<p><b>Using your rights:</b> If you wish to invoke any of these rights, you should contact the person responsible for data protection by email to <a href="mailto:privacy@getshifts.co.uk"> privacy@getshifts.co.uk. </a></p>
								<p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
								<h6>Data Branches</h6>
								<p>We will report any unlawful breach of data as required by the GDPR within 72 hours of the breach occurring, if it is considered that there is an actual, or possibility, that data within our control including the control of our data processors, has been compromised. If the breach is classified as ‘high risk’ we will notify all data subjects concerned using an appropriate means of communication. We will report any relevant breaches to the ICO, see below.</p>
								<h6>Changes to our privacy policy</h6>
								<p>We reserve the right to change this Privacy Policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. You will be deemed to have accepted any changes to the terms of the privacy policy on your next visit of the website following the amendment.</p>
								<h6>Reporting Compliants</h6>
								<p>If you want to raise a concern about the use of your data, you can contact us by email to <a href="mailto:privacy@getshifts.co.uk"> privacy@getshifts.co.uk </a></p>
								<p>Alternatively, you can formally raise a concern or complaint to the Information Commissioner’s Office (ICO) – the UK regulatory authority for data protection - directly on 0303 123 1113, or see the options for reporting issues on <a href="https://ico.org.uk/concerns/"> https://ico.org.uk/concerns/.</a></p>
							</Col>
						</div>
					</div>
				</section>

				<Footer></Footer>


			</>
		);
	}
}
