import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './header'
import Footer from './NewFooter'
import WOW from '../../animation/Wow'
import AuthetiCationModal from './AuthetiCationModal'

// Images
import shiftmanagement from '../../images/feature-new/resoueces/shift_management.svg'
import timesheets from '../../images/feature-new/resoueces/timesheets.svg'
import employee from '../../images/feature-new/resoueces/employee.svg'
import invoice from '../../images/feature-new/resoueces/invoice.svg'
import compliance from '../../images/feature-new/resoueces/compliance.svg'
import recruitment from '../../images/feature-new/resoueces/recruitment.svg'
import resources from '../../images/feature-new/resources.svg'
import { GetStartCTA, GetStartCTATrail } from './Features'
import { BookDemoButton } from './Features'
export default class NewFeature extends Component {
   state = {
      activeTab: '1',
      activeTabs: '1',
      showLoginModal: false,
      autheticateTab: 1
   }
   componentDidMount() {
      new WOW().init()
      window.scrollTo(0, 0)
   }

   toggle = activeTab => {
      this.setState({ activeTab: activeTab })
   }

   toggles = activeTabs => {
      this.setState({ activeTabs: activeTabs })
   }

   openSigninModal = (e, tab) => {
      this.setState({ showLoginModal: true })
      this.setState({ autheticateTab: tab })
   }

   closeSigninModal = e => {
      this.setState({ showLoginModal: false })
   }
   handleButtonClick = buttonNumber => {
      this.setState({ activeButton: buttonNumber })
   }

   render() {
      const { activeButton } = this.state

      const scrollToCenter = id => {
         const element = document.getElementById(id)
         if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
            const offset = window.innerHeight / 2 - element.clientHeight / 2
            window.scrollTo({
               top: elementPosition - offset,
               behavior: 'smooth'
            })
         }
      }

      return (
         <>
            <Header></Header>
            <div className="features_page mt-4">
               <h4>
                  <span>Get Shifts</span> Is Packed With Features <br /> To Improve Your Temporary <br /> Staffing
               </h4>
               <div className="container">
                  <div className="row mt-5" >
                     <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="shift_management">
                           <h5>Shift Management</h5>
                           <h6>
                              Effortless <br /> Shift Management
                           </h6>
                           <div className='child'>
                              <img src={shiftmanagement} alt="img" />
                              <p>
                                 Streamline Staff Scheduling With Get Shifts Shift Management Module; The Central, Most Powerful And Intuitive Module Of The
                                 Get Shifts Platform That Lets You Create, Edit And View All Your Staff Shifts And Schedules Online.
                              </p>
                              <Link to="/shift-management-software" className="btn feature_learnmore">
                                 Read more<i class="fa-solid fa-play"></i>
                              </Link>
                           </div>
                        </div>
                        <div className="employee">
                           <h5>EMPLOYEE</h5>
                           <h6>
                              Manage Employees <br /> with ease
                           </h6>
                           <div className='child'>
                              <img src={employee} alt="img" />
                              <p>
                                 Take Control Of Your Entire Staff Database In One Intuitive Portal: View, Edit And Update Staff Details In A Couple Of Clicks.
                              </p>
                              <Link to="/employee-management-software" className="btn feature_learnmore">
                                 Read more<i class="fa-solid fa-play"></i>
                              </Link>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-12 col-sm-12 timesheet_invoice">
                        <div className="timesheet">
                           <h5>TIMESHEETS</h5>
                           <h6>
                              Automated <br /> Online Approval
                           </h6>

                           <div className='child'>
                              <img src={timesheets} alt="img" />
                              <p>
                                 Are we ready to ditch the paper? <br /> With Automated Online Timesheets, All The Calculations Are Made In Get Shifts, And Since Everyone Can View Their Timesheets Anywhere, There’s Actually Very Little Need For Paper.
                              </p>
                              <Link to="/timesheet-software" className="btn feature_learnmore">
                                 Read more<i class="fa-solid fa-play"></i>
                              </Link>
                           </div>
                        </div>
                        <div className="invoicing">
                           <h5>INVOICE</h5>
                           <h6>
                              INVOICE
                           </h6>
                           <div className='child'>
                              <img src={invoice} alt="img" />
                              <p>
                                 Generating Invoices Automatically When Timesheets Are Approved Ensures Timely Payments With Less Pain, All In One Platform.
                              </p>
                              <Link to="/invoicing-software" className="btn feature_learnmore">
                                 Read more<i class="fa-solid fa-play"></i>
                              </Link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row experience_benifit">
                  <div className="col-lg-6 col-md-6 col-sm-12 left_side">
                     <img src={resources} alt="resources" />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 right_side">
                     <div>
                     <p>Experience the benefits of Get Shifts with no commitment. Sign up for a free trial and see how our platform can help optimise your scheduling and workforce management</p>
                     <div className="get_start_book_demo">
                        <Link className="purple_bg" to="/signup">
                           Get started <span>&nbsp;- Its Free</span>
                        </Link>
                        <BookDemoButton />
                     </div>
                     </div>
                  </div>
               </div>

               <div className="container mt-5">
                  <div className="row">
                     <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="compliance">
                           <h5>COMPLIANCE</h5>
                           <h6>
                              Worry-Free <br /> Compliance
                           </h6>
                           <div className='child'>
                              <img src={compliance} alt="img" />
                              <p>
                                 Stay On Top Of Staff Compliance And Enjoy Peace Of Mind With Centralised Record Keeping. With Get Shifts, Your Employees’
                                 Information Is Digitally Stored And Organised.
                              </p>
                              <Link to="/compliance-software" className="btn feature_learnmore">
                                 Read more<i class="fa-solid fa-play"></i>
                              </Link>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="recruitment">
                           <h5>Recruitment</h5>
                           <h6>
                              Recruitment <br /> Tracking
                           </h6>
                           <div className='child'>
                              <img src={recruitment} alt="img" />
                              <p>
                                 Our Integrated Recruitment Tracking System Is Designed To Take The Hustle Off Your Recruitment Process, From Application,
                                 Compliance checks, Referencing , Interviews, Through To Staff On-boarding, All In One Powerful and Intuitive System.
                              </p>
                              <Link to="/recruitment-softwares" className="btn feature_learnmore">
                                 Read more<i class="fa-solid fa-play"></i>
                              </Link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {this.state.showLoginModal === true ? (
               <AuthetiCationModal
                  loginModal={this.state.showLoginModal}
                  closeModal={e => this.closeSigninModal(e)}
                  autheticateTab={this.state.autheticateTab}
               />
            ) : null}
            <Footer></Footer>
         </>
      )
   }
}
