import React, { Component } from "react";

export default class Register extends Component {
  componentDidMount() {
    window.location = "/employee/register";
  }
  render() {
    return <div></div>;
  }
}
