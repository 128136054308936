import React from 'react'
import './doc.scss';
import success from '../../images/email_success.svg';
import deleteSign from  '../../images/myFiles-deleted.gif';
function SuccessPage({download,Sign,onDownload,docId}) {
    return (
        <>
            {Sign && Sign === '1' ? <div className='success-bg'>
                <div className='success_modal'>
                    <span>
                        <img src={success} />
                    </span>
                    <h4>Thank you!</h4>
                    <p>Your submission has been received</p>
                    {download && download ===  '1'? <p>Click here to : <button onClick={()=>onDownload(docId)}>Download</button></p> : null}
                </div>
            </div> :
            <div className='success-bg'>
            <div className='success_modal'>
            <span>
                <img src={deleteSign} />
            </span>
            <h4>oops!</h4>
            <p>This document has been deleted. Please check your mail.</p>
        </div>
        </div>
        }

        </>

    )
}

export default SuccessPage