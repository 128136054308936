

import React, { useEffect, useState } from 'react';
import Header from "./header";
import Footer from "./NewFooter";
import play from '../../images/play-button.svg';
import pause from '../../images/pause-button.svg';

import get_shifts from '../../images/viewdemo/get-shifts.png';
import getting_started from '../../images/viewdemo/getting-started.png';
import creating_assigning from '../../images/viewdemo/creating-assigning.png';


const NewViewDemo = () => {


  const video_state=[
    {
      id:1,
      link:"https://agencyportal.portal.trainn.co/share/jXwrY4yw5ag0uovyjJYl9Q/embed?autoplay=false",
      title:"Get Shifts Demo",
      thumbnail:get_shifts
    },
    {
     
      id:2,
      link:"https://agencyportal.portal.trainn.co/share/HKWVT7NpFzwSdEzsVbwZPg/embed?autoplay=false",
      title:"Getting started with Get Shifts",
      thumbnail:getting_started
    },
    {
      id:3,
      link:"https://agencyportal.portal.trainn.co/share/PBq2JRmVT8gzkACIoMW4Lg/embed?autoplay=false",
      title:"Creating and Assigning a Shifts",
      thumbnail:creating_assigning
    },
  ]

  const default_state=video_state[0].link;

  const[videos,setVideo]=useState(default_state);
  const [activeVideo, setActiveVideo] = useState(default_state);
  const [loading, setLoading] = useState(false);

  const changeVideo = (link) => {
    if (activeVideo !== link) {
      setLoading(true);
      setVideo(link);
      setActiveVideo(link);
    }
  };
  

  const handleVideoLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <Header />
      <div className="viewdemo">
        <h3>
          Your comprehensive guide to maximising <br /> the benefits of Get
          Shifts for your business needs.
        </h3>
        <div className="video_content">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="video_tittle">
                  {video_state.map((video,idx)=>(
                    <button type='button' key={idx} onClick={() => changeVideo(video.link)} className={false? 'activeVideo' : ''} disabled={false}>
                        <div className="video-thumb">
                          <img src={video.thumbnail} alt='thumbnail' className='video-thumbnail'/>
                        {loading && <div className="loader-box"><span className="loader"></span></div>}
                          <img src={play} alt="play" className="play-video" />
                            <img src={pause} alt="pause" className="pause-video" />
                        </div>
                        {video.title}
                    </button>
                  ))}
              
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="video-show">
                  <div style={{position: "relative",paddingBottom: "50%",minWidth: "100%",height: "100%",borderRadius: "10px",overflow: "hidden",}}>
                    <iframe
                      title='Video Demo'
                      src={videos}
                      frameBorder={0}
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowFullScreen
                      allow="autoplay; fullscreen"
                      style={{position: "absolute",top: 0,left: 0,width: "100%",height: "100%",}}
                      onLoad={handleVideoLoad}
                      />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewViewDemo;
