import React, { Component } from "react";

export default class Login extends Component {
  componentDidMount() {
    window.location = "/employee/";
  }
  render() {
    return <div></div>;
  }
}
