import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader, Container, Row, Col, CardBody, CardTitle, CardText, Button, FormGroup, Input, Label } from 'reactstrap'
import Header from './header'
import Footer from './NewFooter'

import { GetStartButton } from './Features'
import {
   Slider,
   TextField,
   Typography,
   Grid,
   CardContent,
   CardActions,
   Paper,
   Popover,
   Button as MuiButton,
   Box,
   makeStyles,
   Card,
   Divider,
   styled
} from '@material-ui/core'
import request, { NodeURL } from '../../api/api'
import { ReactComponent as AddedSVG } from '../../images/pricing-new/added.svg'
import { ReactComponent as AddSVG } from '../../images/pricing-new/add.svg'

const PricingSection = () => {
   return (
      <>
         <Header></Header>

         <Container className="text-left my-5">
            <Row>
               <div>
                  <h1 className="display-4 pricing_txt_color">Simple and Comprehensive Pricing from</h1>
               </div>
            </Row>
            <Row>
               <div>
                  <h2 className="display-3 pricing_txt_color bold-6">£1 per Employee</h2>
               </div>
            </Row>
            <Row>
               <div>
                  <p className="lead text-muted pricing_txt_color" style={{ maxWidth: '680px' }}>
                     We believe that a good customer relationship should be a part of every business. All of our plans have been designed to fit your agency’s
                     requirements.
                  </p>
               </div>
            </Row>
            <Row>
               <Col>
                  <GetStartButton buttonStyle={{ width: '16%', padding: '2px 0' }} isNobookDemo />
               </Col>
            </Row>
         </Container>
         <PricingPage />
         <Footer></Footer>
      </>
   )
}

function PricingPage({}) {
   const [planData, setPlanData] = useState([])
   const [noEmployee, setNoEmployee] = useState(1)

   const addedPlan = useMemo(
      () =>
         planData
            .filter(i => i.added)
            .map(item => {
               item.totalPrice = Number(item.employee_per_price ? noEmployee * item.price : item.price)
               return item
            }),
      [noEmployee, planData]
   )

   const getPlanDetials = async () => {
      try {
         const respData = await request({ method: 'GET', url: '/site/subscription/plan-lists' })
         if (respData.status === 1) {
            let cartPlans = localStorage.getItem('cartPlans') || [];
            let plans = JSON.parse(respData.response);
            plans = Array.isArray(plans) ? plans.map(item => ({ ...item, added: cartPlans.length ? cartPlans.includes(item._id) : false })) : []
            setPlanData(() => plans)
            return plans
         } else {
            return []
         }
      } catch (error) {
         console.log('Error to get the plan details...')
      }
   }

   useEffect(() => {
      getPlanDetials()
   }, [])

   const onPlanChange = (planData=[])=>{
      let cartPlans = planData.filter(i=>i.added).map(i=>i._id);
      localStorage.setItem('cartPlans',JSON.stringify(cartPlans))
      setPlanData(planData)
   }

   return planData.length > 0 ? (
      <Container maxWidth="lg" className={'pricing_module_box'}>
         <EmployeeSlider value={noEmployee} onValueChange={e => setNoEmployee(e)} />
         <Grid container spacing={4} style={{ marginTop: 16 }}>
            <Grid item lg={8} md={8} sm={12} xs={12} className="module_cards">
               <ModuleCards planData={planData} onPlanChange={onPlanChange} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
               {/* <Summary /> */}
               <SubscriptionCard noEmployee={noEmployee} addedPlan={addedPlan} />
            </Grid>
         </Grid>
      </Container>
   ) : (
      <h5 style={{ textAlign: 'center' }}>Loading...</h5>
   )
}

function EmployeeSlider({ value = 10, onValueChange = () => {} }) {
   const handleSliderChange = (event, newValue) => {
      // setValue(newValue)
      onValueChange(newValue)
   }

   const handleInputChange = event => {
      onValueChange(event.target.value === '' ? '' : Number(event.target.value))
   }

   return (
      <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} alignItems="center">
         <Grid item xs={12} sm={12} lg={8} md={8} className="no_of_emp">
            <Typography variant="body1" className="enter_no_of_emp">
               Enter Number of Employees
            </Typography>
            <TextField className="text_range" value={value} onChange={handleInputChange} variant="outlined" size="small" />
            <Slider
               valueLabelDisplay="on"
               getAriaValueText={() => value}
               value={value}
               onChange={handleSliderChange}
               aria-labelledby="employee-slider"
               min={1}
               max={500}
            />
         </Grid>

         <Grid container lg={4} md={4} sm={12} xs={12} alignItems="center" justifyContent="center">
            <PriceCalculated />
         </Grid>
      </Grid>
   )
}

const useCalculatedStyles = makeStyles(theme => ({
   popover: {
      boxShadow: theme.shadows[0],
      color: '#757575',
      textAlign: 'left',
      fontSize: '14px'
   },
   paper: {
      // padding: theme.spacing(2),
      // borderRadius: '10px',
      borderRadius: '16px',
      // border: '1px solid #8061B3',
      color: 'green',
      width: '350px'
   },
   cancelpaper: {
      // padding: theme.spacing(2),
      // borderRadius: '10px',
      borderRadius: '16px',
      // border: '1px solid #8061B3',
      color: 'green',
      width: '306px'
   }
}))

function PriceCalculated() {
   const [pricecalculated, setPricecalculated] = useState(null)
   const popoverClasses = useCalculatedStyles()

   const handleClick = event => {
      setPricecalculated(event.currentTarget)
   }

   const handleClose = () => {
      setPricecalculated(null)
   }

   const open = Boolean(pricecalculated)
   const id = open ? 'how_price_calculated' : undefined

   return (
      <div>
         <Box
            sx={{
               padding: '8px 16px',
               backgroundColor: '#fffde7',
               border: '1px dashed #f1c40f',
               borderRadius: '8px',
               cursor: 'pointer'
            }}
         >
            <Typography onClick={handleClick}>How is the price calculated?</Typography>
         </Box>
         <Popover
            id={id}
            open={open}
            anchorEl={pricecalculated}
            anchorOrigin={{
               vertical: 'top',
               horizontal: 'center'
            }}
            transformOrigin={{
               vertical: 'bottom',
               horizontal: 'center'
            }}
            onClose={handleClose}
            classes={{ paper: popoverClasses.paper }}
         >
            <div className={`${popoverClasses.popover} price_calculated_popover`} style={{ padding: '10px 15px' }}>
               <Typography variant="subtitle1">Note</Typography>
               <Typography variant="body1">The price is based on the number of Employees</Typography>
               <Typography variant="body1">
                  The total price is worked out based on this formula{' '}
                  <span style={{ textShadow: '0 0 black' }}> (Shift Management + Modules)*No.of Employees + other modules </span>
               </Typography>
               <Typography variant="body1">Certain modules have a minimum employee charge</Typography>
            </div>
         </Popover>
      </div>
   )
}

function ModuleCard({
   title,
   price,
   onAdd,
   added,
   description = '',
   handleReadMore = () => {},
   isExpanded = false,
   moduleId = '',
   employee_per_price = false,
   planIcon = ''
}) {
   const cardRef = useRef(null)

   const handleClickOutside = event => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
         handleReadMore('')
      }
   }

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
         document.removeEventListener('mousedown', handleClickOutside)
      }
   }, [isExpanded, handleReadMore])

   return (
      <Card className={`module_plan_card ${isExpanded ? 'h-100' : ''}`} ref={cardRef}>
         <CardContent>
            <div className="moduel_plan_title">
               <span>
                  <img src={`${NodeURL}/${planIcon}`} alt="Plan" />{' '}
               </span>
               <div>
                  <Typography variant="h6" className="price-title">
                     {title}
                  </Typography>
                  <Typography variant="h6" className="price_content">
                     {employee_per_price ? 'Minimum Charge for 10 Emp' : ''}
                  </Typography>
               </div>
            </div>

            <Typography variant="body2" className={`price-truncated-text ${isExpanded ? 'price-expanded' : ''}`}>
               {description}
               <span className={` price-read-more-text ${isExpanded ? 'position-relative' : ''} `} onClick={() => handleReadMore(moduleId)}>
                  {!isExpanded ? 'read more' : 'read less'}
               </span>
            </Typography>
         </CardContent>
         <CardActions className="price_amount_box">
            <div className="price_amount">
               <Typography variant="h5">
                  £{Number(price).toFixed(2)}
                  <span>{employee_per_price ? '/Emp' : '/mo'}</span>
               </Typography>
            </div>
            <MuiButton
               startIcon={added ? <AddedSVG /> : <AddSVG />}
               variant={'contained'}
               className={`moidule_plan_button ${added ? 'added' : ''}`}
               onClick={onAdd}
            >
               {added ? 'Added' : 'Add'}
            </MuiButton>
         </CardActions>
      </Card>
   )
}

function ModuleCards({ planData = [], onPlanChange = () => {} }) {
   const [isExpanded, setIsExpanded] = useState(false);


   const handleAdd = index => {
      const newModules = [...planData]
      newModules[index].added = !newModules[index].added;
      onPlanChange(newModules)
   }

   const handleReadMore = (id = '') => {
      setIsExpanded(prev => (prev === id ? '' : id))
   }

   return (
      <Grid container>
         {planData.map((moduleitem = {}, index) => (
            <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
               <ModuleCard
                  {...moduleitem}
                  title={moduleitem.name}
                  price={moduleitem.price}
                  added={moduleitem.added}
                  onAdd={() => handleAdd(index)}
                  description={moduleitem.description}
                  handleReadMore={id => handleReadMore(id)}
                  isExpanded={isExpanded === moduleitem._id}
                  key={index}
                  moduleId={moduleitem._id}
               />
            </Grid>
         ))}
      </Grid>
   )
}

const StyledCard = styled(Card)(({ theme }) => ({
   // maxWidth: 300,
   margin: 'auto',
   padding: theme.spacing(2),
   borderRadius: theme.shape.borderRadius,
   boxShadow: theme.shadows[1],
   backgroundColor: '#F7FAFC' // Background color of the card
}))

const IconWrapper = styled(Box)({
   display: 'flex',
   alignItems: 'center',
   marginBottom: 8
})

const ModuleRow = styled(Box)({
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   marginBottom: 8
})

const PriceDetails = styled(Box)({
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   marginTop: 8
})

const SubscriptionCard = ({ noEmployee = 0, addedPlan = [] }) => {
   let { sub_total = 0, total_price = 0, vat_total = 0 } = useMemo(
      () =>
         addedPlan.reduce(
            (acc = {}, curr) => {
               acc['sub_total'] = (acc.sub_total || 0) + curr.totalPrice

               acc['vat_total'] = (acc.sub_total || 0) * 0.2
               acc['total_price'] = (acc.sub_total || 0) + acc.vat_total
               return acc
            },
            { sub_total: 0, totalPrice: 0, vat_total: 0 }
         ),
      [noEmployee, addedPlan]
   )

   return (
      <StyledCard className="pricing_summary_card">
         {/* <CardContent> */}
         <span className="module_count">
            <Typography variant="h6" gutterBottom>
               Employees
            </Typography>
            <Typography variant="h4" gutterBottom>
               {noEmployee}
            </Typography>
         </span>
         <Divider style={{ marginTop: 16, marginBottom: 16 }} />
         <span className="module_count">
            <Typography variant="h6" gutterBottom>
               Modules
            </Typography>
            <Typography variant="h4" gutterBottom>
               {addedPlan.length}
            </Typography>
         </span>

         <Divider />
         <div className="addedplanlist">
            {addedPlan.length > 0
               ? addedPlan.map(item => {
                    return (
                       <ModuleRow>
                          <IconWrapper>
                             <img src={`${NodeURL}/${item.planIcon}`} alt="planIcon" />
                             <div>
                                <Typography>{item.name}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                   £{Number(item.price).toFixed(2)}
                                   {item.employee_per_price ? '/Emp' : '/mo'}
                                </Typography>
                             </div>
                          </IconWrapper>

                          <Typography>£{Number(item.totalPrice).toFixed(2)}</Typography>
                       </ModuleRow>
                    )
                 })
               : ''}

            <Divider style={{ marginTop: 16, marginBottom: 16 }} />

            <PriceDetails>
               <Typography>Sub Total</Typography>
               <Typography>£{Number(sub_total).toFixed(2)}</Typography>
            </PriceDetails>
            <PriceDetails>
               <Typography>VAT 20%</Typography>
               {/* <Typography>£{s.toFixed(2)}</Typography> */}
               <Typography>£{Number(vat_total).toFixed(2)}</Typography>
            </PriceDetails>

            <Divider style={{ marginTop: 16, marginBottom: 16 }} />

            <div className="tot_mon">
               <Typography variant="h5" gutterBottom>
                  Total / Month
               </Typography>
               <Typography variant="h4" gutterBottom>
                  £{Number(total_price).toFixed(2)}
               </Typography>
            </div>

            {/* <SubscribeButton variant="contained" fullWidth>
               Get start for Free
            </SubscribeButton> */}
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <div className="subscribe_btn">
               <GetStartButton buttonStyle={{ width: '60%', margin: 'auto' }} isNobookDemo />
            </div>
         </div>
         {/* </CardContent> */}
      </StyledCard>
   )
}

export default PricingSection
