import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import Header from "./header";
import Footer from "./NewFooter";

export default class AntispamPolicy extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	menufun = () => {
		document.getElementById("varmenu").classList.add("shower-menus");
		document.getElementById("shbody").classList.add("shower-body");
	};
	hidingmenu = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	};
	clomenus = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	};
	render() {
		return (
			<>
				{/* <section className="home-header front-header">
					<div className="container">
						<div className="col-md-12">
							<div className="header-sections">
								<Link to="/">
									{" "}
									<img className="ags-logo"
										src="../../../img/front/agency-logo.png"
										alt="logo"
									/>{" "}
								</Link>
								<ul className="inner-menu menu-gains">
									<li>
										{" "}
										<Link to="/agency-register"> Sign Up </Link>{" "}
									</li>
									<li>
										{" "}
										<Link to="/agency-login"> Sign In </Link>{" "}
									</li>
									<li className="men-clicks">
										<span id="clickmenu" onClick={this.menufun}>
											{" "}
											<img
												src="../../../img/home/white-menu.svg"
												alt="logo"
											/>{" "}
										</span>{" "}
									</li>
								</ul>
								<div
									className="fixed-body act-bdy"
									id="shbody"
									onClick={this.hidingmenu}
								></div>

								<div className="showing-menus" id="varmenu">
									<div className="menu-lists">
										<div className="close-menu">
											<img
												id="closeviews"
												onClick={this.clomenus}
												className="menu-aft"
												src="../../../img/front/menu-close.svg"
												alt="images"
											/>
										</div>
										<ul>
											<li className="sign-desk">
												{" "}
												<Link to="/agency-register">
													{" "}
													Sign Up
													<img
														className="menu-bef"
														src="../../../img/front/menu-user.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-user-clr.svg"
														alt="images"
													/>
												</Link>{" "}
											</li>
											<li className="sign-desk">
												{" "}
												<Link to="/agency-login">
													{" "}
													Sign In
													<img
														className="menu-bef"
														src="../../../img/front/menu-user.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-user-clr.svg"
														alt="images"
													/>
												</Link>{" "}
											</li>
											<li>
												{" "}
												<Link to="/">
													{" "}
													Home
													<img
														className="menu-bef"
														src="../../../img/front/menu-home.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-home-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li className="active">
												{" "}
												<Link to="/features">
													{" "}
													Features
													<img
														className="menu-bef"
														src="../../../img/front/menu-settings.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-settings-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/pricing">
													{" "}
													Pricing
													<img
														className="menu-bef"
														src="../../../img/front/menu-price.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-price-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/resources">
													{" "}
													Resources
													<img
														className="menu-bef"
														src="../../../img/front/menu-resource.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-resource-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/contact">
													{" "}
													Contact
													<img
														className="menu-bef"
														src="../../../img/front/menu-contact.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-contact-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				<Header></Header>
				<section className="policy-section">
					<div className="container">
						<div className="vals-policy">
							<Col md="12">
								<h4> Anti-spam Policy </h4>
							</Col>
							<Col md="7">
								<h6> Introduction </h6>
								<p>Under UK law and regulations, organisations are not permitted to send unsolicited communications where these would be classified as marketing and could constitute spam or junk mail. Spam or junk refers to any commercial email sent without the recipient’s permission. Commercial email is defined as any electronic mail message whose primary purpose is to advertise or promote a product or service.</p>
								<p>The Privacy and Electronic Communications Regulations 2003 (PECR) covers the sending of marketing emails. This legislation only permits organisations to send marketing emails to individuals if they have clear and specific consent to do so, except where there is a clearly defined customer relationship, and the communication is compliant with applicable legislation. Since 25 May 2018 this incorporates the General Data Protection Regulation.</p>
							</Col>

							<Col md="5">
								<img src="../../../img/front/gdpr-comp.svg" alt="img" />
							</Col>

							<Col md="12">
								<h6>About us</h6>
								<p>This Policy applies to your use of the website https://www.getshifts.co.uk. The website is owned by NXT Digital Ltd, trading as Get Shifts, and is registered in England and Wales under company registration number 08835058. Our registered office address is Unit 5, Christie Fields Office Park, Christie Way, Manchester M21 7QY.</p>
								<h6>Use of the website</h6>
								<p>Get Shifts is committed to providing services that are compliant with UK data protection legislation and the Privacy and Electronic Communications Regulations (PECR) 2003. Your use of our Platform is governed by our Terms and Conditions which incorporates this Ani-Spam Policy and you must agree to the terms within this policy when using the site, whether as a registered user or in any other capacity.</p>
								<p>Users of the Get Shifts website are only permitted to use the Platform for lawful purposes and must not:</p>
								<ul className="list-markers">
									<li><i className="fa fa-caret-right" aria-hidden="true"></i>
										Send messages to individuals if they do not have valid, specific and informed consent;</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i>
										Add an email address onto any marketing list without the user’s permission;
									</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i>
										Email or message anyone without a lawful basis to do so.
									</li>
								</ul>
								<p>Users are referred to our Acceptable Use Policy for details of acceptable standards when interacting with our Platform.</p>
								<h6>Marketing </h6>
								<p> Where personal data held is used for marketing purposes, organisations must ensure that prior to use, details are checked against any marketing preference databases including, the Telephone Preference Service, the Mail Preference Service, the Email Preference Service, and the Fax Preference Service, in the relevant country </p>
								<p> Organisations must ensure that records of consent to marketing are kept, as required by data protection legislation. All records must be actively maintained, and actions taken to ensure that an individual’s rights in respect of data protection are upheld.</p>

								<h6>Unsubscribing from marketing communications </h6>
								<p>Under UK legislation, organisations sending marketing communications are required to provide a means for unsubscribing from such messages. This is usually done by clicking on the “UNSUBSCRIBE” button at the bottom of an email, however there may be other methods which apply.</p>

								<h6> Reporting spam  </h6>
								<p>We do not actively monitor use of our website and you are therefore responsible for ensuring that all communications you send using our services do not constitute spam. If you are aware of an organisation using our Platform for unlawful marketing communications or violating our Terms and Conditions, you should report the matter to us by email to <a href="mailto:privacy@getshifts.co.uk">privacy@getshifts.co.uk</a></p>

								<p>Users can report an organisation in relation to spam to the Information Commissioner’s Office (ICO) - the UK supervisory authority for data protection matters: <a href="https://ico.org.uk/make-a-complaint/nuisance-calls-and-messages/spam-emails" target="_blank"> https://ico.org.uk/make-a-complaint/ nuisance-calls-and-messages/spam-emails/ </a> </p>

								<h6>Breaches of this policy</h6>

								<p> An organisation’s registration or account may be immediately suspended or terminated if there is a breach of this policy or breach of data protection legislation.  Without prejudice to any of our other rights, we may take such actions as we deem appropriate to deal with any breach of this policy or our Terms and Conditions, including deleting or editing any of your content, suspending your access to the website, prohibiting you from accessing the website, blocking devices using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.</p>

								<h6>Changes to this policy</h6>

								<p>We may revise this Policy from time to time. Revised terms will apply to the use of our website from the date of the publication of the revised terms being available on our website. Please check this page regularly to ensure you are familiar with the current version</p>

							</Col>
						</div>
					</div>
				</section>
				<Footer></Footer>
			</>
		);
	}
}
