import React, { Component } from "react";
import { Link } from "react-router-dom";
import request from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import Header from "./header";
import Footer from "./NewFooter";
import { CheckValue } from "../../views/common/utils"
import Context from "../../api/context";
import phone from "../../images/new-contact/call.svg"
import mail from "../../images/new-contact/mail.svg"
import location from "../../images/new-contact/location.svg"
import map from "../../images/new-contact/map.svg"
export default class NewContactUs extends Component {
	static contextType = Context

	state = {
		value: "",
		label: "",
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		title: "",
		message: "",
		subscribed: false
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevState) {
		if (prevState.facebookLink !== this.context.facebookLink) {
		}
		if (this.context.twitterLink && this.context.twitterLink !== '') {
		}
		if (this.context.instagramLink && this.context.instagramLink !== '') {
		}
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onCheckedChange = e => {
		e.persist();
		this.setState({
			subscribed: e.target.checked
		});
	};

	onCountrySelect = e => {
		e.persist();
		this.setState({
			value: e.target.value,
			label: e.target.value
		});
	};

	ValidateEmail(mail) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true;
		}
		return false;
	}

	gtag_report_conversion(url) {
		var callback = function () {
			if (typeof (url) != 'undefined') {
				window.location = url;
			}
		};
		window.gtag('event', 'conversion', {
			'send_to': 'AW-977323787/_K59CNvxt48DEIuOg9ID',
			'event_callback': callback
		});
		return false;
	};

	onContactSubmit = () => {
		this.gtag_report_conversion('/contact');
		const { first_name, last_name, email } = this.state;
		const data = {
			value: this.state.value,
			label: this.state.label,
			first_name: first_name,
			last_name: last_name,
			email: email,
			phone: this.state.phone,
			title: this.state.title,
			message: this.state.message,
			subscribed: this.state.subscribed
		};

		if (first_name === "") {
			toast.error("Please enter First Name");
		} else if (last_name === "") {
			toast.error("Please enter Surname");
		} else if (email === "") {
			toast.error("Please enter Email");
		} else if (this.ValidateEmail(email) === false) {
			toast.error("You have entered an invalid email address!");
		} else {
			request({
				url: "/users/contactus/save",
				method: "POST",
				data: data
			})
				.then(res => {
					if (res.status === 1) {
						toast.success(res.response);
						setTimeout(() => {
							this.setState({
								value: "",
								label: "",
								first_name: "",
								last_name: "",
								email: "",
								phone: "",
								title: "",
								message: "",
								subscribed: false
							});
							this.componentDidMount();
						}, 500);
					} else if (res.status === 0) {
						toast.error("Error");
					}
				})
				.catch(err => {
					toast.error(err);
				});
		}
	};

	render() {
		const facebookLink = (this.context.facebookLink && this.context.facebookLink !== '') ? this.context.facebookLink : "";
		const twitterLink = (this.context.twitterLink && this.context.twitterLink !== '') ? this.context.twitterLink : "";
		const instagramLink = (this.context.instagramLink && this.context.instagramLink !== '') ? this.context.instagramLink : "";
		return (
			<>
				<Header />
				<ToastContainer position="top-right" autoClose={2500} closeOnClick />
				<div className="contact_us_page mt-5">
					<div className="container">
						<div className="row aic">
							<div className="col-lg-6 col-md-12 col-sm-12">
								<h6>Do you have more questions about Get Shifts?</h6>
								<h5><span>Say Hi!</span> and leave us a message</h5>
								<p>Our team will contact you as soon as possible</p>
								<hr className="hr_tag" />
								<form action="" className="row">
									<div className={this.state.first_name && CheckValue(this.state.first_name) ? "col-lg-6 col-md-6 col-sm-12 cms-contact valueAdded" : "cms-contact col-lg-6 col-md-6 col-sm-12"}>
										<label>First Name</label>
										<input
											type="text"
											autoComplete="off"
											name="first_name"
											value={this.state.first_name}
											onChange={this.onChange}
											required
											placeholder="John"
										/>

									</div>
									<div className={this.state.last_name && CheckValue(this.state.last_name) ? "col-lg-6 col-md-6 col-sm-12 cms-contact valueAdded" : "cms-contact col-lg-6 col-md-6 col-sm-12"}>
										<label>Last Name</label>
										<input
											type="text"
											autoComplete="off"
											name="last_name"
											value={this.state.last_name}
											onChange={this.onChange}
											required
											placeholder="Do|"
										/>

									</div>
									<div className={this.state.email && CheckValue(this.state.email) ? "col-lg-6 col-md-6 col-sm-12 cms-contact valueAdded" : "cms-contact col-lg-6 col-md-6 col-sm-12"}>
										<label> Email Address </label>
										<input
											type="text"
											name="email"
											autoComplete="off"
											value={this.state.email}
											onChange={this.onChange}
											required
											placeholder="John.Doe@gmail.com"
										/>

									</div>
									<div className={this.state.phone && CheckValue(this.state.phone) ? "col-lg-6 col-md-6 col-sm-12 cms-contact valueAdded" : "cms-contact col-lg-6 col-md-6 col-sm-12"}>
										<label> Phone Number </label>
										<input
											type="number"
											name="phone"
											value={this.state.phone}
											onChange={this.onChange}
											required
											placeholder="987 7654 32100"
										/>

									</div>
									<div className={this.state.title && CheckValue(this.state.title) ? "col-12 cms-contact valueAdded" : "cms-contact col-12 "}>
										<label> Subject </label>
										<input
											type="textarea"
											name="title"
											value={this.state.title}
											onChange={this.onChange}
											placeholder="Business Enquiry"
										/>

									</div>
									<div className={this.state.message && CheckValue(this.state.message) ? "col-12 cms-contact valueAdded" : "cms-contact col-12 "}>
										<label> Message </label>
										<textarea
											type="command"
											name="message"
											value={this.state.message}
											onChange={this.onChange}
											placeholder="Message here"
										></textarea>

									</div>
									<div className="terms_condotion">
										<input type="checkbox" onChange={this.onCheckedChange} />
										<p>
											I want to receive updates from Get Shifts for product
											updates, services and events. By clicking submit you agree to
											our <Link to="#"> Terms of Service </Link> and&nbsp;
											<Link to="/privacypolicy"> Privacy Policy </Link>
										</p>

									</div>
									<button type="submit" onClick={this.onContactSubmit}>Submit</button>
								</form>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12">
								<div className="map-lists">
									<img src={map} className="contact-banner" alt="img" />
									<div className="loc_mail_phone">
										<a href="https://maps.app.goo.gl/1MF8wajQdSpwumbK8" target="_blank">
											<img src={location} alt="location" /> <span>Unit 5, Christie Fields Office Park, <br /> Christie Way,  Manchester M21 7QY</span>
										</a>
										<a href="mailto:support@getshifts.co.uk">
											<img src={mail} alt="mail" />
											<span>support@getshifts.co.uk</span>
										</a>
										<a href="tel:0330 808 3871">
											<img src={phone} alt="phone" />
											<span>0330 808 3871</span>
										</a>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>


				<Footer />
			</>
		);
	}
}
