import { planID, tieredPlansID } from "../../api/api";

export const timelist = [
	{ value: 3600, label: "01:00" },
	{ value: 4500, label: "01:15" },
	{ value: "5400", label: "01:30" },
	{ value: "6300", label: "01:45" },
	{ value: "7200", label: "02:00" },
	{ value: "8100", label: "02:15" },
	{ value: "9000", label: "02:30" },
	{ value: "9900", label: "02:45" },
	{ value: "10800", label: "03:00" },
	{ value: "11700", label: "03:15" },
	{ value: "12600", label: "03:30" },
	{ value: "13500", label: "03:45" },
	{ value: "14400", label: "04:00" },
	{ value: "15300", label: "04:15" },
	{ value: "16200", label: "04:30" },
	{ value: "17100", label: "04:45" },
	{ value: "18000", label: "05:00" },
	{ value: "18900", label: "05:15" },
	{ value: "19800", label: "05:30" },
	{ value: "20700", label: "05:45" },
	{ value: "21600", label: "06:00" },
	{ value: "22500", label: "06:15" },
	{ value: "23400", label: "06:30" },
	{ value: "24300", label: "06:45" },
	{ value: "25200", label: "07:00" },
	{ value: "26100", label: "07:15" },
	{ value: "27000", label: "07:30" },
	{ value: "27900", label: "07:45" },
	{ value: "28800", label: "08:00" },
	{ value: "29700", label: "08:15" },
	{ value: "30600", label: "08:30" },
	{ value: "31500", label: "08:45" },
	{ value: "32400", label: "09:00" },
	{ value: "33300", label: "09:15" },
	{ value: "34200", label: "09:30" },
	{ value: "35100", label: "09:45" },
	{ value: "36000", label: "10:00" },
	{ value: "36900", label: "10:15" },
	{ value: "37800", label: "10:30" },
	{ value: "38700", label: "10:45" },
	{ value: "39600", label: "11:00" },
	{ value: "40500", label: "11:15" },
	{ value: "41400", label: "11:30" },
	{ value: "42300", label: "11:45" },
	{ value: "43200", label: "12:00" },
	{ value: "44100", label: "12:15" },
	{ value: "45000", label: "12:30" },
	{ value: "45900", label: "12:45" },
	{ value: "46800", label: "13:00" },
	{ value: "47700", label: "13:15" },
	{ value: "48600", label: "13:30" },
	{ value: "49500", label: "13:45" },
	{ value: "50400", label: "14:00" },
	{ value: "51300", label: "14:15" },
	{ value: "52200", label: "14:30" },
	{ value: "53100", label: "14:45" },
	{ value: "54000", label: "15:00" },
	{ value: "54900", label: "15:15" },
	{ value: "55800", label: "15:30" },
	{ value: "56700", label: "15:45" },
	{ value: "57600", label: "16:00" },
	{ value: "58500", label: "16:15" },
	{ value: "59400", label: "16:30" },
	{ value: "60300", label: "16:45" },
	{ value: "61200", label: "17:00" },
	{ value: "62100", label: "17:15" },
	{ value: "63000", label: "17:30" },
	{ value: "63900", label: "17:45" },
	{ value: "64800", label: "18:00" },
	{ value: "65700", label: "18:15" },
	{ value: "66600", label: "18:30" },
	{ value: "67500", label: "18:45" },
	{ value: "68400", label: "19:00" },
	{ value: "69300", label: "19:15" },
	{ value: "70200", label: "19:30" },
	{ value: "71100", label: "19:45" },
	{ value: "72000", label: "20:00" },
	{ value: "72900", label: "20:15" },
	{ value: "73800", label: "20:30" },
	{ value: "74700", label: "20:45" },
	{ value: "75600", label: "21:00" },
	{ value: "76500", label: "21:15" },
	{ value: "77400", label: "21:30" },
	{ value: "78300", label: "21:45" },
	{ value: "79200", label: "22:00" },
	{ value: "80100", label: "22:15" },
	{ value: "81000", label: "22:30" },
	{ value: "81900", label: "22:45" },
	{ value: "82800", label: "23:00" },
	{ value: "83700", label: "23:15" },
	{ value: "84600", label: "23:30" },
	{ value: "85500", label: "23:45" }
];
export const breaklist = [
	{ value: "0", label: "0" },
	{ value: 300, label: "5" },
	{ value: "600", label: "10" },
	{ value: "900", label: "15" },
	{ value: "1200", label: "20" },
	{ value: "1500", label: "25" },
	{ value: "1800", label: "30" },
	{ value: "2100", label: "35" },
	{ value: "2400", label: "40" },
	{ value: "2700", label: "45" },
	{ value: "3000", label: "50" },
	{ value: "3300", label: "55" },
	{ value: "3600", label: "60" },
	{ value: "3900", label: "65" },
	{ value: "4200", label: "70" },
	{ value: "4500", label: "75" },
	{ value: "4800", label: "80" },
	{ value: "5100", label: "85" },
	{ value: "5400", label: "90" },
	{ value: "5700", label: "95" },
	{ value: "6000", label: "100" },
	{ value: "6300", label: "105" },
	{ value: "6600", label: "110" },
	{ value: "6900", label: "115" },
	{ value: "7200", label: "120" },
	{ value: "7500", label: "125" },
	{ value: "7800", label: "130" },
	{ value: "8100", label: "135" },
	{ value: "8400", label: "140" },
	{ value: "8700", label: "145" },
	{ value: "9000", label: "150" },
	{ value: "9300", label: "155" },
	{ value: "9600", label: "160" },
	{ value: "9900", label: "165" },
	{ value: "10200", label: "170" },
	{ value: "10500", label: "175" },
	{ value: "10800", label: "180" },
	{ value: "11100", label: "185" },
	{ value: "11400", label: "190" },
	{ value: "11700", label: "195" },
	{ value: "12000", label: "200" },
	{ value: "12300", label: "205" },
	{ value: "12600", label: "210" },
	{ value: "12900", label: "215" },
	{ value: "13200", label: "220" },
	{ value: "13500", label: "225" },
	{ value: "13800", label: "230" },
	{ value: "14100", label: "235" },
	{ value: "14400", label: "240" }
];
export const monthlydates = [
	{ value: "1", label: 1 },
	{ value: "2", label: 2 },
	{ value: "3", label: 3 },
	{ value: "4", label: 4 },
	{ value: "5", label: 5 },
	{ value: "6", label: 6 },
	{ value: "7", label: 7 },
	{ value: "8", label: 8 },
	{ value: "9", label: 9 },
	{ value: "10", label: 10 },
	{ value: "11", label: 11 },
	{ value: "12", label: 12 },
	{ value: "13", label: 13 },
	{ value: "14", label: 14 },
	{ value: "15", label: 15 },
	{ value: "16", label: 16 },
	{ value: "17", label: 17 },
	{ value: "18", label: 18 },
	{ value: "19", label: 19 },
	{ value: "20", label: 20 },
	{ value: "21", label: 21 },
	{ value: "22", label: 22 },
	{ value: "23", label: 23 },
	{ value: "24", label: 24 },
	{ value: "25", label: 25 },
	{ value: "26", label: 26 },
	{ value: "27", label: 27 },
	{ value: "28", label: 28 },
	{ value: "29", label: 29 },
	{ value: "30", label: 30 },
	{ value: "31", label: 31 }
];
export const countries = [
	{
		value: "Andorra",
		label: "Andorra"
	},
	{
		value: "United Arab Emirates",
		label: "United Arab Emirates"
	},
	{
		value: "Afghanistan",
		label: "Afghanistan"
	},
	{
		value: "Antigua and Barbuda",
		label: "Antigua and Barbuda"
	},
	{
		value: "Anguilla",
		label: "Anguilla"
	},
	{
		value: "Albania",
		label: "Albania"
	},
	{
		value: "Armenia",
		label: "Armenia"
	},
	{
		value: "Angola",
		label: "Angola"
	},
	{
		value: "Antarctica",
		label: "Antarctica"
	},
	{
		value: "Argentina",
		label: "Argentina"
	},
	{
		value: "American Samoa",
		label: "American Samoa"
	},
	{
		value: "Austria",
		label: "Austria"
	},
	{
		value: "Australia",
		label: "Australia"
	},
	{
		value: "Aruba",
		label: "Aruba"
	},
	{
		value: "Aland Islands",
		label: "Aland Islands"
	},
	{
		value: "Azerbaijan",
		label: "Azerbaijan"
	},
	{
		value: "Bosnia and Herzegovina",
		label: "Bosnia and Herzegovina"
	},
	{
		value: "Barbados",
		label: "Barbados"
	},
	{
		value: "Bangladesh",
		label: "Bangladesh"
	},
	{
		value: "Belgium",
		label: "Belgium"
	},
	{
		value: "Burkina Faso",
		label: "Burkina Faso"
	},
	{
		value: "Bulgaria",
		label: "Bulgaria"
	},
	{
		value: "Bahrain",
		label: "Bahrain"
	},
	{
		value: "Burundi",
		label: "Burundi"
	},
	{
		value: "Benin",
		label: "Benin"
	},
	{
		value: "Saint Barthélemy",
		label: "Saint Barthélemy"
	},
	{
		value: "Bermuda",
		label: "Bermuda"
	},
	{
		value: "Brunei Darussalam",
		label: "Brunei Darussalam"
	},
	{
		value: "Bolivia, Plurinational State of",
		label: "Bolivia, Plurinational State of"
	},
	{
		value: "Bonaire, Sint Eustatius and Saba",
		label: "Bonaire, Sint Eustatius and Saba"
	},
	{
		value: "Brazil",
		label: "Brazil"
	},
	{
		value: "Bahamas",
		label: "Bahamas"
	},
	{
		value: "Bhutan",
		label: "Bhutan"
	},
	{
		value: "Bouvet Island",
		label: "Bouvet Island"
	},
	{
		value: "Botswana",
		label: "Botswana"
	},
	{
		value: "Belarus",
		label: "Belarus"
	},
	{
		value: "Belize",
		label: "Belize"
	},
	{
		value: "Canada",
		label: "Canada"
	},
	{
		value: "Cocos (Keeling) Islands",
		label: "Cocos (Keeling) Islands"
	},
	{
		value: "Congo, the Democratic Republic of the",
		label: "Congo, the Democratic Republic of the"
	},
	{
		value: "Central African Republic",
		label: "Central African Republic"
	},
	{
		value: "Congo",
		label: "Congo"
	},
	{
		value: "Switzerland",
		label: "Switzerland"
	},
	{
		value: 'Cote d"Ivoire',
		label: 'Cote d"Ivoire'
	},
	{
		value: "Cook Islands",
		label: "Cook Islands"
	},
	{
		value: "Chile",
		label: "Chile"
	},
	{
		value: "Cameroon",
		label: "Cameroon"
	},
	{
		value: "China",
		label: "China"
	},
	{
		value: "Colombia",
		label: "Colombia"
	},
	{
		value: "Costa Rica",
		label: "Costa Rica"
	},
	{
		value: "Cuba",
		label: "Cuba"
	},
	{
		value: "Cabo Verde",
		label: "Cabo Verde"
	},
	{
		value: "Curacao",
		label: "Curacao"
	},
	{
		value: "Christmas Island",
		label: "Christmas Island"
	},
	{
		value: "Cyprus",
		label: "Cyprus"
	},
	{
		value: "Czech Republic",
		label: "Czech Republic"
	},
	{
		value: "Germany",
		label: "Germany"
	},
	{
		value: "Djibouti",
		label: "Djibouti"
	},
	{
		value: "Denmark",
		label: "Denmark"
	},
	{
		value: "Dominica",
		label: "Dominica"
	},
	{
		value: "Dominican Republic",
		label: "Dominican Republic"
	},
	{
		value: "Algeria",
		label: "Algeria"
	},
	{
		value: "Ecuador",
		label: "Ecuador"
	},
	{
		value: "Estonia",
		label: "Estonia"
	},
	{
		value: "Egypt",
		label: "Egypt"
	},
	{
		value: "Western Sahara",
		label: "Western Sahara"
	},
	{
		value: "Eritrea",
		label: "Eritrea"
	},
	{
		value: "Spain",
		label: "Spain"
	},
	{
		value: "Ethiopia",
		label: "Ethiopia"
	},
	{
		value: "Finland",
		label: "Finland"
	},
	{
		value: "Fiji",
		label: "Fiji"
	},
	{
		value: "Falkland Islands (Malvinas)",
		label: "Falkland Islands (Malvinas)"
	},
	{
		value: "Micronesia, Federated States of",
		label: "Micronesia, Federated States of"
	},
	{
		value: "Faroe Islands",
		label: "Faroe Islands"
	},
	{
		value: "France",
		label: "France"
	},
	{
		value: "Gabon",
		label: "Gabon"
	},
	{
		value: "United Kingdom of Great Britain and Northern Ireland",
		label: "United Kingdom of Great Britain and Northern Ireland"
	},
	{
		value: "Grenada",
		label: "Grenada"
	},
	{
		value: "Georgia",
		label: "Georgia"
	},
	{
		value: "French Guiana",
		label: "French Guiana"
	},
	{
		value: "Guernsey",
		label: "Guernsey"
	},
	{
		value: "Ghana",
		label: "Ghana"
	},
	{
		value: "Gibraltar",
		label: "Gibraltar"
	},
	{
		value: "Greenland",
		label: "Greenland"
	},
	{
		value: "Gambia",
		label: "Gambia"
	},
	{
		value: "Guinea",
		label: "Guinea"
	},
	{
		value: "Guadeloupe",
		label: "Guadeloupe"
	},
	{
		value: "Equatorial Guinea",
		label: "Equatorial Guinea"
	},
	{
		value: "Greece",
		label: "Greece"
	},
	{
		value: "South Georgia and the South Sandwich Islands",
		label: "South Georgia and the South Sandwich Islands"
	},
	{
		value: "Guatemala",
		label: "Guatemala"
	},
	{
		value: "Guam",
		label: "Guam"
	},
	{
		value: "Guinea-Bissau",
		label: "Guinea-Bissau"
	},
	{
		value: "Guyana",
		label: "Guyana"
	},
	{
		value: "Hong Kong",
		label: "Hong Kong"
	},
	{
		value: "Heard Island and McDonald Islands",
		label: "Heard Island and McDonald Islands"
	},
	{
		value: "Honduras",
		label: "Honduras"
	},
	{
		value: "Croatia",
		label: "Croatia"
	},
	{
		value: "Haiti",
		label: "Haiti"
	},
	{
		value: "Hungary",
		label: "Hungary"
	},
	{
		value: "Indonesia",
		label: "Indonesia"
	},
	{
		value: "Ireland",
		label: "Ireland"
	},
	{
		value: "Israel",
		label: "Israel"
	},
	{
		value: "Isle of Man",
		label: "Isle of Man"
	},
	{
		value: "India",
		label: "India"
	},
	{
		value: "British Indian Ocean Territory",
		label: "British Indian Ocean Territory"
	},
	{
		value: "Iraq",
		label: "Iraq"
	},
	{
		value: "Iran, Islamic Republic of",
		label: "Iran, Islamic Republic of"
	},
	{
		value: "Iceland",
		label: "Iceland"
	},
	{
		value: "Italy",
		label: "Italy"
	},
	{
		value: "Jersey",
		label: "Jersey"
	},
	{
		value: "Jamaica",
		label: "Jamaica"
	},
	{
		value: "Jordan",
		label: "Jordan"
	},
	{
		value: "Japan",
		label: "Japan"
	},
	{
		value: "Kenya",
		label: "Kenya"
	},
	{
		value: "Kyrgyzstan",
		label: "Kyrgyzstan"
	},
	{
		value: "Cambodia",
		label: "Cambodia"
	},
	{
		value: "Kiribati",
		label: "Kiribati"
	},
	{
		value: "Comoros",
		label: "Comoros"
	},
	{
		value: "Saint Kitts and Nevis",
		label: "Saint Kitts and Nevis"
	},
	{
		value: "Korea, Democratic People\"s Republic of",
		label: 'Korea, Democratic People"s Republic of'
	},
	{
		value: "Korea, Republic of",
		label: "Korea, Republic of"
	},
	{
		value: "Kuwait",
		label: "Kuwait"
	},
	{
		value: "Cayman Islands",
		label: "Cayman Islands"
	},
	{
		value: "Kazakhstan",
		label: "Kazakhstan"
	},
	{
		value: 'Lao People"s Democratic Republic',
		label: 'Lao People"s Democratic Republic'
	},
	{
		value: "Lebanon",
		label: "Lebanon"
	},
	{
		value: "Saint Lucia",
		label: "Saint Lucia"
	},
	{
		value: "Liechtenstein",
		label: "Liechtenstein"
	},
	{
		value: "Sri Lanka",
		label: "Sri Lanka"
	},
	{
		value: "Liberia",
		label: "Liberia"
	},
	{
		value: "Lesotho",
		label: "Lesotho"
	},
	{
		value: "Lithuania",
		label: "Lithuania"
	},
	{
		value: "Luxembourg",
		label: "Luxembourg"
	},
	{
		value: "Latvia",
		label: "Latvia"
	},
	{
		value: "Libya",
		label: "Libya"
	},
	{
		value: "Morocco",
		label: "Morocco"
	},
	{
		value: "Monaco",
		label: "Monaco"
	},
	{
		value: "Moldova, Republic of",
		label: "Moldova, Republic of"
	},
	{
		value: "Montenegro",
		label: "Montenegro"
	},
	{
		value: "Saint Martin (French part)",
		label: "Saint Martin (French part)"
	},
	{
		value: "Madagascar",
		label: "Madagascar"
	},
	{
		value: "Marshall Islands",
		label: "Marshall Islands"
	},
	{
		value: "Macedonia, the former Yugoslav Republic of",
		label: "Macedonia, the former Yugoslav Republic of"
	},
	{
		value: "Mali",
		label: "Mali"
	},
	{
		value: "Myanmar",
		label: "Myanmar"
	},
	{
		value: "Mongolia",
		label: "Mongolia"
	},
	{
		value: "Macao",
		label: "Macao"
	},
	{
		value: "Northern Mariana Islands",
		label: "Northern Mariana Islands"
	},
	{
		value: "Martinique",
		label: "Martinique"
	},
	{
		value: "Mauritania",
		label: "Mauritania"
	},
	{
		value: "Montserrat",
		label: "Montserrat"
	},
	{
		value: "Malta",
		label: "Malta"
	},
	{
		value: "Mauritius",
		label: "Mauritius"
	},
	{
		value: "Maldives",
		label: "Maldives"
	},
	{
		value: "Malawi",
		label: "Malawi"
	},
	{
		value: "Mexico",
		label: "Mexico"
	},
	{
		value: "Malaysia",
		label: "Malaysia"
	},
	{
		value: "Mozambique",
		label: "Mozambique"
	},
	{
		value: "Namibia",
		label: "Namibia"
	},
	{
		value: "New Caledonia",
		label: "New Caledonia"
	},
	{
		value: "Niger",
		label: "Niger"
	},
	{
		value: "Norfolk Island",
		label: "Norfolk Island"
	},
	{
		value: "Nigeria",
		label: "Nigeria"
	},
	{
		value: "Nicaragua",
		label: "Nicaragua"
	},
	{
		value: "Netherlands",
		label: "Netherlands"
	},
	{
		value: "Norway",
		label: "Norway"
	},
	{
		value: "Nepal",
		label: "Nepal"
	},
	{
		value: "Nauru",
		label: "Nauru"
	},
	{
		value: "Niue",
		label: "Niue"
	},
	{
		value: "New Zealand",
		label: "New Zealand"
	},
	{
		value: "Oman",
		label: "Oman"
	},
	{
		value: "Panama",
		label: "Panama"
	},
	{
		value: "Peru",
		label: "Peru"
	},
	{
		value: "French Polynesia",
		label: "French Polynesia"
	},
	{
		value: "Papua New Guinea",
		label: "Papua New Guinea"
	},
	{
		value: "Philippines",
		label: "Philippines"
	},
	{
		value: "Pakistan",
		label: "Pakistan"
	},
	{
		value: "Poland",
		label: "Poland"
	},
	{
		value: "Saint Pierre and Miquelon",
		label: "Saint Pierre and Miquelon"
	},
	{
		value: "Pitcairn",
		label: "Pitcairn"
	},
	{
		value: "Puerto Rico",
		label: "Puerto Rico"
	},
	{
		value: "Palestine, State of",
		label: "Palestine, State of"
	},
	{
		value: "Portugal",
		label: "Portugal"
	},
	{
		value: "Palau",
		label: "Palau"
	},
	{
		value: "Paraguay",
		label: "Paraguay"
	},
	{
		value: "Qatar",
		label: "Qatar"
	},
	{
		value: "Reunion",
		label: "Reunion"
	},
	{
		value: "Romania",
		label: "Romania"
	},
	{
		value: "Serbia",
		label: "Serbia"
	},
	{
		value: "Russian Federation",
		label: "Russian Federation"
	},
	{
		value: "Rwanda",
		label: "Rwanda"
	},
	{
		value: "Saudi Arabia",
		label: "Saudi Arabia"
	},
	{
		value: "Solomon Islands",
		label: "Solomon Islands"
	},
	{
		value: "Seychelles",
		label: "Seychelles"
	},
	{
		value: "Sudan",
		label: "Sudan"
	},
	{
		value: "Sweden",
		label: "Sweden"
	},
	{
		value: "Singapore",
		label: "Singapore"
	},
	{
		value: "Saint Helena, Ascension and Tristan da Cunha",
		label: "Saint Helena, Ascension and Tristan da Cunha"
	},
	{
		value: "Slovenia",
		label: "Slovenia"
	},
	{
		value: "Svalbard and Jan Mayen",
		label: "Svalbard and Jan Mayen"
	},
	{
		value: "Slovakia",
		label: "Slovakia"
	},
	{
		value: "Sierra Leone",
		label: "Sierra Leone"
	},
	{
		value: "San Marino",
		label: "San Marino"
	},
	{
		value: "Senegal",
		label: "Senegal"
	},
	{
		value: "Somalia",
		label: "Somalia"
	},
	{
		value: "Surilabel",
		label: "Surilabel"
	},
	{
		value: "South Sudan",
		label: "South Sudan"
	},
	{
		value: "Sao Tome and Principe",
		label: "Sao Tome and Principe"
	},
	{
		value: "El Salvador",
		label: "El Salvador"
	},
	{
		value: "Sint Maarten (Dutch part)",
		label: "Sint Maarten (Dutch part)"
	},
	{
		value: "Syrian Arab Republic",
		label: "Syrian Arab Republic"
	},
	{
		value: "Swaziland",
		label: "Swaziland"
	},
	{
		value: "Turks and Caicos Islands",
		label: "Turks and Caicos Islands"
	},
	{
		value: "Chad",
		label: "Chad"
	},
	{
		value: "French Southern Territories",
		label: "French Southern Territories"
	},
	{
		value: "Togo",
		label: "Togo"
	},
	{
		value: "Thailand",
		label: "Thailand"
	},
	{
		value: "Tajikistan",
		label: "Tajikistan"
	},
	{
		value: "Tokelau",
		label: "Tokelau"
	},
	{
		value: "Timor-Leste",
		label: "Timor-Leste"
	},
	{
		value: "Turkmenistan",
		label: "Turkmenistan"
	},
	{
		value: "Tunisia",
		label: "Tunisia"
	},
	{
		value: "Tonga",
		label: "Tonga"
	},
	{
		value: "Turkey",
		label: "Turkey"
	},
	{
		value: "Trinidad and Tobago",
		label: "Trinidad and Tobago"
	},
	{
		value: "Tuvalu",
		label: "Tuvalu"
	},
	{
		value: "Taiwan",
		label: "Taiwan"
	},
	{
		value: "Tanzania, United Republic of",
		label: "Tanzania, United Republic of"
	},
	{
		value: "Ukraine",
		label: "Ukraine"
	},
	{
		value: "Uganda",
		label: "Uganda"
	},
	{
		value: "United States Minor Outlying Islands",
		label: "United States Minor Outlying Islands"
	},
	{
		value: "United States of America",
		label: "United States of America"
	},
	{
		value: "Uruguay",
		label: "Uruguay"
	},
	{
		value: "Uzbekistan",
		label: "Uzbekistan"
	},
	{
		value: "Holy See",
		label: "Holy See"
	},
	{
		value: "Saint Vincent and the Grenadines",
		label: "Saint Vincent and the Grenadines"
	},
	{
		value: "Venezuela, Bolivarian Republic of",
		label: "Venezuela, Bolivarian Republic of"
	},
	{
		value: "Virgin Islands, British",
		label: "Virgin Islands, British"
	},
	{
		value: "Virgin Islands, U.S.",
		label: "Virgin Islands, U.S."
	},
	{
		value: "Viet Nam",
		label: "Viet Nam"
	},
	{
		value: "Vanuatu",
		label: "Vanuatu"
	},
	{
		value: "Wallis and Futuna",
		label: "Wallis and Futuna"
	},
	{
		value: "Samoa",
		label: "Samoa"
	},
	{
		value: "Yemen",
		label: "Yemen"
	},
	{
		value: "Mayotte",
		label: "Mayotte"
	},
	{
		value: "South Africa",
		label: "South Africa"
	},
	{
		value: "Zambia",
		label: "Zambia"
	},
	{
		value: "Zimbabwe",
		label: "Zimbabwe"
	}
];

export const CheckValue = (e = "") => {
	if (String(e).length > 0 && String(e) !== String(undefined) && String(e) !== String(null)) {
		return true;
	} else {
		return false;
	}
};

export let validateEmail = email => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};








/* Tierd Pricing comman data to implement the pricing details for plans EX:Basic,Pro and its module
   These data are mapped in this API `/site/tierd_pricing_list` in the `/pricing` Page 
*/


export const modules = [
	{
		module_name:"Recruitment Tracking",
		moduleId:planID.recruitmentmoduleId,
		sub_modules:[]
	},
	{
		module_name:"Communication",
		moduleId:planID.communicationmoduleId,
		sub_modules:[]
	},
	{
		module_name:"Shift Managment",
		moduleId:planID.shiftmoduleId,
		sub_modules:[]
	},
	{
		module_name:"Employee app",
		moduleId:"66faa648bf570200a4f8d30c",
		sub_modules:[]
	},
	{
		module_name:"Digital Timesheets",
		moduleId:planID.timesheetmoduleId,
		sub_modules:[]
	},
	{
		module_name:"HR & Compliance Management",
		moduleId:planID.compliancemoduleId,
		sub_modules:[]
	},
	{
		module_name:"Employee Management",
		moduleId:"66faa666bf570200a4f8d30f",
		sub_modules:[]
	},
	{
		module_name:"Emp Compliance Management",
		moduleId:"66faa672bf570200a4f8d310",
		sub_modules:[]
	},	
	{
		module_name:"Invoicing",
		moduleId:planID.invoicemoduleId,
		sub_modules:[]
	},
	{
		module_name:"Incident Management",
		moduleId:"66faa690bf570200a4f8d312",
		sub_modules:[]
	},


]


export const tierPricingSubPlans = [
	{
		planId:tieredPlansID.basic,
		sub_modules:modules.filter((item)=>[planID.recruitmentmoduleId].includes(item.moduleId) )
	},
	{
		planId:tieredPlansID.starter,
		sub_modules:modules.filter((item)=>[planID.recruitmentmoduleId,planID.communicationmoduleId].includes(item.moduleId) )
	},
	{
		planId:tieredPlansID.pro,
		sub_modules:modules.filter((item)=>![planID.invoicemoduleId].includes(item.moduleId) )
	},
	{
		planId:tieredPlansID.premium,
		sub_modules:modules
	},
]






const recruitmentData = {
   title: 'Recruitment Tracking',
   features: [
      { name: 'Candidates', plans: ['10 /month', 'Unlimited', 'Unlimited', 'Unlimited'] },
      { name: 'Application Form', plans: ['1', 'Unlimited', 'Unlimited', 'Unlimited'] },
      { name: 'Job Board', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Automated Compliance', plans: ['tick', 'tick', 'tick', 'tick'] },
      { name: 'Referencing', plans: ['tick', 'tick', 'tick', 'tick'] },
      { name: 'Interview Management', plans: ['cross', 'tick', 'tick', 'tick'] },
      { name: 'Onboarding', plans: ['cross', 'cross', 'tick', 'cross'] },
      { name: 'Document Signing', plans: ['cross', 'cross', 'tick', 'tick'] },
   ],
   sub_title: false,
};

const communicationData = {
   title: 'Communication',
   features: [
      { name: 'Email', plans: ['cross', 'tick', 'tick', 'tick'] },
      { name: 'SMS', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Chat', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'System Emails', plans: ['cross', 'tick', 'tick', 'tick'] },
      { name: 'Email Templates', plans: ['cross', 'cross', 'tick', 'tick'] },
   ],
   sub_title: true,
};

const shiftManagementData = {
   title: 'Shift Management',
   features: [
      { name: 'Shift broadcast SMS, Email and Push', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Shift Reminders SMS/Email and App Push', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Multiple Day Scheduling', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Multiple Employee Scheduling', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Skill Filtering', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Attending Employee Profile Sharing', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Employee Availability Management', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Custom Shift Pay Rate', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Employee Clock and Clock Out', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Clock in Geofencing', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Smart Shift Assigner', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Client Shift Portal', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Hourly & Day Rates', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Employee Shift Swap', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Shift Autostart', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Work Location Map & Directions', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Late Booking Charges', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Employee Location Restriction', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Employee Hours Limiting', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Automated Shift Reminders', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Work Location Scheduling Patterns', plans: ['cross', 'cross', 'tick', 'tick'] },
   ],
   sub_title:false
};

const employeeAppData = {
   title: 'Employee App',
   features: [
      { name: 'Accept & Deny Shifts', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Pick up open shifts', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Employee Clock and Clock out', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Shift swap', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Chat', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Earnings Reporting', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Availability Management', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Time off management', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Profile Management', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Shift Creation', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Reminders and notifications', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Clockin geofencing', plans: ['cross', 'cross', 'tick', 'tick'] },
   ],
   sub_title:true
};

const digitalTimesheetsData = {
   title: 'Digital Timesheets',
   features: [
      { name: 'Automated Timesheets', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Automated Approval', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Digital Signature', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Manual Timesheets', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Employee App timesheet management', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Pay rates', plans: ['cross', 'cross', 'tick', 'tick'] },
      { name: 'Overtime calculation', plans: ['cross', 'cross', 'tick', 'tick'] },
   ],
   sub_title:true
};

const hrComplianceData = [
	{
		title: 'HR & Compliance Management',
		features:[],
		sub_title:false
	},
   {
      title: 'Employee Management',
      features: [
         { name: 'HR data management', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Customisable Employment Types', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Employee Profile', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Basic details', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Automated compliance', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Referencing', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Full profile sharing', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Document Management', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Document Templates', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Employee Archive', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Employee Location restriction', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Employee Hours Limiting', plans: ['cross', 'cross', 'tick', 'tick'] },
      ],
	  sub_title:true
   },
   {
      title: 'Employee Compliance Management',
      features: [
         { name: 'Right to work tracking', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Automated compliance', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Employee restriction', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Document Tracking', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Training Tracking', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Training Matrix Download', plans: ['cross', 'cross', 'tick', 'tick'] },
      ],
	  sub_title:true
   },
   {
      title: 'Invoicing',
      features: [
         { name: 'Automated invoicing', plans: ['cross', 'cross', 'cross', 'tick'] },
         { name: 'Manual invoicing', plans: ['cross', 'cross', 'cross', 'tick'] },
         { name: 'Client statements', plans: ['cross', 'cross', 'cross', 'tick'] },
         { name: 'Shift Purchase orders', plans: ['cross', 'cross', 'cross', 'tick'] },
         { name: 'Margin tracking', plans: ['cross', 'cross', 'cross', 'tick'] },
      ],
	  sub_title:true
   },
   {
      title: 'Incident Management',
      features: [
         { name: 'Employee app incident reporting', plans: ['cross', 'cross', 'tick', 'tick'] },
         { name: 'Incident tracking', plans: ['cross', 'cross', 'tick', 'tick'] },
      ],
	  sub_title:true
   },


];

export const pricingfeatureData = [recruitmentData, communicationData, shiftManagementData, employeeAppData, digitalTimesheetsData ,...hrComplianceData];


export const sleep = ms => new Promise(r => setTimeout(r, ms));