import React from 'react'
import { Modal, ModalBody } from "reactstrap";
import './Iframe.css'
function Iframe({openIframeModal,handleOnClose,videoLink}) {
  return (
    <Modal isOpen={openIframeModal} toggle={handleOnClose}  className='compare-sub-notify1 video-iframe' centered >
    <ModalBody>
    <div className='cm-sec1'>
    <span className='close-btn1' onClick={() => handleOnClose()}>&times;</span>
    <div className='cm-center1'>
    <iframe id="iframe" src={videoLink} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture ; autoplay;" fullscreen allowFullScreen="true" webkitallowFullScreen="true" mozallowFullScreen="true"></iframe>
    </div>
    </div>
    </ModalBody>
</Modal>
  )
}

export default Iframe;