import React, { useEffect, useState } from 'react'
import request, { client, NodeURL } from '../../api/api'
import Header from './header'
import Footer from './NewFooter'
import { Box, Typography, Button, Grid, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CheckValue } from '../common/utils'
import playButtonIcon from '../../images/appdemo/play_button.svg'
import pdfIcon from '../../images/appdemo/pdf.svg'
import logo1 from '../../images/appdemo/logo1.png'
import logo2 from '../../images/appdemo/logo2.png'
import activity_feed_icon from '../../images/appdemo/content_img/activity_feed.png'
import app_settings_icon from '../../images/appdemo/content_img/app_settings.png'
import availability_settings_icon from '../../images/appdemo/content_img/availability_settings.png'
import availability_settings1_icon from '../../images/appdemo/content_img/availability_settings1.png'
import chat_function_icon from '../../images/appdemo/content_img/chat_function.png'
import compliance_documentation_icon from '../../images/appdemo/content_img/compliance_documentation.png'
import compliance_documentation1_icon from '../../images/appdemo/content_img/compliance_documentation1.png'
import compliance_Earnings_icon from '../../images/appdemo/content_img/compliance_Earnings.png'
import comprehensive_shift_information_icon from '../../images/appdemo/content_img/comprehensive_shift_information.png'
import contact_support_icon from '../../images/appdemo/content_img/contact_support.png'
import document_Upload_icon from '../../images/appdemo/content_img/document_Upload.png'
import earnings_icon from '../../images/appdemo/content_img/earnings.png'
import earnings1_icon from '../../images/appdemo/content_img/earnings1.png'
import frequently_asked_questions_icon from '../../images/appdemo/content_img/frequently_asked_questions.png'
import home_screen_icon from '../../images/appdemo/content_img/home_screen.png'
import incident_Reporting_icon from '../../images/appdemo/content_img/incident_Reporting.png'
import login_logo_icon from '../../images/appdemo/content_img/login_logo.png'
import password_entry_icon from '../../images/appdemo/content_img/password_entry.png'
import personal_Information_icon from '../../images/appdemo/content_img/personal_Information.png'
import popular_Questions_icon from '../../images/appdemo/content_img/popular_Questions.png'
import profile_selection_icon from '../../images/appdemo/content_img/profile_selection.png'
import schedule_Overview_icon from '../../images/appdemo/content_img/schedule_Overview.png'
import settings_Profile_Management_icon from '../../images/appdemo/content_img/settings_Profile_Management.png'
import shift_acceptance_icon from '../../images/appdemo/content_img/shift_acceptance.png'
import shift_actions_icon from '../../images/appdemo/content_img/shift_actions.png'
import shift_actions1_icon from '../../images/appdemo/content_img/shift_actions1.png'
import timesheet_Details_icon from '../../images/appdemo/content_img/timesheet_Details.png'
import timesheet_Details1_icon from '../../images/appdemo/content_img/timesheet_Details1.png'
import timesheet_Details2_icon from '../../images/appdemo/content_img/timesheet_Details2.png'
import timesheet_submission_icon from '../../images/appdemo/content_img/timesheet_submission.png'
import timesheet_submission1_icon from '../../images/appdemo/content_img/timesheet_submission1.png'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
   container: {
      padding: '30px 0px',
      borderRadius: '12px',
      textAlign: 'center',
      maxWidth: '1100px',
      margin: 'auto'
   },
   phaseOneContainer: {
      display: 'flex'
   },
   heading1: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      textAlign: 'left',
      color: '#5F4B95'
   },
   heading2: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      textAlign: 'left',
      color: '#1A202C'
   },
   text: {
      marginBottom: theme.spacing(2),
      color: '#666',
      fontSize: '14px',
      textAlign: 'left',
      color: '#1A202C'
   },
   buttonsContainer: {
      marginBottom: theme.spacing(5)
   },
   pdfIcon: {
      width: '24px',
      height: '24px',
      marginRight: theme.spacing(1)
   },
   pdfButton: {
      backgroundColor: '#FF5151',
      borderRadius: '8px',
      '&:hover': {
         backgroundColor: '#FF5151'
      }
   },
   playButtonIcon: {
      width: '24px',
      height: '24px',
      marginRight: theme.spacing(1)
   },
   playButton: {
      borderColor: '#3B82F6',
      borderRadius: '8px',
      color: '#3B82F6',
      '&:hover': {
         borderColor: '#3B82F6'
      }
   },
   imageContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(5)
      // paddingLeft: '20px'
   },
   image: {
      maxWidth: '100%',
      height: '400px',
      borderRadius: '12px'
   },
   image1: {
      maxWidth: '100%',
      height: '400px',
      borderRadius: '12px'
   },
   contentContainer: {
      // display: 'flex',
      // justifyContent: 'space-between',
      // alignItems: 'flex-start',
      marginTop: theme.spacing(5),
      width: '610px'
   },
   mainContainer: {
      width: '65%'
   },
   sidebar: {
      padding: theme.spacing(2),
      backgroundColor: '#f5f5ff',
      border: '1px solid #FFFFFF',
      borderRadius: '24px',
      width: '25%'
   },
   mainContent_phase2: {
      flexGrow: 1,
      // padding: theme.spacing(2),
      width: '75%',
      textAlign: 'left',
      paddingTop: '60px'
   },
   sectionTitle: {
      fontWeight: 'bold',
      fontSize: '18px',
      // marginBottom: '10px',
      color: '#1A202C'
   },
   sectionActiveTitle: {
      fontWeight: 'bold',
      fontSize: '18px',
      // marginBottom: '10px',
      color: '#5F4B95'
   },
   instructions: {
      fontSize: '14px',
      marginBottom: '10px',
      lineHeight: '1.5',
      color: '#333'
   },
   listItem: {
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
      borderRadius: '8px',
      '&:hover': {
         backgroundColor: '#e0e0e0'
      },
      cursor: 'pointer',
      backgroundColor: '#FFFFFF',
      color: '#1A202C'
   },
   activeListItem: {
      backgroundColor: '#5F4B95',
      color: '#FFFFFF'
   },
   videoButton: {
      backgroundColor: '#6200ea',
      color: '#fff',
      '&:hover': {
         backgroundColor: '#3700b3'
      }
   },
   phaseTwoContainer: {
      display: 'flex',
      gap: '10px',
      marginTop: '20px'
   },
   accordionContent: {
      display: 'flex'
   },
   instructionsContainer: {
      display: 'block'
   },
   accordionContainer: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center'
   },
   accordionTextDiv: {
      width: '75%',
      '&.content_2img': {
         width: '53% !important'
      }
   },
   accordion: {
      border: 'none',
      boxShadow: 'none',
      borderBottom: '1px solid #ccc'
   },
   expandedSummary: {
      border: 'none',
      boxShadow: 'none',
      borderBottom: '1px solid #5F4B95'
   },
   accordionImgDiv: {
      display: 'flex',
      gap: '10px',
      width: '25%',
      '& img': {
         width: '100%',
         // height: 'auto',
         // height: '350px',
         // marginTop: '50px',
         boxShadow: '0 0 4px 0 #8061b3',
         borderRadius: '8px'
      }
   }
}))

let initialImageObj = {
   loginProfileSelection: login_logo_icon,
   mainInterface: home_screen_icon,
   shiftDetails: comprehensive_shift_information_icon,
   scheduleOverview: schedule_Overview_icon,
   timesheetManagement: timesheet_submission_icon,
   profileManagement: app_settings_icon,
   activityFeedChat: activity_feed_icon,
   supportFAQs: popular_Questions_icon,
   settingsMenu: earnings_icon
}

let initialImageObj1 = {
   // loginProfileSelection: login_logo_icon,
   // mainInterface: home_screen_icon,
   // shiftDetails: comprehensive_shift_information_icon,
   // scheduleOverview: schedule_Overview_icon,
   timesheetManagement: timesheet_submission1_icon,
   profileManagement: personal_Information_icon,
   // activityFeedChat: activity_feed_icon,
   // supportFAQs: popular_Questions_icon,
   settingsMenu: earnings1_icon
}

const NewAppDemo = () => {
   const classes = useStyles()
   const domhistory = useHistory()

   const [activeTab, setActiveTab] = useState('loginProfileSelection')
   const [expanded, setExpanded] = useState('panel1')
   const [imgSrc, setImgSrc] = useState(login_logo_icon)
   const [imgSrc1, setImgSrc1] = useState(null);
   

   const handleTabClick = tab => {
      setImgSrc(initialImageObj[tab])
      setImgSrc1(initialImageObj1[tab])
      setActiveTab(tab)
      setExpanded('panel1')
   }

   const handleAccordionChange = (panel, imgUrl, imgUrlNext) => isExpanded => {
      setImgSrc(imgUrl)
      setImgSrc1(imgUrlNext)
      setExpanded(isExpanded ? panel : false)
   }

   const handleDownloadPdf = () => {
      const pdfUrl = `${NodeURL}/uploads/app_documentation.pdf`
      window.open(pdfUrl, '_blank')
   }

   return (
      <div>
         <Header />
         <div className="viewdemo">
            <Box className={classes.container}>
               <Box className={classes.phaseOneContainer}>
                  <Box className={classes.mainContainer}>
                     <Typography variant="h4" component="h1" gutterBottom className={classes.heading1}>
                        Get Shifts
                     </Typography>
                     <Typography variant="h3" component="h2" className={classes.heading2}>
                        App Documentation
                     </Typography>
                     <Box className={classes.contentContainer}>
                        <Box className={classes.textContainer}>
                           <Typography variant="body1" className={classes.text}>
                              The Get Shifts app is a robust workforce management tool designed to enhance shift scheduling, timesheet tracking, and
                              communication for both employees and employers. This comprehensive app simplifies managing work schedules, tracking earnings, and
                              ensuring compliance with industry regulations through an intuitive interface and engaging features.
                           </Typography>
                           <Typography variant="body1" className={classes.text}>
                              This documentation outlines the app's functionalities, including features that allow employees to pick up open shifts, request
                              cancellations, swap shifts, and record precise work hours through a clock-in and clock-out system. Additionally, the app includes
                              availability management tools and automated shift and compliance reminders. This guide will help users navigate the app's features
                              efficiently and maximize their work experience.
                           </Typography>
                           <Grid container spacing={2} className={classes.buttonsContainer}>
                              <Grid item>
                                 <Button variant="contained" color="primary" className={classes.pdfButton} onClick={handleDownloadPdf}>
                                    <img src={pdfIcon} className={classes.pdfIcon} alt="pdfIcon" /> Download PDF
                                 </Button>
                              </Grid>
                              <Grid item>
                                 <Button variant="outlined" color="secondary" className={classes.playButton} onClick={() =>domhistory.push("/helpcentre")}>
                                    <img src={playButtonIcon} className={classes.playButtonIcon} alt="playButtonIcon" /> Play Video
                                 </Button>
                              </Grid>
                           </Grid>
                        </Box>
                     </Box>
                  </Box>
                  <Box className={classes.imageContainer}>
                     <img src={logo1} alt="App Screenshot" className={classes.image} />
                  </Box>
               </Box>
               <Box className={classes.phaseTwoContainer}>
                  <Box className={classes.sidebar}>
                     <List>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'loginProfileSelection' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('loginProfileSelection')}
                        >
                           <ListItemText primary="Login and Profile Selection" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'mainInterface' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('mainInterface')}
                        >
                           <ListItemText primary="Main Interface" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'shiftDetails' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('shiftDetails')}
                        >
                           <ListItemText primary="Shift Details" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'scheduleOverview' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('scheduleOverview')}
                        >
                           <ListItemText primary="Schedule Overview" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'timesheetManagement' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('timesheetManagement')}
                        >
                           <ListItemText primary="Timesheet Management" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'profileManagement' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('profileManagement')}
                        >
                           <ListItemText primary="Settings & Profile Management" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'activityFeedChat' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('activityFeedChat')}
                        >
                           <ListItemText primary="Activity Feed and Chat" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'supportFAQs' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('supportFAQs')}
                        >
                           <ListItemText primary="Support and FAQs" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'settingsMenu' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('settingsMenu')}
                        >
                           <ListItemText primary="Settings Menu" />
                        </ListItem>
                     </List>
                  </Box>

                  <Box className={classes.mainContent_phase2}>
                     {activeTab === 'loginProfileSelection' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={`${classes.accordion} ${expanded === 'panel1' ? classes.expandedSummary : ''}`}
                                 onChange={handleAccordionChange('panel1', login_logo_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel1' ? classes.sectionActiveTitle : classes.sectionTitle}>Logging In</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>1. Launch the Get Shifts app on your device.</Typography>
                                       <Typography className={classes.instructions}>
                                          2. On the login screen, enter your registered email address in the provided field.
                                       </Typography>
                                       <Typography className={classes.instructions}>3. Tap the purple arrow button to proceed to the next step.</Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 className={`${classes.accordion} ${expanded === 'panel2' ? classes.expandedSummary : ''}`}
                                 onChange={handleAccordionChange('panel2', profile_selection_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel2' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Profile Selection
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. After entering your email, verify that the pre-filled email address is correct.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Tap the "Select Profile" field to reveal a dropdown list of available profiles associated with your email.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Choose the appropriate profile from the list, such as "Versatile Infotech" or "Astra Care," corresponding to your
                                          specific workplace or role.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. Tap the purple arrow button to proceed to the final login step.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel3'}
                                 className={`${classes.accordion} ${expanded === 'panel3' ? classes.expandedSummary : ''}`}
                                 onChange={handleAccordionChange('panel3', password_entry_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel3' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Password Entry
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. On the final login screen, enter your password in the provided field.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Double-check your password for accuracy, then tap the "Sign in" button to access your account.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. If you have forgotten your password, tap the "Forgot Password?" link below the password field to initiate a
                                          password reset process. Follow the prompts to securely reset your password and regain access to your account.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                                 {CheckValue(imgSrc1) && <img src={imgSrc1} alt="App Screenshot" className={classes.image1} />}
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'mainInterface' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1', home_screen_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel1' ? classes.sectionActiveTitle : classes.sectionTitle}>Home Screen</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. Upon logging in, you will be greeted with a personalized message that changes based on the time of day, such as
                                          "Good Evening [Your Name]."
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The current date is prominently displayed below the greeting, helping you stay oriented with your schedule.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. The screen is divided into two main sections: "Upcoming Shifts" and "Open Shifts."
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. In the "Upcoming Shifts" section, you can view your scheduled shifts, each displaying relevant details such as
                                          date, time, duration, role, location, and compensation.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. The "Open Shifts" section lists available shifts that you can choose to pick up, also providing detailed
                                          information about each shift.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          6. Use the bottom navigation menu to access different app features, such as viewing timesheets or accessing additional
                                          options.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 className={expanded === 'panel2' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel2', compliance_Earnings_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel2' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Compliance and Earnings
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. In the "Compliance" section, you can check the status of your compliance documents, such as identity verification,
                                          ensuring that all necessary paperwork is up to date.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The "Earnings" section provides a summary of your earnings over selectable time periods, allowing you to track your
                                          income and financial records easily.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel3'}
                                 className={expanded === 'panel3' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel3', shift_acceptance_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel3' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Shift Acceptance
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. When you have shift offers that require confirmation, they will appear under the "Booked" section.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Review the details of the offered shift, including the date, time, duration, role, location, and compensation.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. You will have the option to accept or decline the shift within a given timeframe, typically indicated by a
                                          countdown timer.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. To accept the shift, tap the green "Accept" button. To decline, tap the pink "Decline" button.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                                 {CheckValue(imgSrc1) && <img src={imgSrc1} alt="App Screenshot" className={classes.image1} />}
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'shiftDetails' && (
                        <div className={classes.accordionContainer}>
                           <div className={expanded === 'panel2' ? `${classes.accordionTextDiv}  content_2img` : classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1', comprehensive_shift_information_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel1' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Comprehensive Shift Information
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. Tapping on a specific shift from the main interface or schedule overview will take you to the detailed shift
                                          information screen.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Here, you can view comprehensive details about the booked shift, including the exact date, start and end times,
                                          role, location, and pay rate.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Additional features, such as break times and durations, are also displayed for your reference.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. If there are any specific notes or instructions related to the shift, they will be visible in the "Notes" section
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. The "Show Map" option allows you to view the location of the shift on a map, helping you navigate to the work site
                                          easily.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 className={expanded === 'panel2' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel2', shift_actions_icon, shift_actions1_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel2' ? classes.sectionActiveTitle : classes.sectionTitle}>Shift Actions</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. Depending on the status and requirements of the shift, various actions may be available.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. For shifts that require location verification, you will see a "Start Shift" button that becomes active when you are
                                          at the designated work location and within the scheduled shift time.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. If you need to swap a shift with another employee, tap the "Swap Shift" button to initiate the process. You can
                                          select the employee you wish to swap with and send a request.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. In case you are unable to work a scheduled shift, you may have the option to cancel the shift. Tap the "Cancel
                                          Shift" button and follow the prompts to complete the cancellation process.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                                 {CheckValue(imgSrc1) && <img src={imgSrc1} alt="App Screenshot" className={classes.image1} />}
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'scheduleOverview' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1', schedule_Overview_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel1' ? classes.sectionActiveTitle : classes.sectionTitle}>Calendar View</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. The "Schedule" screen provides a calendar view of your shifts, allowing you to easily navigate through different
                                          months and view your shift schedule at a glance.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Booked shifts are highlighted in green on the calendar, while available shifts that you can pick up are marked in
                                          purple.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Tap on any specific day to view the detailed shift information for that date.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 className={expanded === 'panel2' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel2')}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel2' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Detailed Shift Summary
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. Below the calendar, you will find a summary of your shifts, organized by date.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Each shift entry includes the date, start and end times, role, location, and compensation.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Tap on a shift to expand and view more details or take actions, such as confirming or canceling the shift if
                                          needed.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                                 {CheckValue(imgSrc1) && <img src={imgSrc1} alt="App Screenshot" className={classes.image1} />}
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'timesheetManagement' && (
                        <div className={classes.accordionContainer}>
                           <div className={expanded === 'panel1' || expanded === 'panel2' ? `${classes.accordionTextDiv}  content_2img` : classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1', timesheet_submission_icon, timesheet_submission1_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel1' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Timesheet Submission
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. The "Timesheets" screen allows you to view and manage your timesheets for each pay period.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Each timesheet entry displays the date range, work location, number of shifts worked, and the total amount earned
                                          during that period.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. To submit a timesheet for approval, tap on the relevant timesheet entry and follow the prompts to complete the
                                          submission process.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. Some timesheets may require a signature from a supervisor or manager. In such cases, you will need to follow the
                                          necessary steps to obtain the required signatures.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 className={expanded === 'panel2' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel2', timesheet_Details_icon, timesheet_Details2_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel2' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Timesheet Details
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. Tapping on a specific timesheet entry will take you to a detailed view of the shifts worked within that period.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Here, you can access a breakdown of each shift, including the exact start and end times, break durations, and total
                                          hours worked.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. If there are any discrepancies or issues with the recorded hours, you can use the "View Details" option to
                                          investigate further or raise concerns.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. The "Notes" section allows you to add any relevant notes or comments to provide clarification or additional
                                          information about the shifts worked.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel3'}
                                 className={expanded === 'panel3' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel3', incident_Reporting_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel3' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Incident Reporting
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. In case of any workplace incidents, such as harassment or safety concerns, you can use the "Reports" feature to
                                          file an incident report directly through the app
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Select the appropriate shift date and number, and choose the type of incident from the dropdown menu.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Provide a detailed description of the incident in the text box provided, or use the voice note feature to record
                                          your description verbally.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. If necessary, you can also attach supporting documents or evidence to strengthen your report.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. Once you have filled in all the required information, tap the "Submit" button to send the report for further action
                                          and investigation.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel4'}
                                 className={expanded === 'panel4' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel4', document_Upload_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel4' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Document Upload
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. When submitting timesheets, you may be required to provide additional supporting documents or information.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The "Upload" feature allows you to attach relevant files, such as work completion forms or authorization documents,
                                          to your timesheet submissions.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Tap the "Upload" button and select the appropriate files from your device, ensuring they meet the specified format
                                          and size requirements.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. You can also view and manage previously uploaded documents, deleting them if necessary to keep your records up to
                                          date.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                                 {CheckValue(imgSrc1) && <img src={imgSrc1} alt="App Screenshot" className={classes.image1} />}
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'profileManagement' && (
                        <div className={classes.accordionContainer}>
                           <div className={`${classes.accordionTextDiv}  content_2img`}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1', app_settings_icon, personal_Information_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel1' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Personal Information
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. The "Profile" screen serves as a central hub for managing your personal information within the app.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Tap on "View Profile" to access your current profile details, including your name, contact information, and profile
                                          picture.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. If any changes are needed, tap on "Edit Profile" to modify your personal information, such as updating your address
                                          or changing your profile picture.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. Make sure to review and save any changes made to ensure your profile remains accurate and up to dat.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 className={expanded === 'panel2' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel2', availability_settings_icon, availability_settings1_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel2' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Availability Settings
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. To manage your work availability preferences, tap on "Set Your Availability" from the profile menu.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. On the availability screen, you can specify your availability for each day of the week by toggling the switch next
                                          to the time range.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. If you need to set more specific time slots, tap on the "+ Add New Time" button to define custom availability
                                          periods.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. Remember to save your changes to ensure your availability is accurately reflected in the scheduling system.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel3'}
                                 className={expanded === 'panel3' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel3', compliance_documentation_icon, compliance_documentation1_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel3' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Compliance Documentation
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. The "Compliance" section allows you to view and manage your compliance documents, such as identity proofs,
                                          educational qualifications, and professional memberships.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Each document is listed with its current verification status, such as "Verified," "Not Verified," or "Expiring
                                          Soon."
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. To update or upload new documents, tap on the relevant document and follow the prompts to provide the necessary
                                          information and attach the required files.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. Regularly review and update your compliance documents to maintain your work eligibility and meet any regulatory
                                          requirements.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                                 {CheckValue(imgSrc1) && <img src={imgSrc1} alt="App Screenshot" className={classes.image1} />}
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'activityFeedChat' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1', activity_feed_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel1' ? classes.sectionActiveTitle : classes.sectionTitle}>Activity Feed</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. The "Activity" screen serves as a centralized feed where you can stay informed about recent activities and updates
                                          related to your shifts.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Here, you will find notifications about shift bookings, swap requests, and other relevant information.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Each notification is timestamped and provides a brief description of the activity, along with any associated
                                          details or actions required from you.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. To help manage your notifications, you can mark them as read or clear the entire notification list when needed.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 className={expanded === 'panel2' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel2', chat_function_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel2' ? classes.sectionActiveTitle : classes.sectionTitle}>Chat Function</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. The Get Shifts app includes a built-in chat function that enables real-time communication with the administrative
                                          team.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Access the chat screen by tapping on the chat icon or selecting the relevant chat from your conversation list.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Within the chat interface, you can send text messages, share location updates, and view the conversation history
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. The app also supports the use of predefined messages for quick and efficient communication, saving you time when
                                          sending common queries or responses.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. Remember to maintain professionalism and adhere to any company guidelines while using the chat feature.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                                 {CheckValue(imgSrc1) && <img src={imgSrc1} alt="App Screenshot" className={classes.image1} />}
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'supportFAQs' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1', popular_Questions_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel1' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Popular Questions
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. The "Support" section provides a list of popular questions to help you navigate and use the app effectively
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Topics covered include managing app settings, viewing earnings, getting timesheets approved, canceling shifts, and
                                          more.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Tap on a specific question to view a detailed answer and step-by-step instructions.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 className={expanded === 'panel2' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel2', frequently_asked_questions_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel2' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Frequently Asked Questions (FAQs)
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. For a more comprehensive list of frequently asked questions, tap on the "FAQ" option in the support menu.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Here, you can find answers to a wide range of topics, such as referrals, profile management, compliance,
                                          shift-related queries, and more.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Browse through the categories or use the search function to find specific questions and their corresponding
                                          answers.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel3'}
                                 className={expanded === 'panel3' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel3', contact_support_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel3' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Contact Support
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. If you need further assistance or have specific inquiries not covered in the FAQs, you can reach out to the support
                                          team directly.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Tap on the "Contact Us" option to access the various contact methods available.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. You can choose to call the support team using the provided phone number, send an email to the designated support
                                          email address, or visit the physical office location for in-person assistance.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. When contacting support, provide as much relevant information as possible to ensure a prompt and accurate response.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                                 {CheckValue(imgSrc1) && <img src={imgSrc1} alt="App Screenshot" className={classes.image1} />}
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'settingsMenu' && (
                        <div className={classes.accordionContainer}>
                           <div className={expanded === 'panel1' ? `${classes.accordionTextDiv}  content_2img` : classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1', earnings_icon, earnings1_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel1' ? classes.sectionActiveTitle : classes.sectionTitle}>Earnings</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. The "Earnings" section within the settings menu allows you to access a comprehensive overview of your shift-related
                                          transactions and earnings history
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Tap on "View All" to see a detailed list of all your earnings, including the date, shift details, and corresponding
                                          amounts.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. You can also tap on "View Earnings" for a more focused view of your earnings from specific shifts or time periods.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 className={expanded === 'panel2' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel2', app_settings_icon)}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel2' ? classes.sectionActiveTitle : classes.sectionTitle}>App Settings</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. The "General" option under the settings menu lets you customize various app preferences and settings.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Here, you can manage notification settings, update your account details, and adjust other app-related preferences
                                          to suit your needs.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Take some time to explore and configure these settings to optimize your app experience.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel3'}
                                 className={expanded === 'panel3' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel3')}
                              >
                                 <AccordionSummary>
                                    <Typography className={expanded === 'panel3' ? classes.sectionActiveTitle : classes.sectionTitle}>
                                       Terms and Privacy Policy
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          1. To access the legal terms and conditions governing your use of the Get Shifts app, tap on the "Terms and
                                          Conditions" option in the settings menu.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The "Privacy Policy" section outlines how your personal information is collected, used, and protected by the app
                                          and its associated entities.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. It is important to review and understand these policies to ensure you are comfortable with the app's data practices
                                          and to know your rights as a user.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                                 {CheckValue(imgSrc1) && <img src={imgSrc1} alt="App Screenshot" className={classes.image1} />}
                              </div>
                           ) : null}
                        </div>
                     )}
                     {/* <Button variant="contained" className={classes.videoButton}>
                        Play Video
                     </Button> */}
                  </Box>
               </Box>
            </Box>
         </div>
         <Footer />
      </div>
   )
}

export default NewAppDemo
