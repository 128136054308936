import React, { useState } from 'react';
import "../newWebsite/NewCSS/Login.scss";
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Header from './header';

import google from '../../images/google.png';
import linkedin from '../../images/linkedin.png';
import unverified_img from "../../images/home/expire.svg";
import tick_img from "../../images/home/tick.svg"


import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import request, { NodeURL } from "../../api/api";
import io from 'socket.io-client'
import Remember from './Remember';
const socket = io(NodeURL);


const schema = yup.object().shape({
    email: yup.string().email('Please enter a valid email').required('This is required field').matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, 'Invalid email format'),
    password: yup.string().required('This is required field').min(6, 'Password must have minimum 6 characters'),
});
const newSchema = yup.object().shape({
    emailNew: yup.string().email('Please enter a valid email').required('This is required field').matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, 'Invalid email format'),
});

// GET Shift New login Page
const GetShiftLogin = () => {
    const { register, handleSubmit, formState: { errors, isValid, isDirty } } = useForm({
        resolver: yupResolver(schema),
    });
    const { register:newEmailRegister, handleSubmit:newEmailhandleSubmit, formState: { errors:newEmailErrors, isValid:newEmailValid, isDirty:newEmailDirty } } = useForm({
        resolver: yupResolver(newSchema),
    });

    let [authWindow, setAuthWindow] = useState();
    let [invalid,setInvalid]=useState(false);
    let [message,setMessage]=useState("")
    let [spinner, setSpinner] = useState(false);
    let [remember, setRemember] = useState(false);

    /**************/
    let [unVerified, setUnverified] = useState(false);
    let [subDomain, setSubDomain] = useState('');
    let [agencyId, setAgencyId] = useState('');
    let [emailVerifiedValue, setEmailVerifiedValue] = useState('');


	let [showResendModal, setShowResendModal] = useState(false);
	let	[ackResendModal, setAckResendModal] = useState(false);

    let [ackNewEmail, setAckNewEmail] = useState(false);



    const checkAuthWindow = () => {
		const check = setInterval(() => {
			if (!authWindow || authWindow.closed || authWindow.closed === undefined) {
				clearInterval(check)
			}
		}, 1000)
	}
    const openAuthWindow = (provider) => {
        const width = 600, height = 600
        const left = (window.innerWidth / 2) - (width / 2)
        const top = (window.innerHeight / 2) - (height / 2)
        const url = `${NodeURL}/site/${provider}/auth?socketId=${socket.id}&logintype=user`;
        setAuthWindow(window.open(url, '', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`))
    }
    const socialLogin = (provider) => (e) => {
        e.preventDefault();
        openAuthWindow(provider);
        checkAuthWindow();
    };

    const onSubmit = async (data) => {
        setSpinner(true);
        await request({ url: "/agency", method: "POST", data: data })
        .then((res) => {
            if(res.status===2){
                setUnverified(true);
                setSpinner(false);

                setSubDomain(res.subdomain)
                setAgencyId(res.id);
                setEmailVerifiedValue(res.email_verification_value);

            }else if(res.status===1){
                setInvalid(false);
                setSpinner(false);
                Remember({
                    email: data.email,
                    password: data.password,
                    remember: remember,
                    status: res.status,
                    domain: res.domain,
                    response: res.response,
                });
            }else if(res.status===0){
                setInvalid(true);
                setSpinner(false);
                setMessage(res.response);
            }
            
        })
    }
    const handleResendVerification = async () => {
		const data = {
			subdomain: subDomain,
			verifyId: agencyId
		};
        setSpinner(true);

		await request({url: `/agency/email-verification/redo-validation`,method: "POST",data: data})
        .then((res) => {
			if (+res.status === 1) {
                setSpinner(false);
				setShowResendModal(true);
				setUnverified(false);
			} else if (+res.status === 0) {
                setSpinner(false);
				setShowResendModal(false);
				setUnverified(false);
			}
		}).catch((err) => {
			setShowResendModal(false);
			setUnverified(false);
		})
	};
    const closeSent = () => {
        setSpinner(true);
        setTimeout(() => {
            setUnverified(false);
            setShowResendModal(false);
            setSpinner(false);
        }, 1000);
    }
    

    const onNewEmailSubmit= async (data)=>{
        const dataNew = {subdomain: subDomain,verifyId: emailVerifiedValue,newEmail: data.emailNew,};
        setSpinner(true);
        await request({url: `/agency/email-verification/resubmit-email`,method: "POST",data: dataNew})
        .then((res) => {
            if (+res.status === 1) {
                setSpinner(false);
                setAckNewEmail(true);
                setAckResendModal(false);
                setUnverified(false);
            } else if (+res.status === 0) {
                setSpinner(false);
                setShowResendModal(false);
                setAckResendModal(false);
                setUnverified(false);
            }
        }).catch((err) => {
            setAckResendModal(false);
            setShowResendModal(false);
            setUnverified(false);
        })
    };


  return (
    <div>
		<Header id="hedspace"></Header>
        <div className='login-container'>
            <div className='container'>
                <div className='login-modal'>
                    {!unVerified && !showResendModal && !ackResendModal && !ackNewEmail &&
                        <>
                            <h5>Signin</h5>
                            <form className='new-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                                <div className="form-group">
                                    <label className="form-label">Email Address</label>
                                    <input {...register('email')} type='email' className='form-control' />
                                    {errors.email &&<span className='form-error'>{errors.email.message}</span>}
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Password</label>
                                    <input {...register('password')} type='password' className='form-control' />
                                    {errors.password &&<span className='form-error'>{errors.password.message}</span>}
                                </div>
                                <div className="remember-group">
                                    <div className="remember"><label className="cus-check">Remember me<input type="checkbox" onChange={(e) => setRemember(e.target.checked)} checked={remember}/><span className="cus-checkmark"></span></label></div>
                                    <Link to="/forgot-password" className="forget-password">Forgot Password?</Link>
                                </div>
                                {invalid && <p className='invalid-input alert alert-danger' role='alert'>{message} <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>setInvalid(false)}>&times;</button></p>}
                                <button type="submit" className="form-submit" /* disabled={isDirty && !isValid} */>Log in</button>
                                <p className='reg-here'>New User?<Link to="/signup">Register Here</Link></p>
                                <p className="acc-quc"><b>or access quickly</b></p>
                                <div className="login-through">
                                    <button type="button" onClick={socialLogin('google')}><img src={google} alt='Google'/> Google</button>
                                    <button type="button" onClick={socialLogin('linkedin')}><img src={linkedin} alt='LinkedIn'/> LinkedIn</button>
                                </div>
                            </form>
                        </>
                    }
		            {unVerified &&
                        <>
                            <div className="unVerified-box">
                                <h6><img src={unverified_img} alt='unverified' />Email verification required</h6>
                                <p>Your Get Shifts email address is not yet verified, Please check your registered email for the verification email address (check you spam too) and follow instructions to verify your Get Shifts account.</p>
                                <div className='unVerified-btns'>
                                    <button className="btn resend-link" onClick={handleResendVerification}>Resend verification link</button>
                                    <button className="btn change-email" onClick={() => { setAckResendModal(true); setUnverified(false)}}>Change Email</button>
                                </div>
                            </div>
                        </>
                    }
                    {showResendModal &&
                        <>
                            <div className="unVerified-box">
                                <h6><img src={tick_img} alt='sent' />Verification email sent</h6>
                                <p>A verification email has been sent. Please check your registered email (including spam) and follow the instructions to verify your Get Shifts account.</p>
                                <div className='unVerified-btns'>
                                    <button className="btn change-email" onClick={closeSent}>Close</button>
                                </div>
                            </div>
                        </>
                    }
                    {ackResendModal &&
                        <>
                            <div className="unVerified-box">
                                
                                <h6><img src={unverified_img} alt='unverified'/>Alternate email address</h6>
                                <p>Your Get Shifts email address is not yet verified. Please enter an alternative email address to verify your account.</p>
                                <form className='new-form' onSubmit={newEmailhandleSubmit(onNewEmailSubmit)}>
                                    <div className="form-group">
                                        <label className="form-label">Enter your new email address</label>
                                        <input {...newEmailRegister('emailNew')} type='email' className='form-control' />
                                        {newEmailErrors.emailNew &&<span className='form-error'>{newEmailErrors.emailNew.message}</span>}
                                    </div>
                                    <button type="submit" className="form-submit" disabled={newEmailDirty && !newEmailValid}>Submit</button>
                                </form>
                            </div>
                        </>
                    }
		            {ackNewEmail &&
                        <>
                            <div className="unVerified-box">
                                <h6><img src={tick_img} alt='unverified'/>New email Updated</h6>
                                <p>New email address is updated. please check and verifying your new email.</p>
                                <div className='unVerified-btns'>
                                    <button className="btn change-email" onClick={() => { setAckNewEmail(false); setAckResendModal(false); setShowResendModal(false); setUnverified(false) }}>Close</button>
                                </div>
                            </div>
                        </>
					}


                    {spinner && <div className="spinner-box"><span className="spinner"></span></div>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default GetShiftLogin