import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import Header from "./header";
import Footer from "./NewFooter";

export default class TermsServices extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	menufun = () => {
		document.getElementById("varmenu").classList.add("shower-menus");
		document.getElementById("shbody").classList.add("shower-body");
	}
	hidingmenu = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	}
	clomenus = () => {
		document.getElementById("varmenu").classList.remove("shower-menus");
		document.getElementById("shbody").classList.remove("shower-body");
	}
	upperFun = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}
	render() {
		return (
			<>
				{/* <section className="home-header front-header">
					<div className="container">
						<div className="col-md-12">
							<div className="header-sections">
								<Link to="/">
									{" "}
									<img className="ags-logo"
										src="../../../img/front/agency-logo.png"
										alt="logo"
									/>{" "}
								</Link>
								<ul className="inner-menu menu-gains">
									<li>
										{" "}
										<Link to="/agency-register"> Sign Up </Link>{" "}
									</li>
									<li>
										{" "}
										<Link to="/agency-login"> Sign In </Link>{" "}
									</li>
									<li className="men-clicks">
										<span id="clickmenu" onClick={this.menufun}>
											{" "}
											<img
												src="../../../img/home/white-menu.svg"
												alt="logo"
											/>{" "}
										</span>{" "}
									</li>
								</ul>
								<div
									className="fixed-body act-bdy"
									id="shbody"
									onClick={this.hidingmenu}
								></div>

								<div className="showing-menus" id="varmenu">
									<div className="menu-lists">
										<div className="close-menu">
											<img
												id="closeviews"
												onClick={this.clomenus}
												className="menu-aft"
												src="../../../img/front/menu-close.svg"
												alt="images"
											/>
										</div>
										<ul>
											<li className="sign-desk">
												{" "}
												<Link to="/agency-register">
													{" "}
													Sign Up
													<img
														className="menu-bef"
														src="../../../img/front/menu-user.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-user-clr.svg"
														alt="images"
													/>
												</Link>{" "}
											</li>
											<li className="sign-desk">
												{" "}
												<Link to="/agency-login">
													{" "}
													Sign In
													<img
														className="menu-bef"
														src="../../../img/front/menu-user.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-user-clr.svg"
														alt="images"
													/>
												</Link>{" "}
											</li>
											<li>
												{" "}
												<Link to="/">
													{" "}
													Home
													<img
														className="menu-bef"
														src="../../../img/front/menu-home.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-home-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li className="active">
												{" "}
												<Link to="/features">
													{" "}
													Features
													<img
														className="menu-bef"
														src="../../../img/front/menu-settings.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-settings-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/pricing">
													{" "}
													Pricing
													<img
														className="menu-bef"
														src="../../../img/front/menu-price.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-price-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/resources">
													{" "}
													Resources
													<img
														className="menu-bef"
														src="../../../img/front/menu-resource.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-resource-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
											<li>
												{" "}
												<Link to="/contact">
													{" "}
													Contact
													<img
														className="menu-bef"
														src="../../../img/front/menu-contact.svg"
														alt="images"
													/>
													<img
														className="menu-aft"
														src="../../../img/front/menu-contact-clr.svg"
														alt="images"
													/>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				<Header></Header>

				<section className="policy-section">
					<div className="container">
						<div className="vals-policy">
							<Col md="12">
								<h4> Terms of Service </h4>
							</Col>

							<Col md="7">
								<h6>Introduction </h6>
								<p>These Acceptable Terms of Use govern your use of the website Get Shifts This website is owned by NXT Digital Ltd, trading as Get Shifts, and is registered in England and Wales under company registration number 08835058. Our registered office address is Unit 5, Christie Fields Office Park, Christie Way, Manchester M21 7QY.</p>
								<p>The Acceptable Terms of Use should be read in conjunction with our Cookie
									 Policy, Privacy Policy, Anti-spam Policy and Terms and Conditions. 
									 Use of the website constitutes an agreement by the user to be bound by
									  our Terms and Conditions.</p>

							</Col>
							<Col md="5">
								<img src="../../../img/front/terms-services.svg" alt="img" />
							</Col>
							<Col md="12">
								<h6>license to use website </h6>
								<p>Unless otherwise stated, we or our licensors own the intellectual property rights in the
									website and material on the website. Subject to the licence below, all these intellectual
									property rights are reserved. You may view, download for caching purposes only,
									and print pages from the website for your own personal use, subject to the restrictions
									set out below and elsewhere in these terms and conditions. You must not use the website:</p>
								<ul className="list-markers">
									<li> <i className="fa fa-caret-right" aria-hidden="true"></i>
										in any way that is unlawful, illegal, fraudulent or harmful; or </li>
									<li> <i class="fa fa-caret-right" aria-hidden="true"></i>
										in connection with any unlawful, illegal, fraudulent or harmful purpose or activity. </li>
								</ul>

								<p>You are not permitted to decompile, reverse engineer, disassemble, rent, lease, loan,
									sell, sublicense, or create derivate works from the site or the content. The use of network
									monitoring or discovery software to determine the site architecture, or information about
									its usage or identify individual users is strictly prohibited.</p>
								<p>You must not redistribute material from this website except for content specifically and expressly
									made available for redistribution (such as our newsletter).</p>
								<h6> Acceptable use </h6>
								<p> You must not use our website in any way that causes, or may cause, damage to the website or
									impairment of the availability or accessibility of the website; or in any way which is unlawful,
									illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful
									purpose or activity.</p>
								<p> You must not use our website to copy, store, host, transmit, send, use, publish or
									distribute any material which consists of (or is linked to) any spyware, computer virus,
									Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</p>
								<p>You must not conduct any systematic or automated data collection activities
									(including, without limitation, scraping, data mining, data extraction and data
									harvesting) on or in relation to our website without our express written consent.</p>
								<p>You must not use our website to transmit or send unsolicited commercial communications
									(i.e. spam). You are also referred to our Anti-spam Policy. </p>
								<p>You must not use our website for any purposes related to marketing without our
									express written consent.</p>
								<p>You are strongly advised to ensure that your own anti-virus and protective software
									is up-to-date and effective at all times.</p>
								<h6>Restricted Access</h6>
								<p>Access to certain areas of our website is restricted. We reserve the right to
									restrict access to areas of our website, or indeed our whole website, at our
									discretion. When registering to use our services you will be required to set
									a password to enable you to access the restricted areas and you must ensure that
									your login and password are kept confidential. We may disable your user account
									at our sole discretion without notice or explanation.</p>
								<h6>User Generated Content</h6>
								<p>You grant to us a worldwide, irrevocable, non-exclusive, royalty-free licence to use,
									reproduce, adapt, publish, translate and distribute your user content in any
									existing or future media. You also grant to us the right to sub-license these rights,
									and the right to bring an action for infringement of these rights.</p>
								<p> Your user content must not be illegal or unlawful, must not infringe any third party’s
									legal rights, and must not be capable of giving rise to legal action whether against
									you or us or a third party (in each case under any applicable law).</p>
								<p> You must not submit any user content to the website that is or has ever been the
									subject of any threatened or actual legal proceedings or other similar complaint.
									We reserve the right to edit or remove any material submitted to our website,
									or stored on our servers, or hosted or published upon our website.</p>
								<p> Notwithstanding our rights in relation to user content, we do not undertake to
									monitor the submission of such content to, or the publication of such content on,
									our website.</p>
								<h6>Uptime</h6>
								<p> Get Shifts will endeavour to make the website available to users but cannot
									guarantee that the website will operate continuously or without interruption.
									There may be times when certain features, parts or content of the site, or the
									entire website, become unavailable, or are modified, suspended or withdrawn by
									us at our sole discretion, without any notice given. We will not be liable
									whatsoever for any unavailability issues.</p>
								<p>We cannot guarantee the speed with which you will be able to access and use
									the website as it will depend upon factors such as the specification of your
									equipment and the number of people using the site.</p>
								<h6> Hyperlinks </h6>
								<p>The website may contain links to other sites, which are not under the control of
								Get Shifts. We accept no responsibility or liability for the content, security,
									availability and privacy practices, or any material on any website that is not under
									the control of Get Shifts. A link to a third-party website does not imply
									endorsement by Get Shifts.</p>
								<h6>Limited Warranties</h6>
								<p>We do not warrant the completeness or accuracy of the information published on this
									website, nor do we commit to ensuring that the website remains available or that
									the material on the website is kept up-to-date. To the maximum extent permitted
									by applicable law we exclude all representations, warranties and conditions relating
									to this website and the use of this website (including, without limitation, any
									warranties implied by law of satisfactory quality, fitness for purpose, and/or
									the use of reasonable care and skill). </p>
								<h6> Limitations and Exclusions of Liability </h6>
								<p>Get Shifts has taken every care in the preparation of the content on this website,
									but to the full extent permissible by law, we shall not be held liable for any loss
									or damage of any nature. We will not be liable to you in respect of any losses
									arising out of any event or events beyond our reasonable control</p>
								<p> We will not be liable to you in respect of any business losses, including
									(without limitation) loss of or damage to profits, income, revenue, use, production,
									anticipated savings, business, contracts, commercial opportunities or goodwill.
									We will not be liable to you in respect of any loss or corruption of any data,
									database or software.</p>
								<p>We will not be liable to you in respect of any special, indirect or consequential
									loss or damage. Nothing in these terms and conditions shall:</p>
								<ul className="list-markers">
									<li><i className="fa fa-caret-right" aria-hidden="true"></i>
										limit or exclude our or your liability for death or personal injury resulting from negligence;
									</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i>
										limit or exclude our or your liability for fraud or fraudulent misrepresentation;
									</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i>
										limit any of our or your liabilities in any way that is not permitted under applicable law; or
									</li>
									<li><i className="fa fa-caret-right" aria-hidden="true"></i>
										exclude any of our or your liabilities that may not be excluded under applicable law.
									</li>
								</ul>
								<h6> Indemnity  </h6>
								<p> You hereby indemnify us and undertake to keep us indemnified against any losses,
									damages, costs, liabilities and expenses (including without limitation legal expenses
									and any amounts paid by us to a third party in settlement of a claim or dispute on
									the advice of our legal advisers) incurred or suffered by us arising out of any
									breach by you of any provision of these terms and conditions, or arising out of
									any claim that you have breached any provision of these terms and conditions. </p>

								<h6>Data Protection</h6>
								<p>The use of personal data supplied to us by you and other users of this website
									is governed by our Privacy Policy. We keep all data in our secured systems and such
									data will only be given to third parties when we have a lawful basis to do so. Any testimonial or quote used on the website or other marketing material will only be used when specific and informed consent has been given.</p>

								<p>Under Data Protection Legislation all individuals have the right to access their personal data in accordance with the provisions of the General Data Protection Regulation (GDPR). If you wish to exercise any data subject right you should contact us by email to <a href="mailto:privacy@getshifts.co.uk">privacy@getshifts.co.uk. </a></p>

								<h6>Breach of Acceptable Terms of Use</h6>
								<p>Without prejudice to any of our other rights, we may take such action as we deem appropriate to deal with any breach of our Acceptable Terms of Use, including deleting or editing any of your content, suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.</p>

								<h6> Variation </h6>
								<p> No failure or delay by Get Shifts in exercising any of its rights shall be deemed to be a waiver of that right, and no waiver of a breach of any provision of the Acceptable Terms of Use shall be deemed to be a waiver of any subsequent breach of the same or any other provision. </p>

								<h6>Assignment </h6>
								<p>We may transfer, sub-contract or otherwise deal with our rights and/or obligations under these Acceptable Terms of Use without notifying you or obtaining your consent. You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.</p>

								<h6> Severability </h6>
								<p>If a provision of these Acceptable Terms of Use is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>

								<h6>Third-Party Rights</h6>
								<p>These Acceptable Terms of Use are for the benefit of you and us and are not intended to benefit any third party or be enforceable by any third party. The exercise of our and your rights in relation to these terms is not subject to the consent of any third party.</p>

								<h6>Jurisdiction and Governing Law</h6>
								<p>All disputes, whether contractual or otherwise, arising out of or in connection with the website’s Acceptable Terms of Use, are governed by and shall be construed in accordance with the laws of England and Wales and each party submits to the exclusive jurisdiction of the English courts.</p>
							</Col>
						</div>
					</div>
				</section>

				<Footer></Footer>


			</>
		);
	}
}
