import React, { useState, useEffect } from 'react';
import Header from "./header";
import Footer from "./NewFooter";
import request from "../../api/api";

const FaqDetails = (props) => {
    const { match, history } = props;
    const [content, setContent] = useState("");
    useEffect(() => {
        if (match.params && match.params.slug && match.params.slug !== '') {
            request({
                url: `/mobile/faq/details?slug=${match.params.slug}`,
                method: "GET",
                data: {}
            }).then(res => {
                if (res.status && res.status === 1) {
                    setContent(res.response && res.response.answer && res.response.answer !== '' ? res.response.answer : '')
                } else {
                    history.push('/');
                }
            })
        } else {
            history.push('/');
        }
    }, [match.params.slug])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header></Header>
            <section>
                <div className="container">
                    {content && <div className="terms-para" dangerouslySetInnerHTML={{ __html: content }}></div>}
                </div>
            </section>
            <Footer></Footer>
        </>
    )
}

export default FaqDetails;