import React, { useState, useEffect } from 'react';
// import {  useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useDebounce } from '../../../hooks';

import request from '../../../api/api';
import './index.css';

import Header from '../header';
import Footer from '../NewFooter';
import axios from 'axios';

const KnowledgeBase = () => {
    const [activeTab, setActiveTab] = useState('');
    const [title, setTitle] = useState([]);
    const [CategoryData, setCategoryData] = useState([]);
    const [search, setSearch] = useState('');
    const history = useHistory();
    const[loader,setLoader]=useState(false);


    const debouncedValue = useDebounce(search, 500);

    const getTitles = async () => {
        let get_data = [];
        try {
            let respData = await request({ url: "/company/knowledge/title/list", method: "GET" });
            if (respData.status === 1) {
                get_data = Array.isArray(respData.response) ? respData.response : [];
            }
            setTitle(get_data)
            return get_data
        } catch (error) {
            return get_data
        }
    }
    const getCategoryById = async (id = '', signal) => {
        let get_data = [];
        try {
            setLoader(true)
            let respData = await request({ url: `/company/knowledge/getcategory`, method: 'POST', data: { Id: id, action_for: 'id' }, cancelToken: signal });
            if (respData.status === 1) {
                get_data = Array.isArray(respData.response) ? respData.response : [];
                setLoader(false)
            }
            setCategoryData(get_data)
            return get_data
        } catch (error) {
            setLoader(false)
            return get_data
        }
    }
    const getSearch = async (search = '', signal = '') => {
        let get_data = [];
        try {
            setLoader(true)
            let respData = await request({ url: '/company/knowledge/getcategory', method: 'POST', data: { search: search, action_for: 'search' }, cancelToken: signal });
            if (respData.status === 1) {
                get_data = Array.isArray(respData.response) ? respData.response : [];
                setLoader(false)
            }
            setCategoryData(get_data)
            return get_data
        } catch (error) {
            setLoader(false)
            return get_data
        }
    }



    useEffect(() => {
        getTitles().then((titleData) => {
            let Id = titleData[0] && titleData[0]._id
            if (Id) {
                getCategoryById(Id)
                setActiveTab(Id);

            }
        });

    }, []);


    useEffect(() => {
        let signal = axios.CancelToken.source();

        if (debouncedValue && debouncedValue.replace(/ /g, '')) {
            getSearch(debouncedValue, signal.token);
        } else {
            getCategoryById(activeTab, signal.token)
        }

        return () => {
            signal.cancel("getSearch aborted...")
        }
    }, [debouncedValue]);


    const handleTabClick = async (tab, id) => {
        setActiveTab(id);
        getCategoryById(id)
    };
    const handleCatClick = (tab, id) => {
        history.push({
            pathname: '/knowledge/video',
            state: id
        })
    }
    const handlechange = (e) => {
        setSearch(e.target.value)
    }

    return (
        <>
            <Header></Header>
            <div>
                <div className="container">
                    <div className="knowledge-banner">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                                <div className="banner-info">
                                    <h2><span>Get Shifts</span> Knowledge Base</h2>
                                    <p>One-stop knowledge base to learn about how to use Get Shifts effectively.</p>
                                    {/* <form onSubmit={Onsearchdata}> */}
                                    <div className="search">
                                        <input type='text' onChange={handlechange} value={search} placeholder='Search for videos/articles' />
                                        <button><img src='/img/search-icon.svg' alt="Search" /></button>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className='inner-videobanner'>
                                    {/* <img src='/img/helpimg.png' /> */}
                                    <iframe
                                    width="100%"
                                    height="100%"
                                    src='https://agencyportal.portal.trainn.co/share/HKWVT7NpFzwSdEzsVbwZPg/embed?autoplay=false'
                                    frameBorder="0"
                                    allowFullScreen
                                    title='helpcentre'
                                ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="knowledge-tab">
                        <div className="tab-btns">
                            {title && title.length > 0
                                ? title.map((item = {}, i) => (
                                    <button key={i} onClick={() => handleTabClick(i, item._id)} className={activeTab === item._id ? 'active' : ''}>
                                        <span>{item && item.title ? item.title : ''}</span>
                                        <p>{item && item.category_count ? item.category_count : '0'} Categories . {item && item.video_count
                                            ? item.video_count
                                            : '0'} Videos</p>
                                    </button>
                                ))
                                : null}
                        </div>
                        <div className="tab-contents">
                            <div className='tab-infos'>
                                {CategoryData && CategoryData.length > 0
                                    ? CategoryData.map((item, i) => (
                                        <div className='tab-info' key={i}>
                                            <button className='tab-btn' key={i} onClick={() => handleCatClick(i, item._id)}>
                                                <h6>{item && item.category ? item.category : ''}</h6>
                                                <p>{item && item.content ? item.content : ''}</p>
                                                <p><span>{item && item.videos ? item.videos.length : ''} Videos</span> <b></b></p>
                                            </button>
                                        </div>
                                    ))
                                : <p className='no-added'>{loader ? "Loading ..." : "No videos"} </p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
};

export default KnowledgeBase